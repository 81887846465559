import { combineReducers } from "redux";
import { pickBy, findKey } from "lodash";

import { AsyncDataPhase, createAsyncReducer, createReducer, Reducer } from "~/lib/redux";

import { DocumentCode } from "~/entities/anketa";

import {
  anketaDeleteDocErrorAction,
  anketaDeleteDocStartAction,
  anketaDeleteDocSuccessAction,
  anketaGetRequiredDocsErrorAction,
  anketaGetRequiredDocsStartAction,
  anketaGetRequiredDocsSuccessAction,
  anketaGetUploadedDocsErrorAction,
  anketaGetUploadedDocsStartAction,
  anketaGetUploadedDocsSuccessAction,
  anketaUploadDocErrorAction,
  anketaUploadDocStartAction,
  anketaUploadDocSuccessAction,
  anketaDownloadUplodedFileStartAction,
  anketaDownloadUplodedFileSuccessAction,
  anketaDownloadUplodedFileErrorAction,
} from "../actions";

export const STATE_NAME = "docs";
export const REQUIRED_DOCS_STATE_NAME = "required";
export const UPLOADED_DOCS_STATE_NAME = "uploaded";
export const FILES_STATE_NAME = "files";

const [requiredDocsReducer, requiredDocsDefaultState] = createAsyncReducer(
  anketaGetRequiredDocsStartAction,
  anketaGetRequiredDocsSuccessAction,
  anketaGetRequiredDocsErrorAction
);

const [uploadedDocsReducer, uploadedDocsDefaultState] = createAsyncReducer(
  anketaGetUploadedDocsStartAction,
  anketaGetUploadedDocsSuccessAction,
  anketaGetUploadedDocsErrorAction
);

export interface File {
  phase: AsyncDataPhase;
  data: { path: string } | null;
  error: Error | null;
}

export type FilesState = { [key in DocumentCode]?: File };

const filesDefaultState: FilesState = {};

const filesReducer: Reducer<FilesState> = createReducer(
  {
    [anketaDownloadUplodedFileStartAction.getType()]: (state, payload): FilesState => {
      const docType = findKey(
        state,
        (file): boolean => !!file && !!file.data && file.data.path === payload
      ) as DocumentCode;

      if (!docType) return state;

      return {
        ...state,
        [docType]: {
          ...state[docType],
          phase: AsyncDataPhase.Pending,
          error: null,
        },
      };
    },
    [anketaDownloadUplodedFileSuccessAction.getType()]: (state, payload: DocumentCode): FilesState => {
      return {
        ...state,
        [payload]: {
          ...state[payload],
          phase: AsyncDataPhase.Success,
          error: null,
        },
      };
    },
    [anketaDownloadUplodedFileErrorAction.getType()]: (state, payload: { notified: DocumentCode }): FilesState => {
      if (!payload.notified) return state;

      return {
        ...state,
        [payload.notified]: {
          ...state[payload.notified],
          phase: AsyncDataPhase.Error,
          error: payload,
        },
      };
    },
    [anketaUploadDocStartAction.getType()]: (state, payload): FilesState => ({
      ...state,
      [payload.docType]: {
        phase: AsyncDataPhase.Pending,
        data: { path: payload.file.path },
        error: null,
      },
    }),
    [anketaUploadDocSuccessAction.getType()]: (state, payload, meta): FilesState => ({
      ...state,
      [meta.docType]: {
        phase: AsyncDataPhase.Success,
        data: { path: payload.fileLink },
        error: null,
      },
    }),
    [anketaDeleteDocErrorAction.getType()]: (state, payload, meta): FilesState => ({
      ...state,
      [meta.docType]: {
        ...state[meta.docType as DocumentCode],
        phase: AsyncDataPhase.Error,
        error: payload.error,
      },
    }),

    [anketaDeleteDocStartAction.getType()]: (state, payload: { docType: DocumentCode }): FilesState => ({
      ...state,
      [payload.docType]: {
        ...state[payload.docType],
        phase: AsyncDataPhase.Pending,
      },
    }),
    [anketaDeleteDocSuccessAction.getType()]: (state, _, meta): FilesState => {
      return pickBy(state, (_, key: string): boolean => key !== meta.docType);
    },
    [anketaUploadDocErrorAction.getType()]: (state, payload: Error, meta: { docType: DocumentCode }): FilesState => ({
      ...state,
      [meta.docType]: {
        ...state[meta.docType],
        data: null,
        phase: AsyncDataPhase.Error,
        error: payload,
      },
    }),

    [anketaGetUploadedDocsSuccessAction.getType()]: (state, payload): FilesState => {
      function getFileState(
        docType: DocumentCode,
        currentState: FilesState,
        uploadedDocs: any
      ): { [k in DocumentCode]?: File } {
        if (!uploadedDocs[docType]) return {};

        return {
          [docType]: {
            phase: AsyncDataPhase.Success,
            error: null,
            ...currentState[docType],
            data: {
              path: uploadedDocs[docType],
            },
          },
        };
      }
      return {
        ...state,
        ...getFileState(DocumentCode.foreignPassport, state, payload),
        ...getFileState(DocumentCode.registrationDoc, state, payload),
        ...getFileState(DocumentCode.passportRfPhoto, state, payload),
        ...getFileState(DocumentCode.dop1, state, payload),
        ...getFileState(DocumentCode.dop2, state, payload),
        ...getFileState(DocumentCode.dop3, state, payload),
        ...getFileState(DocumentCode.dop4, state, payload),
        ...getFileState(DocumentCode.dop5, state, payload),
        ...getFileState(DocumentCode.dop6, state, payload),
        ...getFileState(DocumentCode.dop7, state, payload),
        ...getFileState(DocumentCode.dop8, state, payload),
        ...getFileState(DocumentCode.dop9, state, payload),
        ...getFileState(DocumentCode.dop10, state, payload),
      };
    },
  },
  filesDefaultState
);

export type RequiredDocsState = typeof requiredDocsDefaultState;
export type UploadedDocsState = typeof uploadedDocsDefaultState;

export interface DocsState {
  [REQUIRED_DOCS_STATE_NAME]: RequiredDocsState;
  [UPLOADED_DOCS_STATE_NAME]: UploadedDocsState;
  [FILES_STATE_NAME]: FilesState;
}

export default combineReducers<DocsState>({
  [REQUIRED_DOCS_STATE_NAME]: requiredDocsReducer,
  [UPLOADED_DOCS_STATE_NAME]: uploadedDocsReducer,
  [FILES_STATE_NAME]: filesReducer,
});
