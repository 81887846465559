export class AppError<E extends Error = Error> extends Error {
  // https://github.com/Microsoft/TypeScript/issues/13965#issuecomment-278570200
  public __proto__: Error;
  public notified: string;

  public readonly type: string;
  public readonly error?: E;

  public static is = (value: unknown): value is AppError => {
    return !!value && value instanceof AppError;
  };

  // @ts-ignore
  public constructor(error?: E, message?: string) {
    // https://github.com/Microsoft/TypeScript/issues/13965#issuecomment-278570200
    const proto = new.target.prototype;
    super(message);

    this.notified = "";

    this.type = "AppError";
    this.error = error;

    // https://github.com/Microsoft/TypeScript/issues/13965#issuecomment-278570200
    this.__proto__ = proto;
  }

  public setNotified(code: string): void {
    this.notified = code;
  }
}
