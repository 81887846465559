import moment from "moment";

import { locales, months } from "~/constants/enums";
import i18n, { t } from "~/i18n";
import { padDate } from "~/lib/date";

export const getDayOfDate = (date: string): string => moment(date).format("D");

export const getMonthLocalesOfDate = (date: string, i18nLanguage: string) => {
  const month = padDate(moment(date).format("MM"));

  return `${i18nLanguage === "ru" ? months[month]["rus"] : months[month]["eng"]}`;
};

export const getYearOfDate = (date: string): string => moment(date).format("YYYY");

export const formatRelativeDate = (
  date: string,
  withTime?: boolean,
  alwaysWithYear?: boolean,
  alwaysWithoutYear?: boolean
) => {
  const time = moment(date).format("HH:mm");
  const todayDate = new Date();

  if (moment(date).format("DD.MM.YYYY") === moment().format("DD.MM.YYYY")) {
    const today = t("GENERAL.today");

    return withTime ? `${today}, ${time}` : today;
  }
  if (
    moment(date).format("MM.YYYY") === moment().format("MM.YYYY") &&
    moment(date).format("DD") === moment().subtract(1, "days").format("DD")
  ) {
    const yesterday = t("GENERAL.yesterday");

    return withTime ? `${yesterday}, ${time}` : yesterday;
  }

  const month = padDate(moment(date).format("MM"));
  const nameMonth = i18n.language === locales.RU ? months[month]["rus"] : months[month]["eng"];
  const day = moment(date).format("DD");

  const currentYear = todayDate.getFullYear();
  const dateYear = new Date(date).getFullYear();
  const shouldDisplayYear = dateYear !== currentYear || alwaysWithYear;

  const displayedYear = shouldDisplayYear && !alwaysWithoutYear ? dateYear.toString() : "";

  let resultDate;

  if (i18n.language === locales.RU) {
    resultDate = `${parseInt(day)} ${nameMonth} ${displayedYear}`;
  } else {
    resultDate = !displayedYear ? `${nameMonth} ${parseInt(day)}` : `${nameMonth} ${parseInt(day)}, ${displayedYear}`;
  }

  return withTime ? `${resultDate}, ${time}` : resultDate;
};

export const formatQuarter = (quarter?: string | null, year?: string | null | number): string => {
  if (!quarter || !year) {
    return "";
  }

  const quarterNumber = parseInt(quarter.slice(1), 10);

  if (i18n.language === locales.EN) {
    return `Q${quarterNumber} ${year}`;
  } else if (i18n.language === locales.RU) {
    return `${quarterNumber} кв. ${year}`;
  } else {
    throw new Error("Unsupported language");
  }
};
