import { makeStyles } from "tss-react/mui";

import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()({
  wrapper: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  title: {
    color: colorTable.lightGrey_40,
  },
});
