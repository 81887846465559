import { combineReducers } from "redux";

import boot, { STATE_NAME as BOOT_STATE_NAME } from "./reducers/boot";
import error, { STATE_NAME as ERROR_STATE_NAME } from "./reducers/error";
import locale, { STATE_NAME as LOCALE_STATE_NAME } from "./reducers/locale";
import messages, { STATE_NAME as MESSAGES_STATE_NAME } from "./reducers/messages";
import smsSign, { STATE_NAME as SMS_SIGN_STATE_NAME } from "./reducers/smsSign";

export const STATE_NAME = "app";

export { BOOT_STATE_NAME };
export { MESSAGES_STATE_NAME };
export { ERROR_STATE_NAME };
export { SMS_SIGN_STATE_NAME };
export { LOCALE_STATE_NAME };

export interface AppState {
  [BOOT_STATE_NAME]: any;
  [MESSAGES_STATE_NAME]: any;
  [ERROR_STATE_NAME]: any;
  [SMS_SIGN_STATE_NAME]: any;
  [LOCALE_STATE_NAME]: any;
}

export default combineReducers<AppState>({
  [BOOT_STATE_NAME]: boot,
  [MESSAGES_STATE_NAME]: messages,
  [ERROR_STATE_NAME]: error,
  [SMS_SIGN_STATE_NAME]: smsSign,
  [LOCALE_STATE_NAME]: locale,
});
