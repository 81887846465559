import { combineReducers } from "redux";

import postBrokerReport, { STATE_NAME as POST_BROKER_REPORT_STATE_NAME } from "./reducers/postBrokerReport";
import postFundsWithdrawal, { STATE_NAME as POST_FUNDS_WITHDRAWAL_STATE_NAME } from "./reducers/postFundsWithdrawal";
import printForms, { STATE_NAME as PRINT_FORMS_STATE_NAME } from "./reducers/printForms";

export const STATE_NAME = "requests";

export { POST_BROKER_REPORT_STATE_NAME };
export { POST_FUNDS_WITHDRAWAL_STATE_NAME };
export { PRINT_FORMS_STATE_NAME };

export interface RequestsState {
  [POST_BROKER_REPORT_STATE_NAME]: any;
  [POST_FUNDS_WITHDRAWAL_STATE_NAME]: any;
  [PRINT_FORMS_STATE_NAME]: any;
}

export default combineReducers<RequestsState>({
  [POST_BROKER_REPORT_STATE_NAME]: postBrokerReport,
  [POST_FUNDS_WITHDRAWAL_STATE_NAME]: postFundsWithdrawal,
  [PRINT_FORMS_STATE_NAME]: printForms,
});
