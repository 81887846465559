import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { useStore } from "effector-react";
import moment from "moment";
import { makeStyles } from "tss-react/mui";

import ViewportHeader from "~/app/layouts/BaseLayout/components/ViewportHeader";
import { useTranslation } from "~/i18n";
import { technicalWorksModel } from "~/modules/app/technical-works-model";
import { BaseTypography } from "~/shared/ui-kit/BaseComponents";
import colorTable from "~/shared/ui-kit/constants/colors";
import Icon from "~/shared/Icon";

const useStyles = makeStyles()(() => ({
  wrapper: {
    fontFamily: "Formular, Arial, sans-serif",
    height: "100%",
  },
  notAvailable: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "black",
  },
  containerWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    marginTop: "-80px",
    padding: "0 16px",
    gap: "24px",
    "& p": {
      textAlign: "center",
    },
  },
  icon: {
    alignSelf: "center",
    lineHeight: 0.7,
  },
  subtitle: {
    paddingTop: "8px",
    color: colorTable.textSecondary,
    maxWidth: "490px",
    textAlign: "center",
    whiteSpace: "pre-wrap",
  },
}));

export const NotAvailable = () => {
  const { t } = useTranslation();
  const { $message } = technicalWorksModel;
  const message = useStore($message);
  const { classes } = useStyles();

  useEffect(() => {
    sessionStorage.setItem("redirectPath", "");
  }, []);

  return (
    <Grid container direction="column" alignItems="stretch" wrap="nowrap">
      <Box className={classes.containerWrapper}>
        <ViewportHeader unAuthorized={true} />
        <Box className={classes.notAvailable}>
          <Box className={classes.content}>
            <Box className={classes.icon}>
              <Icon
                name="attentionIcon"
                size={40}
                withBackground={{
                  backgroundColor: colorTable.redLight,
                  backgroundType: "rounded_16",
                  padding: "14px",
                }}
              />
            </Box>
            <Box>
              <BaseTypography fontType="title_2_bold" tag="p">
                {t(message.title)}
              </BaseTypography>
              <BaseTypography tag="p" fontType="text" className={classes.subtitle}>
                {message?.startDate && message?.endDate
                  ? t("ERRORS.technical_work_subtitle_with_date", {
                      startDate: moment.utc(message?.startDate).local().format("YYYY-MM-DD HH:mm"),
                      endDate: moment.utc(message?.endDate).local().format("YYYY-MM-DD HH:mm"),
                    })
                  : t("ERRORS.technical_work_subtitle_without_date")}
              </BaseTypography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};
