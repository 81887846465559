import { get } from "~/lib/request";
import { hashType } from "~/pages/Chat/types";

export const chatServiceApi = {
  getHash: async (data: hashType): Promise<any> => {
    const hashData = {
      saltType: data?.saltType,
      sourceString: data?.sourceString,
    };
    const res = await get("@auth/hash/v1/hash", { params: hashData });
    return res.data;
  },
};
