import { forEach, isString, transform } from "lodash";
import qs from "qs";

import { conditionalValue } from "~/lib/utils";
import { ContentType, DataObject } from "./types";

export const appApiUrl = (url: string): string => {
  if (url.startsWith("@auth/")) {
    return `${conditionalValue(import.meta.env.VITE_AUTH_API_URL)}/${url.replace(/^@auth\//, "")}`;
  } else if (url.startsWith("@current/")) {
    return `${conditionalValue(import.meta.env.VITE_AUTH_API_URL)}/${url.replace(/^@current\//, "")}`;
  } else if (url.startsWith("@none/")) {
    return `${url.replace(/^@none\//, "")}`;
  } else if (url.startsWith("@msbuyingproduct/")) {
    return `${conditionalValue(import.meta.env.VITE_MSBUYINGPRODUCT_API_URL)}/${url.replace(/^@msbuyingproduct\//, "")}`;
  } else if (url.startsWith("@msrequests/")) {
    return `${conditionalValue(import.meta.env.VITE_MSREQUESTS_API_URL)}/${url.replace(/^@msrequests\//, "")}`;
  } else if (url.startsWith("@msrequestsmsspdictionary/")) {
    return `${conditionalValue(import.meta.env.VITE_MSREQUESTS_MSSPDICTIONARY_API_URL)}/${url.replace(
      /^@msrequestsmsspdictionary\//,
      ""
    )}`;
  } else if (url.startsWith("@msspproduct/")) {
    return `${conditionalValue(import.meta.env.VITE_MSSPPRODUCT_API_URL)}/${url.replace(/^@msspproduct\//, "")}`;
  } else if (url.startsWith("@ukproduct/")) {
    return `${conditionalValue(import.meta.env.VITE_UK_PRODUCT_API_URL)}/${url.replace(/^@ukproduct\//, "")}`;
  } else if (url.startsWith("@ukrduproduct/")) {
    return `${conditionalValue(import.meta.env.VITE_UK_RDU_PRODUCT_API_URL)}/${url.replace(/^@ukrduproduct\//, "")}`;
  } else if (url.startsWith("@msbank/")) {
    return `${conditionalValue(import.meta.env.VITE_MSBANK_API_URL)}/${url.replace(/^@msbank\//, "")}`;
  } else if (url.startsWith("@msshowcase/")) {
    return `${conditionalValue(import.meta.env.VITE_AUTH_API_URL)}/${url.replace(/^@msshowcase\//, "")}`;
  } else if (url.startsWith("@msactivation/")) {
    return `${conditionalValue(import.meta.env.VITE_SERVICE_ACTIVATION_URL)}/${url.replace(/^@msactivation\//, "")}`;
  } else if (url.startsWith("@msclient/")) {
    return `${conditionalValue(import.meta.env.VITE_CLIENT_MICROSERVICE_URL)}/${url.replace(/^@msclient\//, "")}`;
  } else if (url.startsWith("@msnonfin/")) {
    return `${conditionalValue(import.meta.env.VITE_NON_FIN_URL)}/${url.replace(/^@msnonfin\//, "")}`;
  } else if (url.startsWith("@msdma/")) {
    return `${conditionalValue(import.meta.env.VITE_DMA_INSTRUMENTS_URL)}/${url.replace(/^@msdma\//, "")}`;
  } else if (url.includes("file/am_docs") || url.includes("bcs-sp") || url.includes("sp-product")) {
    return `${url}`;
  } else if (url.includes("https") || url.includes("http")) {
    return `${url}`;
  }

  return `${conditionalValue(import.meta.env.VITE_API_URL)}/${url}`;
};

export const clearData = <T extends { [key: string]: unknown }>(data: T): { [key: string]: unknown } =>
  transform(
    data,
    (result, value, key): void => {
      result[key] = isString(value) && value === "" ? null : value;
    },
    {} as { [key: string]: unknown }
  );

export const convertDataToRequestFormat = (
  data: DataObject,
  contentType?: ContentType
): DataObject | string | FormData => {
  switch (contentType) {
    case ContentType.FormUrlencoded:
      return qs.stringify(data);
    case ContentType.FormData: {
      const formData = new FormData();

      forEach(data, (value, key): void => {
        if (Array.isArray(data[key])) {
          data[key].forEach((el: any, index: number) => formData.append(`${key}${index + 1}`, el));
        } else formData.append(key, value);
      });

      return formData;
    }
    default:
      return data;
  }
};
export const buildQueryParams = (params: Record<string, any>): string => {
  // Для фильтров может быть несколько параметров с одинаковым именем
  // Поэтому форматируем их в строку и подставляем непосредственно в запрос
  const queryParams = new URLSearchParams();

  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (Array.isArray(value)) {
      value.forEach((item) => queryParams.append(key, item));
    } else {
      queryParams.append(key, value);
    }
  });

  return queryParams.toString();
};
