import { makeStyles } from "tss-react/mui";

import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()((theme) => ({
  bunnerButton: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 16px",
    borderRadius: "8px",
    background: colorTable.white_20,
    cursor: "pointer",
    [theme.breakpoints.up(993)]: {
      "&:hover": {
        "& svg": {
          color: colorTable.nobleOchre,
        },
      },
    },
  },
  subtitle: {
    color: colorTable.moonGrey,
  },
  textBlock: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  },
}));
