import { combineReducers } from "redux";

import changePassword, { STATE_NAME as CHANGE_PASSWORD_STATE_NAME } from "./reducers/changePassword";
import changeSecurityQuestion, {
  STATE_NAME as CHANGE_SECURITY_QUESTION_STATE_NAME,
} from "./reducers/changeSecurityQuestion";
import checkOtp, { STATE_NAME as CHECK_OTP_STATE_NAME } from "./reducers/checkOtp";
import initialCredentials, { STATE_NAME as INITIAL_CREDENTIALS_STATE_NAME } from "./reducers/initialCredentials";
import otpTimestamp, { STATE_NAME as OTP_TIMESTAMP_STATE_NAME } from "./reducers/otpTimestamp";
import resetPassword, { STATE_NAME as RESET_PASSWORD_STATE_NAME } from "./reducers/resetPassword";
import resetPasswordQuestion, {
  STATE_NAME as RESET_PASSWORD_QUESTION_STATE_NAME,
} from "./reducers/resetPasswordQuestion";
import securityQuestion, { STATE_NAME as SECURITY_QUESTION_STATE_NAME } from "./reducers/securityQuestion";
import securityQuestions, { STATE_NAME as SECURITY_QUESTIONS_STATE_NAME } from "./reducers/securityQuestions";

export const STATE_NAME = "keycloak";

export { CHANGE_PASSWORD_STATE_NAME };
export { CHANGE_SECURITY_QUESTION_STATE_NAME };
export { RESET_PASSWORD_STATE_NAME };
export { CHECK_OTP_STATE_NAME };
export { SECURITY_QUESTIONS_STATE_NAME };
export { OTP_TIMESTAMP_STATE_NAME };
export { INITIAL_CREDENTIALS_STATE_NAME };
export { SECURITY_QUESTION_STATE_NAME };
export { RESET_PASSWORD_QUESTION_STATE_NAME };

export interface KeycloakState {
  [CHANGE_PASSWORD_STATE_NAME]: any;
  [CHANGE_SECURITY_QUESTION_STATE_NAME]: any;
  [RESET_PASSWORD_STATE_NAME]: any;
  [CHECK_OTP_STATE_NAME]: any;
  [SECURITY_QUESTIONS_STATE_NAME]: any;
  [OTP_TIMESTAMP_STATE_NAME]: any;
  [INITIAL_CREDENTIALS_STATE_NAME]: any;
  [SECURITY_QUESTION_STATE_NAME]: any;
  [RESET_PASSWORD_QUESTION_STATE_NAME]: any;
}

export default combineReducers<KeycloakState>({
  [CHANGE_PASSWORD_STATE_NAME]: changePassword,
  [CHANGE_SECURITY_QUESTION_STATE_NAME]: changeSecurityQuestion,
  [RESET_PASSWORD_STATE_NAME]: resetPassword,
  [CHECK_OTP_STATE_NAME]: checkOtp,
  [SECURITY_QUESTIONS_STATE_NAME]: securityQuestions,
  [OTP_TIMESTAMP_STATE_NAME]: otpTimestamp,
  [INITIAL_CREDENTIALS_STATE_NAME]: initialCredentials,
  [SECURITY_QUESTION_STATE_NAME]: securityQuestion,
  [RESET_PASSWORD_QUESTION_STATE_NAME]: resetPasswordQuestion,
});
