import { makeStyles } from "tss-react/mui";

import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  text: {
    color: colorTable.lightGrey_40,
  },
  icon: {
    margin: "0 auto",
  },
});
