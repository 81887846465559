import { createReducer, Reducer } from "~/lib/redux";
import { AsyncData, AsyncDataPhase, initial } from "~/lib/redux/async";
import { anketaSaveCommonSectionStartAction, anketaUpdateCommonSectionStartAction } from "../actions";

export const STATE_NAME = "saveOrUpdateCommonSection";

export type AnketaSaveOrUpdateCommonSectionState = AsyncData<any>;

export const defaultState: AnketaSaveOrUpdateCommonSectionState = initial();

const reducer: Reducer<AnketaSaveOrUpdateCommonSectionState> = createReducer<AnketaSaveOrUpdateCommonSectionState>(
  {},
  defaultState
);

const pendingReducer = (state: AnketaSaveOrUpdateCommonSectionState): AnketaSaveOrUpdateCommonSectionState => ({
  ...state,
  phase: AsyncDataPhase.Pending,
});

reducer.on(anketaSaveCommonSectionStartAction, pendingReducer);
reducer.on(anketaUpdateCommonSectionStartAction, pendingReducer);

export default reducer;
