import { useEffect, useRef } from "react";
import { useGate, useStore } from "effector-react";
import { isEmpty } from "lodash";

import { ChatChannels, ClientProfileValues } from "~/constants/enums";
import { conditionalValue } from "~/lib/utils";
import { clientCardModel } from "~/modules/client-card-model";
import { clientProfileModel } from "~/modules/client-profile";
import { getBrowserVersion, getTimezone, getUserAgentOSVersion } from "~/pages/Chat/helpers";
import { chatService } from "~/pages/Chat/services/services";
import { edmService } from "~/pages/EDM/services/edm-services";

export const Chat = () => {
  const { $clientProfile, gate } = clientProfileModel;
  const { $agreements } = clientCardModel;
  const { $isEdm } = edmService;
  const { chatGate, $hashInfo } = chatService;
  const isFirst = useRef(true);

  const agreements = useStore($agreements);
  const clientProfile = useStore($clientProfile);
  const hashInfo = useStore($hashInfo);
  const isEdm = useStore($isEdm);

  useGate(gate);
  useGate(chatGate);

  useEffect(() => {
    if (
      !isEmpty(clientProfile) &&
      !!hashInfo.length &&
      !document?.querySelector(".chatScript") &&
      isFirst.current &&
      !isEdm
    ) {
      const agreementsNumbers: any = [];
      const channel =
        import.meta.env.VITE_PUBLIC_URL === "/online/" ? ChatChannels.prodChannel : ChatChannels.testChannel;
      let brokerAccounts: any = [];
      agreements.forEach((agreement) => {
        if (agreement?.number.length) {
          agreementsNumbers.push(agreement?.number);
        }
      });
      agreements.forEach((agreement) => {
        brokerAccounts = brokerAccounts.concat(agreement?.brokerAccounts);
      });
      const userInfo = {
        uuid: conditionalValue(clientProfile?.id, "нет данных"),
        first_name: conditionalValue(clientProfile?.firstName, "нет данных"),
        last_name: conditionalValue(clientProfile?.lastName, "нет данных"),
        email: conditionalValue(
          clientProfile?.contacts?.find((e: any) => e.type === ClientProfileValues.EMAIL)?.value,
          "нет данных"
        ),
        "d-login": conditionalValue(clientProfile?.dlogin, "нет данных"),
        phone: conditionalValue(
          clientProfile?.contacts?.find((e: any) => e.type === ClientProfileValues.PHONE)?.value,
          "нет данных"
        ),
        accountNumbers: conditionalValue(brokerAccounts.join(","), "нет данных"),
        contract: conditionalValue(agreementsNumbers.join(","), "нет данных"),
        bcs_web: getBrowserVersion(),
        bcs_os_version: getUserAgentOSVersion(),
        timeZone: getTimezone()?.toString(),
        hash: conditionalValue(hashInfo, "нет данных"),
      };
      const chatScript = document.createElement("script");
      const chatUserCallbackScript = document.createElement("script");
      chatScript.type = "text/javascript";
      chatScript.async = true;
      chatScript.className = "craftTalkScript";
      chatScript.innerHTML = `!function(e, t) {
      var a = "${channel}"
        , n = "webchat-js-container:".concat(a);
      function s() {
        var t = e.createElement("iframe");
        t.id = n,
          t.className = n;
        var s = e.createElement("script");
        s.type = "text/javascript",
          s.async = !0,
          s.src = "//ct.bcs.ru/assets/js/" + a,
          s.crossOrigin = "anonymous",
          t.addEventListener("load", (function() {
              t.contentDocument.head.appendChild(s)
            }
          )),
          e.head.appendChild(t);
        var c = e.createElement("link");
        c.setAttribute("rel", "stylesheet"),
          c.setAttribute("type", "text/css"),
          c.setAttribute("crossorigin", "anonymous"),
          c.setAttribute("href", "//ct.bcs.ru/assets/css/" + a),
          e.getElementsByTagName("head")[0].appendChild(c)
      }
      "complete" === e.readyState ? s() : t.attachEvent ? t.attachEvent("onload", s) : t.addEventListener("load", s, !1)
    }(document, window);`;
      chatUserCallbackScript.type = "text/javascript";
      chatUserCallbackScript.async = true;
      chatUserCallbackScript.className = "chatUserCallbackScript";
      chatUserCallbackScript.innerHTML = `
        window.__WebchatUserCallback = function () {
          var data = ${JSON.stringify(userInfo)};
           
          return data;
       }`;
      document?.body?.appendChild(chatUserCallbackScript);
      document?.body?.appendChild(chatScript);
      isFirst.current = false;
    }
  }, [clientProfile, hashInfo, isEdm]);

  useEffect(() => {
    if (!isEmpty(clientProfile) && !document?.querySelector(".craftTalkScript")) {
      const openChat = document.createElement("script");

      openChat.type = "text/javascript";
      openChat.async = true;
      openChat.className = "openChat";
      openChat.innerHTML = `
          window.getWebChatCraftTalkExternalControl = (externalControl) => {
            externalControl.openWidget();
          }`;
      if (document?.querySelector(".closeChat") && document?.querySelector(".closeStyles")) {
        document?.querySelector(".closeChat")?.remove();
        document?.querySelector(".closeStyles")?.remove();
      }
      if (!document?.querySelector(".openChat") && document?.querySelector(".craftTalkScript")) {
        document?.body?.appendChild(openChat);
      }
      return () => {
        const closeChat = document.createElement("script");
        const closeStyles = document.createElement("style");

        closeStyles.className = "closeStyles";
        closeStyles.innerHTML = `
          .webchat {
            display: none !important;
          }
          .webchat-icon {
            display: none !important;
          }
          .webchat-icon.opened {
            display: none !important;
          }
        `;

        closeChat.type = "text/javascript";
        closeChat.async = true;
        closeChat.className = "closeChat";
        closeChat.innerHTML = `
          window.getWebChatCraftTalkExternalControl = (externalControl) => {
            externalControl.closeWidget();
          }`;
        if (!document?.querySelector(".closeChat")) {
          document?.body?.appendChild(closeChat);
          document?.body?.appendChild(closeStyles);
        }
        if (document?.querySelector(".openChat")) {
          document?.querySelector(".openChat")?.remove();
        }
      };
    }
  }, []);

  return null;
};

export default Chat;
