import React, { useCallback } from "react";
import { DialogTitle, Grid, LinearProgress } from "@mui/material";
import cn from "classnames";
import { makeStyles } from "tss-react/mui";

import { Theme } from "~/configs/theme";
import { clientChannels } from "~/constants/enums";
import { useTranslation } from "~/i18n";
import colorTable from "~/shared/ui-kit/constants/colors";
import HeaderSaveDocs from "./components/HeaderSaveDocs";
import { PrintForm } from "~/entities/common/models/PrintForm";

const useDialogTitleStyles = makeStyles()({
  root: {
    background: colorTable.charcoalBlack,
    padding: 0,
    minHeight: 130,

    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  webViewRoot: {
    background: colorTable.charcoalBlack,
    padding: 0,

    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
});

const useLinearProgressStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: 12,
    [theme.breakpoints.down(768)]: {
      height: "7px",
    },
  },
  colorPrimary: {
    backgroundColor: colorTable.anthraciteGray,
  },
  barColorPrimary: {
    backgroundColor: colorTable.nobleOchre,
  },
}));

const useCustomStyles = makeStyles()((theme: Theme) => ({
  root: {
    minHeight: 130,
    padding: "20px 25px",
    flexWrap: "nowrap",
    [theme.breakpoints.down(768)]: {
      flexDirection: "column",
      padding: "20px 16px",
    },
    [theme.breakpoints.down(576)]: {
      padding: "16px 8px",
    },
  },
  webViewRoot: {
    padding: "20px 25px",
    flexWrap: "nowrap",
    [theme.breakpoints.down(768)]: {
      flexDirection: "column",
      padding: "20px 16px",
    },
    [theme.breakpoints.down(576)]: {
      padding: "16px 8px",
    },
  },
  rootMobile: {
    flexWrap: "wrap",
  },
  title: {
    flexGrow: 1,
  },
  docNumber: {
    color: colorTable.white,
    opacity: 0.5,
    fontSize: 14,
  },
  docName: {
    width: "70%",
    color: colorTable.white,
    fontSize: 20,
    paddingTop: 12,
    [theme.breakpoints.down(768)]: {
      width: "calc(100% - 30px)",
      fontSize: 16,
      paddingTop: 0,
    },
  },
  download: {
    width: "30%",
    [theme.breakpoints.down(768)]: {
      width: "100%",
    },
    "& div": {
      display: "flex",
      justifyContent: "end",
      [theme.breakpoints.down(768)]: {
        justifyContent: "flex-start",
      },
    },
  },
}));

export interface HeaderProps {
  onSave: () => void;
  ready: boolean;
  progress: number;
  index: number;
  limit: number;
  title: string;
  currentDocument?: PrintForm;
  children?: never;
  isOne?: boolean;
  isDownloadingAvailable?: boolean;
}

const Header = ({ ready, progress, index, limit, title, onSave, isOne }: HeaderProps) => {
  const { t } = useTranslation();
  const { classes: dialogTitleClasses } = useDialogTitleStyles();
  const { classes: linearProgressClasses } = useLinearProgressStyles();
  const { classes: custom } = useCustomStyles();
  const xChannel = localStorage.getItem("X-Channel") || clientChannels.UCF_WEB;

  const handleOnSave = useCallback((): void => {
    onSave();
  }, [onSave]);

  // DUBAI-334 - fix issue of displaying the ru translation
  const titleText = limit > 1 ? "WIZARD.PRINT-FORMS.docs_plural" : "WIZARD.PRINT-FORMS.docs";

  return (
    <DialogTitle
      classes={{
        root: xChannel === clientChannels.UCF_WEB ? cn(dialogTitleClasses.root) : cn(dialogTitleClasses.webViewRoot),
      }}
    >
      <Grid
        container
        className={cn(xChannel === clientChannels.UCF_WEB ? custom.root : custom.webViewRoot)}
        alignContent="center"
      >
        <Grid item className={custom.title}>
          {ready && (
            <>
              <div className={cn(custom.docName)}>{title}</div>
              {!!limit && (
                <div className={cn(custom.docNumber)}>{t(titleText, { count: limit, index: index + 1 })}</div>
              )}
            </>
          )}
        </Grid>
        {xChannel === clientChannels.UCF_WEB && (
          <Grid item className={cn(custom.download)}>
            <HeaderSaveDocs isOne={isOne} onSave={handleOnSave} />
          </Grid>
        )}
      </Grid>
      <LinearProgress variant="determinate" value={ready ? progress : 0} classes={linearProgressClasses} />
    </DialogTitle>
  );
};

export default Header;
