import { IconNameType } from "~/shared/Icon";
import colorTable from "./colors";

export const LIGHT_GREY_CIRCLE = {
  padding: "8px",
  borderRadius: "50%",
  backgroundColor: colorTable.lightGrey,
  display: "flex",
};


export const WARNING_ICON_SETTINGS = {
  icon: "timeCurrentIcon" as IconNameType,
  iconColor: colorTable.orange_10,
  iconBackground: { backgroundColor: colorTable.yellowLight, width: "68px", height: "68px" },
}

export const SUCCESS_ICON_SETTINGS = {
  icon: "checkboxCurrent" as IconNameType,
  iconColor: colorTable.green_50,
  iconBackground: { backgroundColor: colorTable.lightGreen, width: "68px", height: "68px" },
}

export const ERROR_ICON_SETTINGS = {
  icon: "attentionIcon" as IconNameType,
  iconBackground: { backgroundColor: colorTable.redLight, width: "68px", height: "68px" },
}
