import { makeStyles } from "tss-react/mui";

import theme from "~/configs/theme";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()({
  link: {
    textDecoration: "none",
    cursor: "pointer",
    [theme.breakpoints.up(992)]: {
      "&:hover": {
        "& svg": {
          color: colorTable.nobleOchre,
        },
      },
    },
  },
  root: {
    height: 100,
    color: colorTable.white,
    padding: "26px 24px",
  },
});
