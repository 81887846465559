import { OperationsType } from "~/constants/enums";

export const HISTORY_WITHDRAWAL_MOCK = {
  amount: 10000,
  clientId: "1235",
  currency: "840",
  dateSent: "2023-12-11T15:49:37.999617",
  externalId: "123445",
  externalOperationId: "123455",
  id: "string",
  operationDatetime: "2023-12-11T15:49:37.999617",
  operationType: OperationsType.WITHDRAWAL,
  strategyId: "123455",
};

export const HISTORY_DEPOSIT_MOCK = {
  amount: 10000,
  clientId: "1235",
  currency: "840",
  dateSent: "2023-12-11T15:49:37.999617",
  externalId: "123445",
  externalOperationId: "123455",
  id: "string",
  operationDatetime: "2023-12-11T15:49:37.999617",
  operationType: OperationsType.DEPOSIT,
  strategyId: "123455",
};
