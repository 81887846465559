import { makeStyles } from "tss-react/mui";
import borderColors from "~/shared/ui-kit/constants/borderColors";

import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()(() => ({
  block: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 0",
    cursor: "pointer",
    "&:not(:last-child)": {
      borderBottom: `1px solid ${borderColors.greyContour}`,
    },
  },
  subtitle: {
    color: colorTable.moonGrey,
  },
  textBlock: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  },
}));
