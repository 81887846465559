import { createDomain, forward, sample } from "effector";
import { createGate } from "effector-react";

import { locationGroupEnum } from "~/constants/enums";
import { applicationsApi } from "~/entities/applications/api";
import { Content } from "~/entities/order-status";
import { AgreementTypeEnum } from "~/entities/productBuy/models/ClientCard";
import { t } from "~/i18n";
import { getCurrency } from "~/lib/money";
import { SelectOptions } from "~/shared/ui/Select";
import { clientCardModel } from "../client-card-model";
import { productListModel } from "../productsService";
import { cashGroups, cashLocationGroups, INITIAL_FILTER, INITIAL_FILTERED_PRODUCTS } from "./const";
import { filterBy } from "./helpers";
import { currentFilterArgs, filteredProductArgs } from "./types";

const operationIndexDomain = createDomain("operation index.ts domain");
const gate = createGate({ domain: operationIndexDomain });

const setPortfolioFilter = operationIndexDomain.createEvent<Partial<currentFilterArgs>>();
const resetPortfolioFilter = operationIndexDomain.createEvent();

export const changeLanguageEv = operationIndexDomain.createEvent<string>();
export const forceSetFilters = operationIndexDomain.createEvent();

const $currentFilter = operationIndexDomain
  .createStore<Partial<currentFilterArgs>>(INITIAL_FILTER)
  .on(setPortfolioFilter, (prev, store) => ({ ...prev, ...store }))
  .reset(resetPortfolioFilter);

const setAgreementFilterOptionsEv = operationIndexDomain.createEvent<SelectOptions[]>();
const $agreementsFilterOptions = operationIndexDomain
  .createStore<SelectOptions[]>([])
  .on(setAgreementFilterOptionsEv, (_, data) => data);
const setAccountsFilterOptionsEv = operationIndexDomain.createEvent<SelectOptions[]>();
const $accountsFilterOptions = operationIndexDomain
  .createStore<SelectOptions[]>([])
  .on(setAccountsFilterOptionsEv, (_, data) => data);

export const setCurrencyFilterOptionsEv = operationIndexDomain.createEvent<SelectOptions[]>();
const $currencyFilterOptions = operationIndexDomain
  .createStore<SelectOptions[]>([])
  .on(setCurrencyFilterOptionsEv, (_, data) => data);

const filteredProductsEv = operationIndexDomain.createEvent<Partial<filteredProductArgs>>();
const $filteredProducts = operationIndexDomain
  .createStore<filteredProductArgs>(INITIAL_FILTERED_PRODUCTS)
  .on(filteredProductsEv, (prev, state) => ({ ...prev, ...state }));

sample({
  source: clientCardModel.$agreements,
  clock: [clientCardModel.$agreements, changeLanguageEv, forceSetFilters],
  fn: (agreements) =>
    agreements
      .filter((item) => item.type !== AgreementTypeEnum.GENERAL_DU)
      .map((item) => ({
        value: item.number,
        label: t("PRODUCT.fields.agreement", {
          numberAgreement: item.number,
          dateAgreement: item.openDate,
        }),
      })),
  target: setAgreementFilterOptionsEv,
});

sample({
  source: setAgreementFilterOptionsEv,
  fn: (options) => ({ agreement: options[0]?.value || "" }),
  target: setPortfolioFilter,
});

sample({
  source: { agreements: productListModel.$accountAgreements, filter: $currentFilter },
  fn: ({ agreements, filter }) => {
    if (agreements) {
      const currentAccounts = agreements.find((item) => item.number === filter.agreement)?.brokerAccounts || [];
      return currentAccounts.reduce((curr: any, next: any) => {
        if (!curr.find((item: any) => item.value === next.accountNumber)) {
          return [
            ...curr,
            {
              value: next.accountNumber,
              label: next.accountNumber,
            },
          ];
        }
        return curr;
      }, []);
    }
  },
  target: setAccountsFilterOptionsEv,
});

sample({
  source: { agreements: productListModel.$accountAgreements, filter: $currentFilter },
  fn: ({ agreements, filter }) => {
    if (agreements) {
      const currentAccounts: any = agreements.find((item) => item.number === filter.agreement)?.brokerAccounts || [];

      return currentAccounts.reduce((curr: any, next: any) => {
        if (next.currencyInfo?.code && !curr.find((item: any) => item.value === next.currencyInfo?.code)) {
          return [
            ...curr,
            {
              value: next.currencyInfo?.code,
              label: getCurrency(next.currencyInfo?.code)?.name,
            },
          ];
        }
        return curr;
      }, []);
    }
  },
  target: setCurrencyFilterOptionsEv,
});

sample({
  source: { agreements: productListModel.$accountAgreements, filter: $currentFilter },
  fn: ({ agreements, filter }) => {
    const selectedAgreements = agreements.find((item) => item.number === filter.agreement);
    return {
      availableMoneyPlate: selectedAgreements?.brokerAccounts.filter((item: any) => {
        return (
          (item.locationGroup?.visibleType === locationGroupEnum.CLIENT_CASH_EUR ? item.balance > 0 : false) ||
          cashLocationGroups.includes(item.locationGroup?.visibleType)
        );
      }),
      availableMoney: filterBy(selectedAgreements?.brokerAccounts, filter.account, filter.currency).filter(
        (item: any) => {
          return (
            item.balance > 0 ||
            cashGroups.includes(item.groupId) ||
            cashLocationGroups.includes(item.locationGroup?.visibleType)
          );
        }
      ),
      forwards: filterBy(selectedAgreements?.forwards, filter.account, filter.currency),
      notes: filterBy(selectedAgreements?.notes, filter.account, filter.currency),
      others: filterBy(selectedAgreements?.others, filter.account, filter.currency),
      preIpos: filterBy(selectedAgreements?.preIpos, filter.account, filter.currency),
    } as filteredProductArgs;
  },
  target: filteredProductsEv,
});

const getNewApplicationsFx = operationIndexDomain.createEffect(applicationsApi.applicationsRequest);
const getNewApplications = operationIndexDomain.createEvent();
const $applications = operationIndexDomain
  .createStore<Content[]>([])
  .on(getNewApplicationsFx.doneData, (_, data) => data.content);
forward({ from: [gate.open, getNewApplications], to: getNewApplicationsFx });

export const operationIndexModel = {
  gate,
  $agreementsFilterOptions,
  $accountsFilterOptions,
  $currencyFilterOptions,
  $currentFilter,
  setPortfolioFilter,
  resetPortfolioFilter,
  $filteredProducts,
  changeLanguageEv,
  $applications,
  getNewApplications,
};
