import { makeStyles } from "tss-react/mui";

import { Theme } from "~/configs/theme";
import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useHeaderStyles = makeStyles()((theme: Theme) => ({
  grow: {
    flexGrow: 1,
  },
  buttonIcon: {
    marginRight: "8px",
  },
  h100: {
    height: "100%",
    maxWidth: "100%",
    padding: 0,
    [theme.breakpoints.down(768)]: {
      height: "auto",
      minHeight: "30px",
    },
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
  },
  headerContentWrapper: {
    padding: "0",
  },
  userTabsBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "40px",
    [theme.breakpoints.down(576)]: {
      gap: "8px",
    },
  },
  tabs: {
    [theme.breakpoints.down(992)]: {
      display: "none",
    },
  },
  sendMessage: {
    display: "block",
  },
  userDropdownWrapper: {
    height: "20px",
    [theme.breakpoints.down(768)]: {
      display: "none",
    },
  },
  space: {
    position: "relative",
  },
  tabsContainer: {
    "& > div > div": {
      display: "flex",
      gap: 52,
    },
  },
  newTabsContainer: {
    "& > div > div": {
      display: "flex",
      gap: 48,
    },
  },
  tab: {
    padding: "0!important",
    fontSize: "15px",
    color: `${colorTable.moonGrey} !important`,
    fontWeight: 500,
    minWidth: "fit-content!important",
    width: "fit-content!important",
    borderBottom: "3px solid transparent",
    textTransform: "none",
    fontFamily: "Suisse Intl, sans-serif",
    lineHeight: "18px",
    "&:hover": {
      color: `${colorTable.nobleOchre} !important`,
    },
  },
  newTab: {
    padding: "12px 0!important",
    fontSize: "16px",
    color: `${colorTable.moonGrey} !important`,
    fontWeight: 500,
    minWidth: "fit-content!important",
    width: "fit-content!important",
    borderBottom: "1px solid transparent",
    textTransform: "none",
    fontFamily: "Suisse Intl, sans-serif",
    lineHeight: "24px",
    "&:hover": {
      color: `${colorTable.nobleOchre} !important`,
    },
  },
  selected: {
    padding: "0!important",
    color: `${colorTable.black} !important`,
    fontWeight: 500,
    textTransform: "none",
    minWidth: "fit-content!important",
    width: "fit-content!important",
    borderBottom: `3px solid ${borderColors.nobleOchre}`,
    fontFamily: "Suisse Intl, sans-serif",
    fontSize: "15px",
    lineHeight: "18px",
  },
  newSelected: {
    color: `${colorTable.charcoalBlack} !important`,
    borderBottom: `1px solid ${borderColors.nobleOchre}`,
    padding: "12px 0!important",
    fontSize: "16px",
    fontWeight: 500,
    minWidth: "fit-content!important",
    width: "fit-content!important",
    textTransform: "none",
    fontFamily: "Suisse Intl, sans-serif",
    lineHeight: "24px",
  },
  burgerButton: {
    cursor: "pointer",
    background: colorTable.white_50,
    top: 20,
    right: 5,
    zIndex: 11,
    borderRadius: "6px 0px 0px 6px",
    display: "none",
    backgroundColor: colorTable.transparent,
    [theme.breakpoints.down(992)]: {
      display: "block",
    },
  },
  burgerButtonIcon: {
    width: "20px",
    height: "20px",
    [theme.breakpoints.down(768)]: {
      width: "14px",
      height: "14px",
    },
  },
  rightSide: {
    display: "flex",
    alignItems: "center",
    gap: 64,

    [theme.breakpoints.down(768)]: {
      gap: 24,
    },
  },
  actionsWrap: {
    display: "flex",
    alignItems: "center",
    height: 24,
    gap: 24,
  },
  menuLayout: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: colorTable.black_50,
    zIndex: 12,
  },
  sideMenu: {
    position: "fixed",
    right: 0,
    top: 0,
    maxWidth: "0px",
    height: "100%",
    overflow: "hidden",
    zIndex: 13,
    transition: "max-width, padding 0.5s ease-in",
    backgroundColor: colorTable.white,
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "0",
  },
  isSideMenu: {
    maxWidth: "80%",
    padding: "20px",
  },
  menuItem: {
    cursor: "pointer",
    lineHeight: "22px",
    fontSize: "16px",
    color: "black",
  },
  closeMenuElem: {
    position: "fixed",
    width: "100vw",
    height: "100vh",
    left: 0,
    top: 0,
    background: colorTable.black_50,
  },
  menuItemBurger: {
    display: "flex",
    color: colorTable.lightBlue_10,
    margin: "20px",
    fontSize: "32px",
  },
  currentItem: {
    background: colorTable.white_50,
    padding: "15px",
    margin: 0,
  },
  appBar: {
    background: colorTable.white,
    height: "80px",
    boxShadow: "none!important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > div": {
      height: "fit-content",
    },
    [theme.breakpoints.down(768)]: {
      minHeight: "30px",
      height: "auto",
      paddingTop: "12px",
      paddingBottom: "12px",
    },
  },
  contactUs: {
    height: "20px",
  },
}));
