import React, { ReactNode } from "react";
import { Box, Button, SwipeableDrawer, useMediaQuery } from "@mui/material";
import DialogBase from "@mui/material/Dialog";
import { ClassNameMap } from "@mui/styles";
import cn from "classnames";

import { colors } from "~/constants/enums";
import Icon from "~/shared/Icon";
import { useDialogStyles, useIconButtonStyles, useStyles } from "./styles";

export interface BaseModalProps {
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
  crossClassName?: string;
  maxWidthContent?: string | null;
  paperClasses?: ClassNameMap;
  classNameContent?: string;
  isHideMobileClose?: boolean;
}

const BaseModal = ({
  open,
  onClose,
  children,
  crossClassName,
  maxWidthContent,
  paperClasses,
  classNameContent,
  isHideMobileClose,
}: BaseModalProps) => {
  const { classes } = useStyles({ maxWidthContent });
  const { classes: dialogClasses } = useDialogStyles();
  const { classes: iconButtonClasses } = useIconButtonStyles();
  const isTabletOrMobile = useMediaQuery("(max-width: 768px)");

  return (
    <>
      {isTabletOrMobile ? (
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={onClose}
          onOpen={() => {}}
          classes={paperClasses || dialogClasses}
          BackdropProps={{ style: { backgroundColor: colors.dialogBackground } }}
        >
          <Button
            className={cn(iconButtonClasses.mobileButton, { [iconButtonClasses.mobileButtonLine]: !isHideMobileClose })}
            onClick={onClose}
          ></Button>
          <Box
            className={cn(classNameContent ? classNameContent : classes.wrapperContent, classes.maxWidthContent)}
            onTouchMoveCapture={(event) => event.stopPropagation()}
          >
            {children}
          </Box>
        </SwipeableDrawer>
      ) : (
        <DialogBase
          open={open}
          onClose={onClose}
          classes={paperClasses || dialogClasses}
          BackdropProps={{ style: { backgroundColor: colors.dialogBackground } }}
        >
          <Box onClick={onClose} className={cn(iconButtonClasses.root, crossClassName)}>
            <Icon name="modalCross" size={24} />
          </Box>
          <Box className={cn(classNameContent ? classNameContent : classes.wrapperContent, classes.maxWidthContent)}>
            {children}
          </Box>
        </DialogBase>
      )}
    </>
  );
};

export default BaseModal;
