import { createReducer, Reducer } from "~/lib/redux";
import { AsyncData, AsyncDataPhase, error, initial, success } from "~/lib/redux/async";

import {
  authLoginStartAction,
  authLogoutErrorAction,
  authLogoutStartAction,
  authLogoutSuccessAction,
  authSetTokenStateAction,
  authLoginClearAction,
} from "./actions";

export const STATE_NAME = "auth";
export const defaultState: AsyncData<any> = initial();

export type AuthState = AsyncData<any>;

const reducer: Reducer<AuthState> = createReducer<AuthState>({}, defaultState);

const pendingReducer = (state: AuthState): AuthState => ({
  ...state,
  phase: AsyncDataPhase.Pending,
});

reducer.on(authLoginStartAction, pendingReducer);

reducer.on(authLoginClearAction, () => success<any>({ result: false }));

reducer.on(authLogoutStartAction, pendingReducer);
reducer.on(authLogoutSuccessAction, (): AuthState => success<any>({ result: false }));
reducer.on(authLogoutErrorAction, (state: AuthState, err: Error): AuthState => error<any>(state.data || null, err));

reducer.on(authSetTokenStateAction, (_, payload): AuthState => {
  if (payload.result) {
    const { result, userName, isTemporaryPassword, securityQuestion } = payload;
    return success<any>({ result, userName, isTemporaryPassword, securityQuestion });
  }

  return success<any>({ result: false });
});

export default reducer;
