import Big from "big.js";
import { isNil } from "lodash";

import { CURRENCY_CODES, TCurrencyCode } from "~/constants/currencyCodes";
import { locales } from "~/constants/enums";
import { SHORT_MONEY_VALUES } from "~/constants/enums/money";
import { CurrencyCode } from "~/entities/common";
import { getValueByCondition } from "~/lib/utils";

export type Sign = -1 | 1;

export default class Money {
  readonly amount: string;
  readonly currency: CurrencyCode;
  readonly sign: Sign;

  constructor({ amount, currency = CurrencyCode.USD }: { amount: string | number | null; currency?: CurrencyCode }) {
    this.amount = isNil(amount) ? "0" : Money.normalizeAmount(amount);
    this.currency = currency;
    this.sign = Money.parseSign(amount);
  }

  static isMoney(value: Money | string | number): boolean {
    return value instanceof Money;
  }

  static normalizeAmount(amount: string | number): string {
    if (typeof amount === "string") {
      return amount
        .replace(/\s/g, "")
        .replace(",", ".")
        .replace(/[^\d\\.\\-\\+]+/g, "");
    }

    return amount.toString();
  }

  static fixAmount(amount: null | string | number): string {
    if (isNil(amount)) {
      return "0";
    }

    return new Big(Money.normalizeAmount(amount)).toFixed(2);
  }

  static parseSign(amount: null | string | number): Sign {
    if (isNil(amount)) return 1;

    return new Big(amount).s as Sign;
  }

  getAmount(): string {
    return this.amount;
  }

  getCurrency(): CurrencyCode {
    return this.currency;
  }

  getSign(): Sign {
    return this.sign;
  }

  toObject(): { amount: string; currency: CurrencyCode } {
    return {
      amount: this.getAmount(),
      currency: this.getCurrency(),
    };
  }

  toUnit(): string {
    return Money.fixAmount(this.getAmount());
  }

  add(added: Money): Money {
    const sum = new Big(this.toUnit()).plus(added.toUnit()).toString();

    return new Money({ amount: sum, currency: this.getCurrency() });
  }

  divide(diviser: Money | string | number): Money {
    const div = Money.isMoney(diviser) ? (diviser as Money).toUnit() : (diviser as string | number);
    const result = new Big(this.toUnit()).div(div).toString();

    return new Money({ amount: result, currency: this.getCurrency() });
  }

  multiply(multiplier: number | string): Money {
    const result = new Big(this.toUnit()).times(multiplier).toString();

    return new Money({ amount: result, currency: this.getCurrency() });
  }

  subtract(subtrahend: Money): Money {
    const result = new Big(this.toUnit()).minus(subtrahend.toUnit()).toString();

    return new Money({ amount: result, currency: this.getCurrency() });
  }
}

export const floatToMoney = (money: string | number, lang?: string) => {
  const parts = money.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, lang === locales.EN ? "," : " ");
  parts[1] = parts[1]?.slice(0, 2);

  if (parts[1] && parts[1] !== "00") {
    return parts.join(".");
  }
  return parts[0];
};

export const getCurrency = (currency?: string): TCurrencyCode | undefined => {
  if (!currency) return;

  return CURRENCY_CODES.find((code) => code.code === currency);
};

export const getPlusOrMinusAmount = (amount?: string | number) => {
  if (!amount) return "";
  return Number(amount) > 0 ? "+" : "";
};

export const getRoundedPercentage = (percent?: number, decimal: number = 2, withInteger: boolean = true) => {
  if (percent !== undefined && percent !== null) {
    if (withInteger && Number.isInteger(percent)) {
      return `${percent}%`;
    }

    return `${Number(percent)?.toFixed(decimal)}%`;
  }
  return "";
};

export const getPriceWithSpaces = (price?: number) => {
  if (!price) return 0;

  if (Number.isInteger(price)) {
    return price?.toLocaleString("ru");
  }

  const floatPrice = Number(price)?.toFixed(2);
  return Number(floatPrice)
    ?.toLocaleString("ru", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    .replace(/,/g, ".");
};

export const getAmountWithCurrency = (number: number, currency?: string) => {
  return getPriceWithSpaces(number) + " " + (currency ?? "");
};

export type TArgsMoneyСonverter = {
  lang: string;
  currencySymbol?: string;
  money: string | number;
  isPlusSymbol?: boolean;
  isMinusSymbol?: boolean;
  isGreaterSymbol?: boolean;
  isLessSymbol?: boolean;
};

export const moneyConverter = (moneyInfo: TArgsMoneyСonverter) => {
  const currencySymbol = moneyInfo.currencySymbol ?? "";
  let greaterLessOrNothing = getValueByCondition(
    [
      {
        condition: Boolean(moneyInfo.isPlusSymbol),
        value: "+",
      },
      { condition: Boolean(moneyInfo.isMinusSymbol), value: "-" },
    ],
    ""
  );
  let plusOrMinusOrNothing = getValueByCondition(
    [
      {
        condition: Boolean(moneyInfo.isGreaterSymbol),
        value: "<",
      },
      { condition: Boolean(moneyInfo.isLessSymbol), value: ">" },
    ],
    ""
  );

  const money = floatToMoney(moneyInfo.money, moneyInfo.lang);

  let result = greaterLessOrNothing || plusOrMinusOrNothing;

  if (moneyInfo.lang === locales.RU) {
    result = result + money + " " + currencySymbol;
    return result;
  }
  result = result + currencySymbol + money;
  return result;
};

/**
 * Функция для локализации сумм.
 * @param sum Входная сумма, например, "1205.79".
 * @param language Текущий язык приложения.
 * @returns Число в виде строке с десятичным разделителем. Например: для "ru" 1 000 000.98, для "en" 1,000,000.98.
 */
export const localizationSum = (sum: string, language: string): string => {
  const fractionalPart = sum.split(".");
  const minimumFractionDigits = fractionalPart.length > 1 && (fractionalPart.at(-1) ?? "").length > 0 ? 2 : 0;
  const formatter = new Intl.NumberFormat(language, { minimumFractionDigits });
  const formattedValue = formatter.format(parseFloat(sum));

  if (language === locales.RU) {
    return formattedValue.replace(/,/, ".");
  }
  return formattedValue;
};

/**
 * Функция преобразования строки с лишними символами в число.
 *
 * @param input Входное число в формате строки с двумя знаками после запятой.
 * @returns Строка без знаков валюты и десятичного разделителя (,), если для разделения целой части используется запятая,
 * то она пребразована в точку.
 */
export const cleanUpNumber = (input: string): string => {
  if (!input) return "";

  let cleaned = input.replace(/[^\d.,]/g, "");
  let res = "";

  for (let index = 0; index < cleaned.length; index++) {
    const element = cleaned[index];

    if ([",", "."].includes(element) && (index === cleaned.length - 3 || index === cleaned.length - 2)) {
      res = res + ".";
    }

    if (![",", "."].includes(element)) {
      res = res + element;
    }
  }

  return res;
};

export const moneyWithCurrency = (money: string, currencySymbol: string, lang: string) => {
  return lang === locales.EN ? currencySymbol + money : `${money} ${currencySymbol}`;
};

export const shortMoney = (num: number, currencySymbol: string, lang: locales) => {
  if (num < 1000) {
    // Форматируем число и обрезаем лишние нули
    const formattedNum = parseFloat(num.toFixed(2))
      .toString()
      .replace(".", lang === locales.EN ? "." : ",");
    return moneyWithCurrency(formattedNum, currencySymbol, lang);
  }

  const space = lang === locales.EN ? "" : " ";
  const separator = lang === locales.EN ? "." : ",";
  const thousandsSeparator = lang === locales.EN ? "," : " ";

  // Форматирование числа с разделителями
  const formatWithSeparators = (value: number) => {
    const rounded = parseFloat(value.toFixed(2)); // Округляем до 2 знаков
    const parts = rounded.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator); // Разделители для классов
    return parts.join(separator); // Устанавливаем разделитель дробной части
  };

  // Определяем самый высокий класс числа
  const trillion = num / 1_000_000_000_000; // Триллионы
  const billion = num / 1_000_000_000; // Миллиарды
  const million = num / 1_000_000; // Миллионы
  const thousand = num / 1000; // Тысячи

  let result = "";

  if (trillion >= 1) {
    result = `${formatWithSeparators(trillion)}${space}${SHORT_MONEY_VALUES.T[lang]}`;
  } else if (billion >= 1) {
    result = `${formatWithSeparators(billion)}${space}${SHORT_MONEY_VALUES.B[lang]}`;
  } else if (million >= 1) {
    result = `${formatWithSeparators(million)}${space}${SHORT_MONEY_VALUES.M[lang]}`;
  } else if (thousand >= 1) {
    result = `${formatWithSeparators(thousand)}${space}${SHORT_MONEY_VALUES.K[lang]}`;
  }

  return moneyWithCurrency(result, currencySymbol, lang);
};

export const getCurrencySymbolByName = (currency: string): string => {
  return CURRENCY_CODES.find((code) => code.name === currency)?.symbol ?? "";
};

export const getPercentOrDash = (data?: string | number | null) => {
  return !data ? "-" : `${data}%`;
};
