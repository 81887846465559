import { createAsyncReducer } from "~/lib/redux";
import { initial } from "~/lib/redux/async";

import {
  buyProductStartAction,
  buyProductSuccessAction,
  buyProductErrorAction,
  buyProductClearAction,
  productClearAction,
} from "../actions";

export const STATE_NAME = "buy";

const [reducer, defaultState] = createAsyncReducer(
  buyProductStartAction,
  buyProductSuccessAction,
  // @ts-ignore vite
  buyProductErrorAction,
  buyProductClearAction
);

reducer.on(productClearAction, () => initial());

export type BuyState = typeof defaultState;

export default reducer;
