import { makeStyles } from "tss-react/mui";

import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()({
  wrapper: {
    border: "1px solid #D9D9D9",
    borderRadius: "50%",
    width: "32px",
    height: "32px",
  },
  type: {
    background: colorTable.white_20,
    padding: "2px 8px",
    fontSize: "10px",
    lineHeight: "14px",
    fontWeight: 400,
    color: colorTable.lightGrey_40,
    borderRadius: "8px",
    position: "absolute",
    top: "22px",
    left: "50%",
    transform: "translate(-50%)",
  },
  iconWrapper: {
    position: "relative",
    width: "32px",
    height: "32px",
  },
  hiddenChartWrapper: {
    width: "32px",
    height: "32px",
    display: "none",
  },
  chartWrapper: {
    width: "32px",
    height: "32px",
    overflow: "hidden",
    borderRadius: "50%",
  },
});
