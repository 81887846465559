import { createAsyncReducer } from "~/lib/redux";

import {
  keycloakCheckOtpClearAction,
  keycloakCheckOtpErrorAction,
  keycloakCheckOtpStartAction,
  keycloakCheckOtpSuccessAction,
} from "../actions";

export const STATE_NAME = "checkOtp";

const [reducer, defaultState] = createAsyncReducer(
  keycloakCheckOtpStartAction,
  keycloakCheckOtpSuccessAction,
  keycloakCheckOtpErrorAction,
  keycloakCheckOtpClearAction,
);

export type KeycloakCheckOtpState = typeof defaultState;

export default reducer;
