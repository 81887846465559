export enum ResetPasswordTypes {
  RESET_BY_DOCUMENTS,
  RESET_BY_SECURITY_QUESTION,
}

export enum LoginStepsTypes {
  LOGIN = "Login",
  TEMPORARILY_PASSWORD = "TemporarilyPassword",
  TEMPORARILY_BLOCKED_ONE_HOUR = "TemporarilyBlockedOneHour",
  TEMPORARILY_BLOCKED_24_HOURS = "TemporarilyBlocked24Hour",
  BLOCKED_USER = "BlockedUser",
  SET_SECURITY_QUESTION = "SET_SECURITY_QUESTION",
  SET_SECURITY_QUESTION_WITHOUT_CAMUNDA = "SET_SECURITY_QUESTION_WITHOUT_CAMUNDA",
  ENTER_OTP_CODE = "ENTER_OTP_CODE",
  SET_SECURITY_QUESTION_DONE = "SET_SECURITY_QUESTION_DONE",
  CANNOT_SEND_SMS = "CANNOT_SEND_SMS",
  OTP_TIME_EXPIRED = "OTP_TIME_EXPIRED",
}

export enum LoginLocks {
  FIRST_LOCK = "FIRST_LOCK",
  SECOND_LOCK = "SECOND_LOCK",
  THIRD_LOCK = "THIRD_LOCK",
  PERMANENT_LOCK = "PERMANENT_LOCK",
}

export enum loginErrorsEnum {
  invalidGrant = "invalid_grant",
  invalidUsername = "invalid_username",
}
