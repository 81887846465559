import { makeStyles } from "tss-react/mui";

import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()({
  container: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "16px",
    borderBottom: `1px solid ${colorTable.greyContour}`,
    cursor: "pointer",

    "&:not(:first-child)": {
      padding: "16px 0",
    },
    "&:hover": {
      background: colorTable.white_20,
    },
  },
  leftSide: {
    display: "flex",
    gap: "16px",
  },
  icon: {
    width: "40px",
    height: "48px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  titlesWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",

    "& > span": {
      color: colorTable.moonGrey,
    },
    "& > p": {
      color: colorTable.charcoalBlack,
    },
  },
  rightSide: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    textAlign: "right",

    "& > p": {
      color: colorTable.moonGrey,
    },
  },
  avgWrap: {
    color: colorTable.moonGrey,

    "& > span:first-child": {
      color: colorTable.charcoalBlack,
    },
  },
});
