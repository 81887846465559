import { createReducer, Reducer } from "~/lib/redux";
import { AsyncData, AsyncDataPhase, initial } from "~/lib/redux/async";

import { anketaSaveEconomySectionStartAction, anketaUpdateEconomySectionStartAction } from "../actions";

export const STATE_NAME = "saveOrUpdateEconomySection";

export type AnketaSaveOrUpdateEconomySectionState = AsyncData<any>;

export const defaultState: AnketaSaveOrUpdateEconomySectionState = initial();

const reducer: Reducer<AnketaSaveOrUpdateEconomySectionState> = createReducer<AnketaSaveOrUpdateEconomySectionState>(
  {},
  defaultState
);

const pendingReducer = (state: AnketaSaveOrUpdateEconomySectionState): AnketaSaveOrUpdateEconomySectionState => ({
  ...state,
  phase: AsyncDataPhase.Pending,
});

reducer.on(anketaSaveEconomySectionStartAction, pendingReducer);
reducer.on(anketaUpdateEconomySectionStartAction, pendingReducer);

export default reducer;
