import { makeStyles } from "tss-react/mui";

import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()({
  container: {
    padding: "24px",
    borderRadius: "8px",
    backgroundColor: colorTable.white_20,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  priceWrap: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
    color: colorTable.charcoalBlack,
  },
  subtitle: {
    marginTop: "8px",
    color: colorTable.moonGrey,
  },
  tooltip: {
    "& circle": {
      fill: colorTable.moonGrey,
    },
    "& path": {
      fill: colorTable.white,
    },
    "& rect": {
      fill: colorTable.white,
    },
  },
});
