import {
  Action,
  BaseActionCreator,
  createAction,
  createReducer,
  MetaReducer,
  Reducer,
  SimpleActionCreator,
} from "redux-act";
import { SagaIterator } from "redux-saga";
import { ForkEffect, takeEvery, takeLatest } from "redux-saga/effects";
import { createSelector } from "reselect";

import { AsyncData, AsyncDataPhase, initial } from "./async";

export * from "./models";

export { Action, createAction, createReducer, createSelector, Reducer };

interface EmptyActionCreatorWithMeta<M = NonNullable<unknown>>
  extends BaseActionCreator<EmptyActionCreatorWithMeta<M>> {
  (): Action<undefined, M>;

  raw(): Action<undefined, M>;
  asError(): Action<undefined, M>;
}

interface Value<P> {
  value: P;
}

type SmartActionCreator<P, M, V extends Value<P> = Value<P>> = V["value"] extends null
  ? EmptyActionCreatorWithMeta<M>
  : SimpleActionCreator<P, M>;

type AsyncActions<TRes, TReq, TError extends Error, M> = [
  SmartActionCreator<TReq, M>,
  SmartActionCreator<TReq, M>,
  SmartActionCreator<TRes, M>,
  SmartActionCreator<TError, M>,
  SmartActionCreator<null, M>,
];

export const reduxClearStoreAction = createAction("REDUX_CLEAR_STORE");

export const createSmartAction = <P extends null | unknown = null, M = NonNullable<unknown>>(
  description: string,
  metaReducer?: MetaReducer<M>
): SmartActionCreator<P, M> => createAction<P, M>(description, (payload) => payload, metaReducer) as any; // eslint-disable-line @typescript-eslint/no-explicit-any

export const createAsyncRequestAction = <P = null, M = NonNullable<unknown>>(
  description: string,
  metaReducer?: MetaReducer<M>
): SmartActionCreator<P, M> => createSmartAction<P, M>(`${description}/REQUEST`, metaReducer);

export const createAsyncStartAction = <P = null, M = NonNullable<unknown>>(
  description: string
): SmartActionCreator<P, M> => createSmartAction<P, M>(`${description}/START`);

export const createAsyncSuccessAction = <P = null, M = NonNullable<unknown>>(
  description: string
): SmartActionCreator<P, M> => createSmartAction<P, M>(`${description}/SUCCESS`);

export const createAsyncErrorAction = <TError extends Error = Error, M = NonNullable<unknown>>(
  description: string
): SmartActionCreator<TError, M> => createSmartAction<TError, M>(`${description}/ERROR`);

export const createAsyncClearAction = <M = NonNullable<unknown>>(description: string): SmartActionCreator<null, M> =>
  createSmartAction<null, M>(`${description}/CLEAR`);

export function createAsyncActions<TRes = null, TReq = null, TError extends Error = Error, M = NonNullable<unknown>>(
  description: string,
  metaReducer?: MetaReducer<M>
): AsyncActions<TRes, TReq, TError, M> {
  return [
    createAsyncRequestAction<TReq, M>(description, metaReducer),
    createAsyncStartAction<TReq, M>(description),
    createAsyncSuccessAction<TRes, M>(description),
    createAsyncErrorAction<TError, M>(description),
    createAsyncClearAction<M>(description),
  ];
}

export const onReset = <S>(reducer: Reducer<S, any>, defaultState: S): void => {
  reducer.on(reduxClearStoreAction, (): S => defaultState);
};

export function createAsyncReducer<TRes = null, TReq = null, TError extends Error = Error, M = NonNullable<unknown>>(
  start: SmartActionCreator<TReq, M>,
  success: SmartActionCreator<TRes, M>,
  error: SmartActionCreator<TError, M>,
  clear: SmartActionCreator<null, M> | null = null,
  clearOnReset = true
): [Reducer<AsyncData<TRes, TError>>, AsyncData<TRes, TError>] {
  const defaultState: AsyncData<TRes, TError> = initial();
  const reducer: Reducer<AsyncData<TRes, TError>> = createReducer<AsyncData<TRes, TError>>({}, defaultState);

  reducer.on(
    start,
    (state): AsyncData<TRes, TError> => ({
      ...state,
      phase: AsyncDataPhase.Pending,
    })
  );

  reducer.on(
    success,
    (_, payload): AsyncData<TRes, TError> => ({
      phase: AsyncDataPhase.Success,
      data: payload as TRes,
      error: null,
    })
  );

  reducer.on(
    error,
    (state, payload): AsyncData<TRes, TError> => ({
      ...state,
      phase: AsyncDataPhase.Error,
      error: payload as TError,
    })
  );

  if (clear) {
    reducer.on(clear, (): AsyncData<TRes, TError> => defaultState);
  }

  if (clearOnReset) {
    onReset(reducer, defaultState);
  }

  return [reducer, defaultState];
}

interface ActionFunc<P = null, M = NonNullable<unknown>> {
  (payload: P): Action<P, M>;
  getType: () => string;
}

export const takeLatestAction = <P = null, M = NonNullable<unknown>>(
  actionFunc: ActionFunc<P, M>,
  worker: (action: Action<P, M>) => SagaIterator
): ForkEffect => takeLatest(actionFunc.getType(), worker);

export const takeEveryAction = <P = null, M = NonNullable<unknown>>(
  actionFunc: ActionFunc<P, M>,
  worker: (action: Action<P, M>) => SagaIterator
): ForkEffect => takeEvery(actionFunc.getType(), worker);
