import React, { useState } from "react";
import { Box, Button, Checkbox, Grid, useMediaQuery } from "@mui/material";
import cn from "classnames";
import { makeStyles } from "tss-react/mui";

import { Theme } from "~/configs/theme";
import { useTranslation } from "~/i18n";
import colorTable from "~/shared/ui-kit/constants/colors";
import Icon from "~/shared/Icon";

export interface ViewOneFormActionProps {
  blocked: boolean;
  onAccept: () => void;
  watchText: string;
  children?: never;
  acceptText?: () => string;
  readonly?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: "20px 25px",
    justifyContent: "space-between",
    [theme.breakpoints.down(768)]: {
      padding: "8px 16px",
    },
  },
  rootMobile: {
    flexWrap: "wrap",
  },
  icon: {
    marginRight: 24,
    [theme.breakpoints.down(768)]: {
      marginRight: "8px",
    },
  },
  content: {
    flexGrow: 1,
    marginRight: 130,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down(768)]: {
      margin: 0,
    },
    [theme.breakpoints.down(576)]: {
      fontSize: "14px",
    },
  },
  mobileContent: {
    marginRight: 0,
  },
  mobileText: {
    fontSize: "32px",
    lineHeight: "32px",
  },
  mobileCheckbox: {
    "& .MuiSvgIcon-root": {
      width: "2em",
      height: "2em",
    },
  },
  buttonWrapper: {
    [theme.breakpoints.down(768)]: {
      width: "100%",
    },
  },
  button: {
    height: "100px",
    width: "100%",
    marginTop: "50px",
  },
  continueBtn: {
    [theme.breakpoints.down(768)]: {
      width: "100%",
      marginTop: "8px",
      height: "48px",
      fontSize: "14px",
    },
  },
  checkbox: {
    [theme.breakpoints.down(768)]: {
      width: "20px",
      height: "20px",
    },
  },
}));

const ViewOneFormAction = (props: ViewOneFormActionProps) => {
  const { t } = useTranslation();

  const { blocked, onAccept, watchText, acceptText = t("WIZARD.PRINT-FORMS.descriptions.accept"), readonly } = props;

  const [accepted, setAccepted] = useState(false);
  const { classes } = useStyles();
  const isTabletOrMobile = useMediaQuery("(max-width: 768px)");

  const handleAccepted = (): void => setAccepted(!accepted);

  return (
    <Grid
      style={{ border: "1px solid transparent" }}
      container
      className={cn(classes.root, isTabletOrMobile && classes.rootMobile)}
      direction="row"
      wrap="nowrap"
      alignItems="center"
    >
      {readonly ? (
        <Grid item>{watchText}</Grid>
      ) : (
        <>
          <Box style={{ display: "flex" }}>
            <Grid item className={classes.icon}>
              {blocked ? (
                <Icon name="arrowDownwardRounded" size={32} />
              ) : (
                <Checkbox checked={accepted} onChange={handleAccepted} className={classes.checkbox} />
              )}
            </Grid>
            <Grid item className={cn(classes.content)}>
              {blocked ? watchText : acceptText}
            </Grid>
          </Box>
          <Grid item className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="primary"
              style={{ background: blocked || !accepted ? colorTable.lightGrey_5 : colorTable.darkBlue }}
              disabled={blocked || !accepted}
              onClick={onAccept}
              className={classes.continueBtn}
            >
              <span>{t("GENERAL.continue")}</span>
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ViewOneFormAction;
