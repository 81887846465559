import moment from "moment";

import { locales, months } from "~/constants/enums";
import i18n, { t } from "~/i18n";
import { padDate } from "~/lib/date";

export const getDayOfDate = (date: string): string => moment(date).format("D");

export const getMonthLocalesOfDate = (date: string, i18nLanguage: string) => {
  const month = padDate(moment(date).format("MM"));

  return `${i18nLanguage === "ru" ? months[month]["rus"] : months[month]["eng"]}`;
};

export const getYearOfDate = (date: string): string => moment(date).format("YYYY");

export const formatRelativeDate = (date: string, withTime?: boolean) => {
  const time = moment(date).format("HH:mm");

  if (moment(date).format("DD.MM.YYYY") === moment().format("DD.MM.YYYY")) {
    const today = t("GENERAL.today");

    return withTime ? `${today}, ${time}` : today;
  }
  if (
    moment(date).format("MM.YYYY") === moment().format("MM.YYYY") &&
    moment(date).format("DD") === moment().subtract(1, "days").format("DD")
  ) {
    const yesterday = t("GENERAL.yesterday");

    return withTime ? `${yesterday}, ${time}` : yesterday;
  }

  const month = padDate(moment(date).format("MM"));
  const nameMonth = i18n.language === locales.RU ? months[month]["rus"] : months[month]["eng"];
  const day = moment(date).format("DD");

  const displayedYear = new Date(date).getFullYear() === new Date().getFullYear() ? "" : moment(date).format("YYYY");

  const resultDate = `${parseInt(day)} ${nameMonth} ${displayedYear}`;

  return withTime ? `${resultDate}, ${time}` : resultDate;
};
