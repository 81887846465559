import { t } from "~/i18n";
import { IconNameType } from "~/shared/Icon";

export enum colors {
  white = "white",
  black = "black",
  dialogBackground = "rgba(0, 0, 0, 0.75)",
}

export enum PDFFileTypes {
  CONSENT_BCS = "CONSENT_BCS",
  CONSENT_BCS_BANK = "CONSENT_BCS_BANK",
  DUBAI_ELECTRONIC_TERMS = "DUBAI_ELECTRONIC_TERMS",
  CLIENT_PURCHASE_CONFIRMATION = "CLIENT_PURCHASE_CONFIRMATION",
  TRADE_ORDER = "TRADE_ORDER",
  AGREEMENT_BCS_BANK = "AGREEMENT_BCS_BANK",
  AGREEMENT_BCS = "AGREEMENT_BCS",
  RISK_DISCLOSURE = "risk_disclosure",
  ACCEPTANCE_OF_GENERAL_TERMS = "acceptance-of-general-terms",
  IDENTIFICATION_FORM = "identification_form",
  ASSET_MANAGEMENT_REQUEST = "asset_management_request",
  ACCEPTANCE_OF_GENERAL_TERMS_DU = "acceptance-of-general-terms_du",
  PROFILE = "profile",
  LEXIS_REPORT = "lexis_report",
  DMA = "dma",
  FEE_SCHEDULE_EFFECTIVE = "fee-schedule-effective",
  TRANSFER_ORDER = "transfer-order",
  W_8 = "W-8",
  NOTES_TRADER_ORDER = "notes-trade-order",
  REPRESENTATION = "representation",
  STATUS_PROFESSIONAL = "status-professional",
  FUNDS_WITHDRAWAL_FORM = "funds_withdrawal_form",
}

export enum PDFFileNames {
  CONSENT_BCS = "Request for Consent for the disclosure of personal data to BrokerCreditService Ltd",
  CONSENT_BCS_BANK = "Request for Consent for the disclosure of personal data to “BCS Bank” AO",
  DUBAI_ELECTRONIC_TERMS = "Acceptance of Electronic servicesTerms",
  CLIENT_PURCHASE_CONFIRMATION = "Client Confirmation",
  TRADE_ORDER = "Trade order",
  AGREEMENT_BCS_BANK = "Agreement with BCS Bank",
  AGREEMENT_BCS = "Agreement with BCS",
  RISK_DISCLOSURE = "OTC Derivatives Risk Disclosure Statement",
  ACCEPTANCE_OF_GENERAL_TERMS = "Acceptance of general terms",
  IDENTIFICATION_FORM = "Individual Client Identification Form",
  ASSET_MANAGEMENT_REQUEST = "Asset Management Request",
  ACCEPTANCE_OF_GENERAL_TERMS_DU = "Acceptance of asset management agreement",
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  PROFILE = "Individual Client Identification Form",
  LEXIS_REPORT = "Lexis nexis report",
  DMA = "Service Order Form US market via GT",
  FEE_SCHEDULE_EFFECTIVE = "Fee schedule",
  TRANSFER_ORDER = "Order for money transfer",
  W_8 = "W8-BEN",
  NOTES_TRADER_ORDER = "Notes trade order",
  REPRESENTATION = "Representation",
  STATUS_PROFESSIONAL = "Application for a change of client category",
  FUNDS_WITHDRAWAL_FORM = "Instruction for money transfer",
}

export enum checkSentStatusTypes {
  bsCheck = "bsCheck",
  bs = "bs",
  du = "du",
  duCheck = "duCheck",
}

export enum ChatChannels {
  prodChannel = "channel_2c3a7b5",
  testChannel = "channel_2812bb0",
}

export enum links {
  operations = "/operations",
  operationsAssets = "/operations/assets",
  operationsHistory = "/operations/history",
  orderStatus = "/operations/order_status",
  orderReport = "/operations/order-report",
  operationsRefillBrokerageAccount = "/operations/replenishment/brokerage_account",
  operationsPaymentProofDocument = "/operations/replenishment/payment_proof_document",
  operationsRefillUsStockMarket = "/operations/replenishment/usStockMarket",
  withdrawOperationsForm = "/operations/withdraw/form",
  withdrawDocumentsOperations = "/operations/withdraw/documents",
  operationsAssetManagement = "/operations/asset_management",
  withdrawAssetOperationsForm = "/operations/withdraw_asset",
  withdrawAssetDocumentsOperations = "/operations/withdraw_asset/documents",
  operationsAssetManagementHistory = "/operations/asset_management/history",
  operationsAssetManagementReports = "/operations/asset_management/reports",
  operationsWithdrawAssetCancel = "/operations/withdraw_asset/cancel",
  forYou = "/for_you",
  forYouCommon = "/for_you/common",
  forYouUsStocks = "/for_you/usStockMarket",
  forYouBroker = "/for_you/broker",
  forYouAssetManagement = "/for_you/assetManagement",
  forYouNotes = "/for_you/notes",
  forYouInvestIdeas = "/for_you/investIdeas",
  forYouOrder = "/for_you/order",
  forYouOrderApplicationComplete = "/for_you/order/aplication/complete",
  forYouUsStockMarketMyScreeners = "/for_you/usStockMarket/screeners/my-screeners",
  forYouUsStockMarketScreenersTable = "/for_you/usStockMarket/screeners/table",
  forYouUsStockMarketScreenersSelect = "/for_you/usStockMarket/screeners/select",
  forYouOrderApplicationBuyComplete = "/for_you/product_buy/complete",
  openAssetApplication = "/for_you/asset_application",
  forYouNews = "/for_you/news",
  forYouBonds = "/for_you/bonds",
  forYouAboutBonds = "/for_you/about_bonds",
  usStocksMarket = "/for_you/us-stock-market-product",
  productPage = "/for_you/product_show",
  structuredIndividualForm = "/for_you/structured_individual_form",
  assetForm = "/for_you/asset_form",
  shortAsset = "/for_you/short_asset",
  forYouFavorites = "/for_you/favorites",
  forYouNonfinancialServices = "/for_you/nonfinancial_services",
  profile = "/profile",
  changePassword = "/change-password",
  changeSecurityQuestion = "/change-security-question",
  services = "/services",
  servicesAll = "/services/all",
  servicesDeposit = "/services/deposits",
  servicesWithdraw = "/services/withdraw",
  servicesReports = "/services/report",
  servicesDocuments = "/services/docs",
  sendMessage = "/send-message",
  blocked = "/blocked",
  login = "/login",
  forgotPassword = "/forgot-password",
  registration = "/registration",
  registrationFirstStep = "/registration/first-step",
  registrationSecondStep = "/registration/second-step",
  registrationSMS = "/registration/sms-step",
  registrationThirdStep = "/registration/third-step",
  registrationSuccess = "/registration/success",
  registrationDoc = "/registration/privacy-notice-doc",
  registrationFailed = "/registration/failed",
  unblocking = "/unblocking",
  documentsForReviewDU2GS = "/documents-for-review-du-2gs",
  documentsForReviewBO2GS = "/documents-for-review-bo-2gs",
  usStocksMarketDocs = "/for_you/us-stock-market-product/docs",
  openAccount = "/open_account",
  openAssetManagement = "/open_asset_management",
  edm = "/edm",
  edmFirstStep = "/edm/first-step",
  edmSecondStep = "/edm/second-step",
  edmThirdStep = "/edm/third-step",
  edmFourthStep = "/edm/fourth-step",
  professionalStatus = "/professional_status",
  professionalStatusOportunitiesSection = "/professional_status/opportunities_section",
  mobileMenu = "/menu",
  advisoryBroker = "/advisory_broker",
  advisoryBrokerChoose = "choose",
  advisoryBrokerPdf = "pdf",
  w8benStatus = "/w8ben/status",
  w8benCitizenship = "/w8ben/сitizenship",
  w8benClientData = "/w8ben/client_data",
  w8benAgreement = "/w8ben/agreement",
  w8benDocumentsSign = "/w8ben/documents_sign",
  market = "/market",
  marginTraging = "/margin_trading",
  marginTradingDocuments = "/margin_trading/documents",
  marginTradingTest = "/margin_trading/test",
  marginTradingTestMistakes = "/margin_trading/test_mistakes",
  marketData = "/marketData",
  marketDataSubscription = "/marketData/subscription",
  marketDataSubscriptionOrder = "/marketData/subscription/order",
  marketDataSubscriptionTransfer = "/marketData/subscription/transfer",
  marketDataSubscriptionDocuments = "/marketData/subscription/documents",
}

export enum routes {
  operations = "/operations/*",
  operationsHistory = "/operations/history",
  operationsAssets = "/operations/assets/*",
  operationsWithdraw = "/withdraw/*",
  operationsAssetWithdraw = "/withdraw_asset/:externalId/:type/*",
  operationsWithdrawForm = "/form",
  operationsWithdrawDocuments = "/documents",
  operationsWithdrawAssetCancel = "/withdraw_asset/cancel",
  operationsOrderStatus = "order_status",
  operationsOrderReport = "order-report",
  operationsRefillBrokerageAccount = "replenishment/brokerage_account",
  operationsPaymentProofDocument = "replenishment/payment_proof_document",
  operationsRefillUsStockMarket = "replenishment/usStockMarket",
  operationsSuccess = "success_section",
  operationsAssetManagement = "asset_management/:strategyId/:truncheId/:bodrAgreementId?",
  operationsAssetManagementHistory = "asset_management/history/:externalId",
  operationsAssetManagementReports = "asset_management/reports/:externalId/:bodrAgreementId?",
  operationsAssetSuccess = "asset_success_section",
  forYou = "/for_you/*",
  forYouBroker = "broker",
  forYouAssetManagement = "assetManagement",
  forYouUsStockMarket = "usStockMarket",
  forYouUsStockMarketScreeners = "usStockMarket/screeners/*",
  myScreeners = "my-screeners",
  select = "select",
  table = "table",
  error = "error",
  forYouNotes = "notes",
  forYouCommonTab = "common",
  forYouInvestIdeas = "investIdeas",
  forYouBonds = "bonds",
  forYouNews = "news",
  forYouShortAssetSuccess = "short_asset_success",
  forYouOrderSuccess = "order/success_section",
  forYouOrder = "order",
  forYouProductBuyComplete = "product_buy/complete",
  forYouOrderAppComplete = "order/aplication/complete",
  forYouFavorites = "favorites",
  forYouNonfinancialServices = "nonfinancial_services",
  profile = "profile",
  servicesAll = "all",
  servicesReport = "report",
  servicesDocs = "docs",
  servicesWithdraw = "withdraw",
  servicesDeposits = "deposits",
  changePassword = "/change-password",
  changeSecurityQuestion = "/change-security-question",
  services = "/services/*",
  sendMessage = "send-message",
  blocked = "blocked",
  login = "/login",
  forgotPassword = "/forgot-password",
  registration = "/registration",
  registrationNew = "/registration/*",
  registrationFirstStep = "/first-step",
  registrationSecondStep = "/second-step",
  registrationSMS = "/sms-step",
  registrationThirdStep = "/third-step",
  registrationSuccess = "/success",
  registrationDoc = "/privacy-notice-doc",
  registrationFailed = "/failed",
  unblocking = "/unblocking",
  notAvailable = "/not_available",
  invalidBrowser = "/invalid_browser",
  documentsForReviewDU2GS = "documents-for-review-du-2gs",
  documentsForReviewBO2GS = "documents-for-review-bo-2gs",
  usStocksMarket = "us-stock-market-product",
  usStocksMarketDocs = "us-stock-market-product/docs",
  mainPage = "/",
  openAccount = "/open_account/*",
  openAssetManagement = "/open_asset_management/*",
  commonSection = "common_section",
  economySection = "economy_section",
  incomeSourcesSection = "source_of_income_section",
  investSection = "invest_section",
  strategyTypeSection = "strategy_type_section",
  strategySection = "strategy_section",
  bankSection = "bank_section",
  extraDocsSection = "extra_documents_section",
  sowDocsSection = "sow_documents_section",
  structuredIndividualForm = "structured_individual_form",
  assetForm = "asset_form/:id/:type",
  aboutBonds = "about_bonds",
  bondsProductOperationType = "product_show/:productId/:productType/:operationType",
  bondsProductOperationTypePortfolio = "product_show/:productId/:productType/:operationType/portfolio",
  mobileMenu = "/menu",
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  documentsSignSection = "documents_sign_section",
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  successSection = "success_section",
  edm = "edm/*",
  firstStep = "first-step",
  secondStep = "second-step",
  thirdStep = "third-step",
  fourthStep = "fourth-step",
  productPage = "product_show/:productId/:productType",
  productPagePortfolio = "product_show/:productId/:productType/portfolio",
  notesPortfolioProductPage = "product_show/:productType",
  chat = "chat",
  professionalStatus = "/professional_status/*",
  opportunitiesSection = "/opportunities_section",
  questionnaireSection = "/questionnaire_section",
  documentsSection = "/documents_section",
  documentsForReviewSection = "/documents_for_review_section",
  applicationStatusSection = "/application_status_section",
  detailSP = "/operations/structured_products/",
  operationsCardSP = "structured_products/",
  newsId = "news/:id",
  shortAsset = "short_asset/:id",
  advisoryBroker = "/advisory_broker/:id",
  advisoryBrokerChoose = "/advisory_broker/:id/choose",
  advisoryBrokerPdf = "/advisory_broker/:id/pdf",
  personalDetails = "/personal_details",
  identityVerification = "/identity_verification",
  w8ben = "/w8ben/*",
  w8benStatus = "/status",
  w8benCitizenship = "/сitizenship",
  w8benClientData = "/client_data",
  w8benAgreement = "/agreement",
  w8benDocumentsSign = "/documents_sign",
  marginTraging = "/margin_trading/*",
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  marginTradingDocuments = "/documents",
  marginTradingTest = "/test",
  marginTradingTestMistakes = "/test_mistakes",
  marketData = "/marketData/*",
  marketDataSubscription = "subscription/*",
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  marketDataSubscriptionOrder = "order",
  marketDataSubscriptionTransfer = "transfer",
  marketDataSubscriptionDocuments = "documents",
}

export enum externalRoutes {
  appleMarket = "https://apps.apple.com/ru/app/the-ultima-world/id6444885457",
  android = "https://play.google.com/store/apps/details?id=ru.bcs.ucf.android",
  faceBook = "https://www.facebook.com/TheUltimaWorld",
  instagram = "https://www.instagram.com/theultimaworld",
  terms = "https://theultima.com/terms/",
}

export const stepPathsOpenAccount = [
  "/open_account/common_section",
  "/open_account/economy_section",
  "/open_account/invest_section",
  "/open_account/agreement",
  "/open_account/extra_documents_section",
  "/open_account/documents_sign_section",
];

export const stepPathsOpenAccountSow = [
  "/open_account/common_section",
  "/open_account/economy_section",
  "/open_account/invest_section",
  "/open_account/agreement",
  "/open_account/sow_documents_section",
  "/open_account/documents_sign_section",
];

export const stepPathsOpenAsset = [
  "/open_asset_management/strategy_section",
  "/open_asset_management/bank_section",
  "/open_asset_management/extra_documents_section",
  "/open_asset_management/documents_sign_section",
];

export enum OpenAccountProcesses {
  DOCS_SECTION = "/open_account/docs",
  COMMON_SECTION = "/open_account/common_section",
  ECONOMY_SECTION = "/open_account/economy_section",
  SOURCE_OF_INCOME_SECTION = "/open_account/source_of_income_section",
  INVEST_SECTION = "/open_account/invest_section",
  BANK_SECTION = "/open_account/bank_section",
  UPLOAD_OFFLINE_DOCUMENTS = "/open_account/extra_documents_section",
  DOCUMENTS_SIGN_SECTION = "/open_account/documents_sign_section",
  SOW_DOCUMENT_SECTION = "/open_account/sow_documents_section",
  EXTRA_DATA_CONFIRMATION_W8 = "/open_account/agreement",
}

export enum OpenAssetManagementProcesses {
  DOCS_SECTION = "/open_asset_management/docs",
  COMMON_SECTION = "/open_asset_management/common_section",
  ECONOMY_SECTION = "/open_asset_management/economy_section",
  SOURCE_OF_INCOME_SECTION = "/open_asset_management/source_of_income_section",
  INVEST_SECTION = "/open_asset_management/invest_section",
  BANK_SECTION = "/open_asset_management/bank_section",
  EXTRA_DOCUMENT_SECTION = "/open_asset_management/extra_documents_section",
  STRATEGY_TYPE_CHOICE_SECTION = "/open_asset_management/strategy_type_section",
  STRATEGY_SECTION = "/open_asset_management/strategy_section",
  DOCUMENTS_SIGN_SECTION = "/open_asset_management/documents_sign_section",
}

export enum orderReportProcessResultEnum {
  INCORRECT_PERIOD = "INCORRECT_PERIOD",
  UNSUPPORTED_ERROR = "UNSUPPORTED_ERROR",
  ERROR_CREATING_REQUEST = "ERROR_CREATING_REQUEST",
  ERROR_SENDING_TO_BODR = "ERROR_SENDING_TO_BODR",
  SUCCESS = "SUCCESS",
}

export enum addressesTypes {
  PLACE_BIRTH_ADDRESS = "PLACE_BIRTH_ADDRESS",
  REGISTRATION_ADDRESS = "REGISTRATION_ADDRESS",
  ACTUAL_ADDRESS = "ACTUAL_ADDRESS",
  MAILING_ADDRESS = "MAILING_ADDRESS",
}

export enum identityDocuments {
  INTERNATIONAL_PASSPORT = "INTERNATIONAL_PASSPORT",
  INTERNAL_PASSPORT = "INTERNAL_PASSPORT",
  FOREIGN_PASSPORT = "FOREIGN_PASSPORT",
}

export enum serviceTypesEnum {
  ASSET_MANAGEMENT = "ASSET_MANAGEMENT",
  BROKER_SERVICE = "BROKER_SERVICE",
}

export const expectedVolumes = [
  {
    label: t("OPEN_ACCOUNT.economySection.selectVariant", { money: "< 5000" }),
    value: "FIVE_THOUSAND_DOLLARS",
  },
  {
    label: t("OPEN_ACCOUNT.economySection.selectVariant", { money: "5 000 - 20 000" }),
    value: "TWENTY_THOUSAND_DOLLARS",
  },
  {
    label: t("OPEN_ACCOUNT.economySection.selectVariant", { money: "20 000 - 50 000" }),
    value: "FIFTY_THOUSANDS_DOLLARS",
  },
  {
    label: t("OPEN_ACCOUNT.economySection.selectVariant", { money: "50 000 - 200 000" }),
    value: "TWO_HUNDRED_THOUSAND_DOLLARS",
  },
  {
    label: t("OPEN_ACCOUNT.economySection.selectVariant", { money: "200 000 - 500 000" }),
    value: "FIVE_HUNDRED_THOUSAND_DOLLARS",
  },
  {
    label: t("OPEN_ACCOUNT.economySection.selectVariant", { money: "500 000 - 1 000 000" }),
    value: "MILLION_DOLLARS",
  },
  {
    label: t("OPEN_ACCOUNT.economySection.selectVariant", { money: "> 1 000 000" }),
    value: "OVER_A_MILLION_DOLLARS",
  },
];

export const reasonsNotOpeningProcess = ["AGREEMENT_ALREADY_EXISTS", "REQUEST_ALREADY_SENT", "REQUEST_IS_OLD"];

export enum contactsTypes {
  EMAIL = "EMAIL",
  PHONE = "PHONE",
}

export enum processResults {
  FORBIDDEN = "FORBIDDEN",
  NOT_FOUND = "NOT_FOUND",
  CANNOT_SEND_OTP_CODE = "CANNOT_SEND_OTP_CODE",
  WAITING_BODR_DESICION = "WAITING_BODR_DESICION",
  UNSUPPORTED_ERROR = "UNSUPPORTED_ERROR",
  VALIDATION_ERROR = "VALIDATION_ERROR",
  GO_TO_SUPPORT = "GO_TO_SUPPORT",
  FORBIDDEN_DUE_TO_AGE = "FORBIDDEN_DUE_TO_AGE",
  W8_NOT_ACCEPT = "W8_NOT_ACCEPT",
  PRINT_FORM_ERROR = "PRINT_FORM_ERROR",
  INVALID_OTP_FIRST_BLOCK = "INVALID_OTP_FIRST_BLOCK",
  INVALID_OTP_SECOND_BLOCK = "INVALID_OTP_SECOND_BLOCK",
  USER_PERMANENTLY_BLOCKED = "USER_PERMANENTLY_BLOCKED",
  ERROR_SEND_TO_BODR = "ERROR_SEND_TO_BODR",
  SAVE_ERROR = "SAVE_ERROR",
  ADDRESS_IN_SANCTION_LIST = "ADDRESS_IN_SANCTION_LIST",
  ADDRESSES_NOT_MATCH = "ADDRESSES_NOT_MATCH",
  INCORRECT_SUMSUB_DATA = "INCORRECT_SUMSUB_DATA",
  PASSPORT_IN_SANCTION_LIST = "PASSPORT_IN_SANCTION_LIST",
  EXISTS_BY_IDENTIFICATION_DOCUMENT_FULLNUMBER_AND_CITIZENSHIP = "EXISTS_BY_IDENTIFICATION_DOCUMENT_FULLNUMBER_AND_CITIZENSHIP",
  CITIZENSHIP_DISMATCH = "CITIZENSHIP_DISMATCH",
  RESET_SUMSUB = "RESET_SUMSUB",
  EXISTS_BY_FULLNAME_AND_BIRTH_DATE = "EXISTS_BY_FULLNAME_AND_BIRTH_DATE",
  FULLNAME_NOT_MATCH = "FULLNAME_NOT_MATCH",
  ERROR_SENDING_ACCOUNT_REQUEST = "ERROR_SENDING_ACCOUNT_REQUEST",
}

export enum processStatus {
  DONE = "DONE",
  IN_PROGRESS = "IN_PROGRESS",
}

export const fileTypes = ["pdf, png, gif, jpg, jpeg"];

export const mimeType = {
  png: "image/png",
  jpeg: "image/jpeg",
  gif: "image/gif",
  pdf: "application/pdf",
  doc: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  excel: "application/vnd.ms-excel",
  msword: "application/msword",
};

export const VALID_FILE_TYPES = [mimeType.png, mimeType.jpeg, mimeType.gif, mimeType.pdf];

export enum DocumentsForReviewFlags {
  BO = "BO",
  DU = "DU",
  BO2GS = "BO2GS",
  DU2GS = "DU2GS",
}

export enum colorsLogo {
  white = "white",
  dark = "dark",
}

export enum filtres {
  data = "data",
  actual = "actual",
  all = "all",
  deposits = "deposits",
  withdraw = "withdraw",
  report = "report",
  docs = "docs",
}

export enum EventKey {
  enter = "Enter",
}

export enum checkSentStatuses {
  bs = "bs",
  bsCheck = "bsCheck",
  du = "du",
  duCheck = "duCheck",
}

export const docTypes = {
  CHP: "CHP",
  COIP: "COIP",
  FEE_SCHEDULE: "FEE_SCHEDULE",
  GENERAL_TERMS: "GENERAL_TERMS",
  RISK_DISCLOSURE: "RISK_DISCLOSURE",
  SCHEDULE_CUSTODY: "SCHEDULE_CUSTODY",
  SCHEDULE_DERIVATIVES: "SCHEDULE_DERIVATIVES",
  AM_RULES: "AM_RULES",
  AM_SCHEDULE_CUSTODY: "AM_SCHEDULE_CUSTODY",
  ASSET_MANAGEMENT_ARCHIVE: "ASSET_MANAGEMENT_ARCHIVE",
  BROKER_SERVICE_ARCHIVE: "BROKER_SERVICE_ARCHIVE",
};

export enum sortDocTypeEnum {
  dataUp = "dataUp",
  dataDown = "dataDown",
  actualUp = "actualUp",
  actualDown = "actualDown",
}

export const activityTypes = {
  EMPLOYEE: "EMPLOYEE",
  SELF_EMPLOYED: "SELF_EMPLOYED",
  INDIVIDUAL_ENTREPRENEUR: "INDIVIDUAL_ENTREPRENEUR",
  LEGAL_ENTITY: "LEGAL_ENTITY",
  UNEMPLOYED: "UNEMPLOYED",
  RENTIER: "RENTIER",
  STUDENT: "STUDENT",
  PENSIONER: "PENSIONER",
  OTHER: "OTHER",
};

export const activityTypesArray = [
  "EMPLOYEE",
  "SELF_EMPLOYED",
  "LEGAL_ENTITY",
  "INDIVIDUAL_ENTREPRENEUR",
  "UNEMPLOYED",
  "RENTIER",
  "STUDENT",
  "PENSIONER",
  "OTHER",
];

export const taskDefinitionKeys = {
  REVIEWED_DOCS_ACCEPT: "REVIEWED_DOCS_ACCEPT",
  POLLITICALY_EXPOSED_PERSON_INPUT: "POLLITICALY_EXPOSED_PERSON_INPUT",
  OFFLINE_CLIENT_INPUT: "OFFLINE_CLIENT_INPUT",
  ONLINE_CLIENT_INPUT: "ONLINE_CLIENT_INPUT",
  ECONOMIC_PROFILE_INPUT: "ECONOMIC_PROFILE_INPUT",
  SOURCE_FUNDS_UPLOAD: "SOURCE_FUNDS_UPLOAD",
  INVEST_PROFILE_INPUT: "INVEST_PROFILE_INPUT",
  BANK_ACCOUNT_INPUT: "BANK_ACCOUNT_INPUT",
  UPLOAD_EXTRA_DOCUMENT: "UPLOAD_EXTRA_DOCUMENT",
  UPLOAD_OFFLINE_DOCUMENTS: "UPLOAD_OFFLINE_DOCUMENTS",
  ACCEPTED_PRINT_FORMS: "ACCEPTED_PRINT_FORMS",
  ASSET_MANAGEMENT_STRATEGY_TYPE_CHOICE: "ASSET_MANAGEMENT_STRATEGY_TYPE_CHOICE",
  ASSET_MANAGEMENT_STRATEGY_CHOICE: "ASSET_MANAGEMENT_STRATEGY_CHOICE", //RDU
  ASSET_MANAGEMENT_PROFILE_INPUT: "ASSET_MANAGEMENT_PROFILE_INPUT", //IDU
  OTP_CONFIRMATION: "OTP_CONFIRMATION",
  SENT_TO_AM: "SENT_TO_AM",
  UPLOAD_SOW_DOCUMENTS: "UPLOAD_SOW_DOCUMENTS",
  EXTRA_DATA_CONFIRMATION: "EXTRA_DATA_CONFIRMATION", //W8
};

export const taskDefinitionKeysEDM = {
  NATIONALITY_INPUT: "NATIONALITY_INPUT",
  START_SUMSUB_CHECKING: "START_SUMSUB_CHECKING",
  SUMSUB_CLIENT_IDENTIFICATION: "SUMSUB_CLIENT_IDENTIFICATION",
  VALIDATED_DATA_CONFIRMATION: "VALIDATED_DATA_CONFIRMATION",
  ACCEPTED_PRINT_FORMS: "ACCEPTED_PRINT_FORMS",
  OTP_CONFIRMATION: "OTP_CONFIRMATION",
};

export enum RequiredDocs {
  PASSPORT_RF = "PASSPORT_RF",
  REGISTRATION_DOC = "REGISTRATION",
  FOREIGN_PASSPORT = "FOREIGN_PASSPORT",
  INTERNATIONAL_PASSPORT = "INTERNATIONAL_PASSPORT",
  INTERNAL_PASSPORT = "INTERNAL_PASSPORT",
  UTILITY_BILL = "UTILITY_BILL",
  ADDITIONAL = "ADDITIONAL",
  SOURCE_OF_FUNDS = "SOURCE_OF_FUNDS",
  BANK_STATEMENT = "BANK_STATEMENT",
  TAX_BILL = "TAX_BILL",
  PASSPORT = "PASSPORT",
  SOURCE_OF_WEALTH = "SOURCE_OF_WEALTH",
  OTHER = "OTHER",
}

export const DocTypesSelect = [
  {
    label: "EDM.second-step.internal-passport-section.internal-passport",
    value: "INTERNAL_PASSPORT",
  },
  {
    label: "EDM.second-step.foreign-passport-section.foreign-passport",
    value: "INTERNATIONAL_PASSPORT",
  },
  {
    label: "OPEN_ACCOUNT.documentsSection.registrationDocTooltip.secondP1",
    value: "UTILITY_BILL",
  },
  {
    label: "OPEN_ACCOUNT.documentsSection.registrationDocTooltip.secondP2",
    value: "BANK_STATEMENT",
  },
  {
    label: "OPEN_ACCOUNT.documentsSection.registrationDocTooltip.secondP3",
    value: "TAX_BILL",
  },
  {
    label: "WIZARD.STATE-1.STEP-2.labels.internalPassportRF",
    value: "PASSPORT",
  },
  {
    label: "OPEN_ACCOUNT.economySection.professionalActivities.other",
    value: "OTHER",
  },
];

export enum addressValidation {
  ONLINE = "ONLINE",
  ACTUAL_ADDRESS = "ACTUAL_ADDRESS",
  MAILING_ADDRESS = "MAILING_ADDRESS",
}

export const brokerDocsIssued = [
  "CHP",
  "COIP",
  "FEE_SCHEDULE",
  "GENERAL_TERMS",
  "RISK_DISCLOSURE",
  "SCHEDULE_CUSTODY",
  "SCHEDULE_DERIVATIVES",
];

export const brokerDocsIssuedSPPLC = ["RISK_WARNING", "DAOC_ISDA", "DAOC"];

export enum usStocksStatus {
  NO_APPLICATION = "NO_APPLICATION",
  NEW = "NEW",
  SUBSCRIBED = "SUBSCRIBED",
}

export const assetDocsIssued = ["AM_RULES", "AM_RISK_DISCLOSURE", "AM_SCHEDULE_CUSTODY", "COIP", "CHP"];

export enum productCategory {
  STOCK = "STOCK",
}

export enum dmaState {
  IN_PROGRESS = "IN_PROGRESS",
  WAITING_BODR_DESICION = "WAITING_BODR_DESICION",
  ERROR_SENDING_BODR_REQUEST = "ERROR_SENDING_BODR_REQUEST",
  DONE = "DONE",
}

export enum forYouTabs {
  broker = routes.forYouBroker,
  assetManagement = routes.forYouAssetManagement,
  usStockMarket = routes.forYouUsStockMarket,
  notes = routes.forYouNotes,
  commonTab = routes.forYouCommonTab,
  news = routes.forYouNews,
  investIdeas = routes.forYouInvestIdeas,
  bonds = routes.forYouBonds,
  favorites = routes.forYouFavorites,
  nonfinancialServices = routes.forYouNonfinancialServices,
}

export enum OperationsTabsEnum {
  assets = "assets",
  history = "history",
}

export enum dmaBrokerStatus {
  BROKERAGE_SERVICES_FORMALIZATION_COMPLETED = "BROKERAGE_SERVICES_FORMALIZATION_COMPLETED",
  TRUST_MANAGEMENT_FORMALIZATION_AND_BROKERAGE_SERVICES = "TRUST_MANAGEMENT_FORMALIZATION_AND_BROKERAGE_SERVICES",
}

export const clientStatusWithBoAndDu = [
  dmaBrokerStatus.BROKERAGE_SERVICES_FORMALIZATION_COMPLETED,
  dmaBrokerStatus.TRUST_MANAGEMENT_FORMALIZATION_AND_BROKERAGE_SERVICES,
];

export enum orderStatusesEnum {
  SENT = "SENT",
  ACTIVATED = "ACTIVATED",
  REJECTED = "REJECTED",
  PENDING = "PENDING",
  CLOSED = "CLOSED",
  BLOCKED = "BLOCKED",
  ACCEPTED = "ACCEPTED",
  PROCESSING = "PROCESSING",
  EXECUTED = "EXECUTED",
  PENDING_APPROVAL_DOCUMENT = "PENDING_APPROVAL_DOCUMENT",
  PENDING_ADDITIONAL_INFORMATION = "PENDING_ADDITIONAL_INFORMATION",
  CANCELED = "CANCELED",
}

export enum orderTypesEnum {
  FUNDS_WITHDRAWAL = "FUNDS_WITHDRAWAL",
  TRADE_ORDER = "TRADE_ORDER",
  ACCOUNT_REQUEST = "ACCOUNT_REQUEST",
  ASSET_MANAGEMENT_ACCOUNT_REQUEST = "ASSET_MANAGEMENT_ACCOUNT_REQUEST",
  DMA = "DMA",
  REMITTANCE = "REMITTANCE",
  MONEY_TRANSFER = "MONEY_TRANSFER",
  FOA_NOTES = "FOA_NOTES",
  ASSESSED_PROFESSIONAL = "ASSESSED_PROFESSIONAL",
  CONSULTING_BROKER_SERVICE = "CONSULTING_BROKER_SERVICE",
  ASSET_MANAGEMENT = "ASSET_MANAGEMENT",
  BROKER_SERVICE = "BROKER_SERVICE",
  W8_FORM = "W8_FORM",
  FUNDS_DEPOSIT = "FUNDS_DEPOSIT",
  SP_ORDER = "SP_ORDER",
  BOND = "BOND",
  SP_PAYMENT = "SP_PAYMENT",
  PART_WITHDRAWAL_FROM_AM = "PART_WITHDRAWAL_FROM_AM",
  FULL_WITHDRAWAL_FROM_AM = "FULL_WITHDRAWAL_FROM_AM",
  MARGIN_TRADING = "MARGIN_TRADING",
}

export enum strategyTypes {
  RDU = "RDU",
  IDU = "IDU",
}

export const StrategyTypeByEvaId: Record<string, string> = {
  "1100": "Fundamental selection strategy 3 years (presentation)",
  "1101": "Fundamental selection strategy 5 years (presentation)",
  "1102": "Global bonds strategy 3 years (presentation)",
  "1103": "Global equities strategy 3 years (presentation)",
  "1104": "Global Income Funds 3 years (presentation)",
  "1105": "REITs 3 years (presentation)",
};

export enum edmContactsEnums {
  EMAIL = "EMAIL",
  RUS = "RUS",
  EDM_UPDATED_COUNTER = "EDM_UPDATED_COUNTER",
}

export enum edmClientStatuses {
  NEW = "NEW",
  IDENTIFICATION_IN_PROGRESS = "IDENTIFICATION_IN_PROGRESS",
  IDENTIFICATION_COMPLETED = "IDENTIFICATION_COMPLETED",
  IDENTIFICATION_FAILED = "IDENTIFICATION_FAILED",
}

export enum edmCompleteStatuses {
  ELECTRONIC_DOCUMENT_SIGNED_COMPLETED = "ELECTRONIC_DOCUMENT_SIGNED_COMPLETED",
  SUCCESS = "SUCCESS",
  GO_TO_SUPPORT = "GO_TO_SUPPORT",
}

export enum lastKeyProcessNames {
  PROCESS_KEY_USER_REGISTRATION = "userRegistration",
  PROCESS_KEY_USER_IDENTIFICATION = "userIdentification",
  PROCESS_KEY_USER_IDENTIFICATION_V2 = "userIdentificationV2",
  PROCESS_KEY_USER_IDENTIFICATION_V3 = "userIdentificationV3",
  PROCESS_KEY_USER_EDM = "userElectronicalDocumentManagementProcess",
  PROCESS_KEY_ACCOUNT_OPENING = "creatingAccountOpeningRequest",
  PROCESS_KEY_USER_DEACTIVATION = "userDeactivation",
  PROCESS_KEY_USER_UNLOCKING = "userUnlocking",
  PROCESS_KEY_UPDATING_PASSWORD = "updatingPassword",
  PROCESS_KEY_UPDATING_SECURITY_QUESTION = "updatingSecurityQuestion",
  PROCESS_KEY_SET_PERMANENT_PASSWORD = "setPermanentPassword",
  PROCESS_KEY_RESET_PASSWORD = "resetPasswordUser",
  PROCESS_KEY_SET_SECURITY_ANSWER = "setSecurityAnswer",
  PROCESS_KEY_DMA_SERVICE_CONNECTION = "dmaServiceConnection",
}

export const noTaxReasons = [
  {
    russianName:
      "Страна, в которой Владелец счета обязан платить налоги, не присваивает своим резидентам налоговые идентификационные номера (TIN)",
    englishName: "The country where the Account Holder is liable to pay tax does not issue TINs to its residents",
    reason: "TAX_COUNTRY_DOES_NOT_ASSIGN_TIN",
  },
  {
    russianName:
      "Владелец счета по иным причинам не может получить налоговый идентификационный номер (TIN) или эквивалент (если выбран данный ответ, то поясните, по какой причине Вы не можете получить TIN или аналог)",
    englishName:
      "The Account Holder is otherwise unable to obtain a TIN or equivalent number (Please, explain why you are unable to obtain a TIN in the below table if you have selected this reason)",
    reason: "ACCOUNT_OWNER_CANNOT_GET_TIN",
  },
  {
    russianName:
      "Налоговый идентификационный номер (TIN) не требуется. (Примечание: выбрать данную причину только в том случае, если органы страны налогового резидентства не требуют раскрытия TIN)",
    englishName:
      "No TIN is required. (Note: only select this reason if the authorities of the country of tax residence entered below do not require the TIN to be disclosed)",
    reason: "TIN_IS_NOT_NECESSARY",
  },
];

export const edmGenders = [
  {
    ru: "M",
    en: "M",
    value: "MALE",
  },
  {
    ru: "F",
    en: "F",
    value: "FEMALE",
  },
];

export enum emdActiveTasksNames {
  IDENTITY_DOCUMENT_INPUT = "IDENTITY_DOCUMENT_INPUT",
  ADDRESSES_INPUT = "ADDRESSES_INPUT",
  TAX_INFO_INPUT = "TAX_INFO_INPUT",
  START_SUMSUB_CHECKING = "START_SUMSUB_CHECKING",
  SUMSUB_CLIENT_IDENTIFICATION = "SUMSUB_CLIENT_IDENTIFICATION",
}

export enum edmAddressTypes {
  REGISTRATION_ADDRESS = "REGISTRATION_ADDRESS",
  ACTUAL_ADDRESS = "ACTUAL_ADDRESS",
  MAILING_ADDRESS = "MAILING_ADDRESS",
}

export enum modalPdfTypes {
  ASSET = "ASSET",
  BROKERAGE = "BROKERAGE",
}

export enum ShowCaseProductType {
  ASSET_MANAGEMENT = "ASSET_MANAGEMENT",
  DMA = "DMA",
  STRUCTURED_PRODUCT = "STRUCTURED_PRODUCT",
  NOTE = "NOTE",
  NEWS = "NEWS",
  // логика УКФ
  INVEST_IDEAS = "INVEST_IDEA",
  STOCK = "STOCK",
  CBS = "CBS",
  BOND = "BOND",
  NONFINANCIAL_SERVICES = "NONFINANCIAL_SERVICES",
  ANALYTICS = "ANALYTICS",
}

export enum ShowCaseProductTypePermission {
  ASSET_MANAGEMENT = "ASSET_MANAGEMENT",
  DMA = "DMA",
  BROKER_SERVICE = "BROKER_SERVICE",
}

export enum ShowCaseProductComponentType {
  LISTS = "LISTS",
  INFO = "INFO",
  PARAMETERS = "PARAMETERS",
  RISK_PROFILE = "RISK_PROFILE",
  DOCUMENTS = "DOCUMENTS",
  FAQS = "FAQS",
  EXTRA_INFO = "EXTRA_INFO",
  MAIN_POINTS = "MAIN_POINTS",
  GRID = "GRID",
  PRODUCT_CATEGORY = "PRODUCT_CATEGORY",
  PRODUCT_CURRENCY = "PRODUCT_CURRENCY",
  QUALIFICATION = "QUALIFICATION",
  RISK_PROFILE_INCOMPATIBILITY = "RISK_PROFILE_INCOMPATIBILITY",
  ASSETS = "ASSETS",
  PRODUCT_TABS = "PRODUCT_TABS",
  PRODUCT_PLATES = "PRODUCT_PLATES",
  SECTIONED_LIST = "SECTIONED_LIST",
  PRODUCT_SUMMARY = "PRODUCT_SUMMARY",
  PLATES = "PLATES",
  PRODUCT_STORYPLATE = "PRODUCT_STORYPLATE",
  DIRECTION = "DIRECTION",
  FINISHED = "FINISHED",
  VERTICAL = "VERTICAL",
  SCRIPTS = "SCRIPTS",
  PRODUCT_LISTS = "PRODUCT_LISTS",
}

export enum ShowCaseProductLabelType {
  CURRENCY = "CURRENCY",
}

export enum newAssetValues {
  CASH = "CASH",
  SECURITIES = "SECURITIES",
  WITH_AUTOMATIC_CASH_BALANCE_TRANSFER = "WITH_AUTOMATIC_CASH_BALANCE_TRANSFER",
  WITHOUT_AUTOMATIC_CASH_BALANCE_TRANSFER = "WITHOUT_AUTOMATIC_CASH_BALANCE_TRANSFER",
  INSTRUMENT_1 = "INSTRUMENT_1",
  INSTRUMENT_2 = "INSTRUMENT_2",
  INSTRUMENT_3 = "INSTRUMENT_3",
  INSTRUMENT_4 = "INSTRUMENT_4",
  Y1 = "Y1",
  Y2 = "Y2",
  Y3 = "Y3",
  Y4 = "Y4",
  Y5 = "Y5",
  OY5 = "OY5",
}

export enum ClientProfileValues {
  PHONE = "PHONE",
  EMAIL = "EMAIL",
}

export enum DMAModalTypes {
  SHOWCASE_ASSET = "SHOWCASE_ASSET",
  SHOWCASE_ASSET_INDIVIDUAL = "SHOWCASE_ASSET_INDIVIDUAL",
  SHOWCASE_STRUCTURED = "SHOWCASE_STRUCTURED",
  DMA = "DMA",
}

export enum PrimitivesTypes {
  STRING = "string",
  NUMBER = "number",
}

export enum dateTypes {
  RUS_DATE = "DD.MM.YYYY",
  EN_DATE = "YYYY-MM-DD",
}

export enum locales {
  RU = "ru",
  EN = "en",
}

export enum EnumCurrencies {
  USD = "USD",
  RUB = "RUB",
  EUR = "EUR",
}

export const currenciesForSearch = ["USD", "RUB", "EUR"];

export const currenciesForSearchAM = ["USD", "RUB"];

export enum webEnum {
  HTTPS = "https://",
}

export enum processActionsEnum {
  REMOVE = "REMOVE",
  SP_BUYING_PROCESS = "SP_BUYING_PROCESS",
  PROFESSIONAL_REQUIRED = "PROFESSIONAL_REQUIRED",
}

export enum errorsMessages {
  SUPPORT_SERVICE = "Ошибка открытия брокерского счёта, обратитесь в службу поддержки",
  SUPPORT_SERVICE_2 = "Ошибка открытия доверительного управления, обратитесь в службу поддержки",
  SUPPORT_SERVICE_3 = "Возникла ошибка, обратитесь в службу поддержки",
}

export const genders = ["MALE", "FEMALE"];

export enum GenderEnum {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export enum AddressType {
  ACTUAL_ADDRESS = "ACTUAL_ADDRESS",
  REGISTRATION_ADDRESS = "REGISTRATION_ADDRESS",
  MAILING_ADDRESS = "MAILING_ADDRESS",
  PLACE_BIRTH_ADDRESS = "PLACE_BIRTH_ADDRESS",
}

export const strategyType = {
  GLOBAL_INCOME: "Global Income Funds (3 years)",
  FUNDAMENTAL_5: "Fundamental Selection Strategy (5 years)",
  FUNDAMENTAL_3: "Fundamental Selection Strategy (3 years)",
  REITS_3: "REITs 3 years",
};

export const strategyDocTitles = {
  SCHEDULE_B: "Schedule B. 1.1 fundamental selection (3 years) strategy.pdf",
  SCHEDULE_C: "Schedule C. 1.2 fundamental selection (5 years) strategy.pdf",
  SCHEDULE_D: "Schedule D 1.3 GLOBAL_BONDS_STRATEGY.pdf",
  SCHEDULE_E: "Schedule E 1.4 Global equities strategy (3 years).pdf",
  SCHEDULE_F: "Schedule F 1.5 Global Income Funds (3 years).pdf",
  SCHEDULE_G: "Schedule G 1.6 REITs (3 years).pdf",
};

export const GenderType = {
  MALE: "M",
  FEMALE: "F",
};

export const validClientStatuses = [
  "ELECTRONIC_DOCUMENT_SIGNED_COMPLETED",
  "ELECTRONIC_DOCUMENT_SIGNED_OFFLINE",
  "BROKERAGE_SERVICES_FORMALIZATION_COMPLETED",
  "TRUST_MANAGEMENT_FORMALIZATION_COMPLETED",
  "TRUST_MANAGEMENT_FORMALIZATION_AND_BROKERAGE_SERVICES",
  "OFFLINE_IDENTIFICATION_COMPLETED",
];

export const validOfflineClientStatuses = [
  "BROKERAGE_SERVICES_FORMALIZATION_COMPLETED",
  "TRUST_MANAGEMENT_FORMALIZATION_COMPLETED",
  "TRUST_MANAGEMENT_FORMALIZATION_AND_BROKERAGE_SERVICES",
];

export enum sumSubReviewStatus {
  COMPLETED = "completed",
  ON_HOLD = "onHold",
  INIT = "init",
  PENDING = "pending",
  PRECHECKED = "prechecked",
}

export enum sumSubReviewAnswers {
  GREEN = "GREEN",
  RED = "RED",
  NONE = "NONE",
}

export enum reviewRejectType {
  FINAL = "FINAL",
  RETRY = "RETRY",
}

export enum countryEnum {
  RF = "643",
  ARE = "784",
}

export enum emdDocsNames {
  DUBAI_ELECTRONIC_TERMS_V2 = "Acceptance of electronic services terms",
  AGREEMENT_BCS_V2 = "Request for Consent for the disclosure of personal data to BrokerCreditService Ltd",
}

export enum emdTaxIdErrors {
  INVALID_TAX_ID = "Invalid Tax ID. Please check and try again",
  INVALID_TAX_ID_OLD = "Tin number is incorrect",
  UNDEFINED_TAX_ID = "The indicated Tax ID does not exist. Please check and try again",
  DUPLICATED_TAX_ID = "Client can have no more than one TIN for each country",
}

export enum accountTypes {
  AM = "AM",
  BR = "BR",
}

export enum clientChannels {
  EVA = "EVA",
  UCF_WEB = "UCF_WEB",
  UCF_IOS = "UCF_IOS",
  UCF_ANDROID = "UCF_ANDROID",
  UCF_MOBILE = "UCF_MOBILE",
}

export const duTypeOrder = {
  profile: 1,
  "acceptance-of-general-terms_du": 2,
  representation: 3,
};

export enum saltTypes {
  DEV_SALT = "web",
}

export enum iconsEnum {
  PURITY = "🤍",
}

export enum HistoryProcessStatusEnum {
  NEW = "NEW",
  PENDING = "PENDING",
  DECLINED = "DECLINED",
  APPROVED = "APPROVED",
}

export enum NotesBuyingStatuses {
  NUMBER_OF_SECURITY_ATTEMPTS_INPUT = "NUMBER_OF_SECURITY_ATTEMPTS_INPUT",
  ACCEPTED_PRINT_FORMS = "ACCEPTED_PRINT_FORMS",
  WAITING_BODR_DESICION = "WAITING_BODR_DESICION",
}

export enum LocalStorageKeysEnum {
  registrationBusinessKey = "registrationBusinessKey",
  onboardingBusinessKey = "onboardingBusinessKey",
  strategyForAM = "strategyForAM",
}

export enum AdvisoryBrokerTaskEnum {
  tariffChange = "TARIFF_CHANGE",
  otpConfirm = "OTP_CONFIRMATION",
  printFormAccept = "ACCEPTED_PRINT_FORMS",
}

export enum AdvisoryBrokerProcessResultEnum {
  waitingBodrDecision = "WAITING_BODR_DESICION",
}

export enum InvestProfileTypeEnum {
  CONSERVATIVE = "CONSERVATIVE",
  MODERATELY_CONSERVATIVE = "MODERATELY_CONSERVATIVE",
  RATIONAL = "RATIONAL",
  MODERATELY_AGGRESSIVE = "MODERATELY_AGGRESSIVE",
  AGGRESSIVE = "AGGRESSIVE",
  SUPER_AGGRESSIVE = "SUPER_AGGRESSIVE",
  PROFESSIONAL = "PROFESSIONAL",
  UNKNOWN = "UNKNOWN",
}

export const IconsTypes: Record<string, IconNameType> = {
  ICON_STAR: "favorites",
  ICON_PIECHART: "chartPie",
  ICON_TERMINAL: "trade",
  ICON_EXPERIENCE: "education",
  ICON_CHECKMARK: "checkboxCurrent",
  ICON_PORTFOLIO: "portfolioIcon",
  ICON_KNIGHT: "knight",
  ICON_CHARTS: "chartLines",
  ICON_CANDLES: "candles",
  ICON_CALENDAR: "dateCalendarBlack",
  ICON_IN_N_OUT: "reload",
  ICON_DOC: "document",
  ICON_PDF_DOC: "downloadDoc",
  ICON_UMBRELLA: "umbrella",
  ICON_LENTA: "lenta",
  ICON_GLOBAL: "worldIcon",
  ICON_PROFILE: "menuProfile",
  ICON_GLOBAL_INDEX: "globalIndex",
  ICON_STRUCTURED_PRODUCT: "structureProduct24",
  ICON_GRAPH_HISTOGRAMS: "trade",
  ICON_INCOME_WALLET: "replenishment",
  ICON_OUTCOME_WALLET: "withdraw",
  ICON_PERSONAL: "personal",
  ICON_PERCENT: "percent",
  ICON_PLANS: "plans",
  ICON_BULLET_DOT: "dot",
  ICON_BELL: "bell",
  ICON_WALLET: "walletGrey",
  ICON_SHIELD: "shield",
  ICON_COUPON: "coupon",
  ICON_FLAG: "flag",
  ICON_FLASH: "flash",
  ICON_NUMBERS_ROUND_1: "numbersRound1",
  ICON_NUMBERS_ROUND_2: "numbersRound2",
  ICON_NUMBERS_ROUND_3: "numbersRound3",
  ICON_NUMBERS_ROUND_4: "numbersRound4",
  ICON_NUMBERS_ROUND_5: "numbersRound5",
  ICON_NUMBERS_ROUND_6: "numbersRound6",
  ICON_NUMBERS_ROUND_7: "numbersRound7",
  ICON_NUMBERS_ROUND_8: "numbersRound8",
  ICON_NUMBERS_ROUND_9: "numbersRound9",
  ICON_SUM_PRODUCT: "currency",
  ICON_ACCOUNT_INVESTMENT: "accountInvestment",
};

export enum InvestIdeasTagsEnum {
  BUY = "buyTag",
  SELL = "sellTag",
  HOLD = "holdTag",
  COUPLE = "coupleTag",
}

export enum InvestIdeasProfitabilityEnum {
  LOSS = "LOSS",
  PROFIT = "PROFIT",
  NUETRAL = "NUETRAL",
}

export enum CheckStatus {
  ANTIVIRUS_CHECK = "ANTIVIRUS_CHECK",
  CHECK_SUCCESS = "CHECK_SUCCESS",
  ANTIVIRUS_CHECK_ERROR = "ANTIVIRUS_CHECK_ERROR",
  FILE_TYPE_ERROR = "FILE_TYPE_ERROR",
  FILE_SIZE_ERROR = "FILE_SIZE_ERROR",
  FILE_NAME_ERROR = "FILE_NAME_ERROR",
  FILE_TYPE_INVALID = "FILE_TYPE_INVALID",
  TEMP_STORAGE_ERROR = "TEMP_STORAGE_ERROR",
}

export enum TextColor {
  DARK = "DARK",
  LIGHT = "LIGHT",
}

export enum ChapterBanners {
  PORTFOLIO = "PORTFOLIO",
  MARKET = "MARKET",
  ASSET_MANAGEMENT = "ASSET_MANAGEMENT",
  STRUCTURED_PRODUCT = "STRUCTURED_PRODUCT",
  NOTE = "NOTE",
  INVEST_IDEA = "INVEST_IDEA",
  BOND = "BOND",
  NEWS = "NEWS",
}

export enum EducationBOEnum {
  LACK_OF_EDUCATION = "LACK_OF_EDUCATION",
  SECONDARY = "SECONDARY",
  HIGHER = "HIGHER",
  HIGHER_FINANCIAL = "HIGHER_FINANCIAL",
  CERTIFICATE = "CERTIFICATE",
  INVESTMENT_EXPERIENCE = "INVESTMENT_EXPERIENCE",
}

export enum FinInstrBOEnum {
  STOCKS_BONDS_POOL_INVESTMENTS = "STOCKS_BONDS_POOL_INVESTMENTS",
  DERIVATIVES = "DERIVATIVES",
  COMPLICATED_BONDS = "COMPLICATED_BONDS",
  OTC_INSTRUMENTS = "OTC_INSTRUMENTS",
  STRUCTURED_PRODUCTS = "STRUCTURED_PRODUCTS",
  MARGIN_TRADING = "MARGIN_TRADING",
  LACK_OF_EXPERIENCE = "LACK_OF_EXPERIENCE",
}

export enum InvestmentObjectiveBOEnum {
  INFLATION_PROTECTION = "INFLATION_PROTECTION",
  FUTURE_PENSION_SURPLUS = "FUTURE_PENSION_SURPLUS",
  PURPOSE_SAVING = "PURPOSE_SAVING",
  EXTRA_INCOME = "EXTRA_INCOME",
  BASIS_INCOME = "BASIS_INCOME",
}

export enum InvestPeriodBOEnum {
  LESS_THAN_5_YEARS = "LESS_THAN_5_YEARS",
  FROM_5_TO_10_YEARS = "FROM_5_TO_10_YEARS",
  MORE_THAN_10_YEARS = "MORE_THAN_10_YEARS",
}

export enum RatioBOEnum {
  INCOME_MUCH_GREATER_EXPENSES = "INCOME_MUCH_GREATER_EXPENSES",
  INCOME_GREATER_EXPENSES = "INCOME_GREATER_EXPENSES",
  INCOME_EQUAL_EXPENSES = "INCOME_EQUAL_EXPENSES",
  EXPENSES_GREATER_INCOME = "EXPENSES_GREATER_INCOME",
}

export enum AffordToLoseBOEnum {
  SMALL_SHORT_TERM_INVEST_LOSS = "SMALL_SHORT_TERM_INVEST_LOSS",
  MODERATE_MID_TERM_INVEST_LOSS = "MODERATE_MID_TERM_INVEST_LOSS",
  SIGNIFICANT_INVEST_LOSS = "SIGNIFICANT_INVEST_LOSS",
}

export enum StatusW8Enum {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  EXPIRE = "EXPIRE",
  NOT_ISSUED = "NOT_ISSUED",
}

export const STATUSES_W8_CAN_NOT_START_PROCESS = [StatusW8Enum.ACCEPTED, StatusW8Enum.APPROVED, StatusW8Enum.PENDING];

export enum modalTypes {
  any = "any",
  opertions = "opertions",
  success = "success",
  mistake = "mistake",
}

export const notForRedirectPath = [links.blocked, routes.mainPage];

export const edmAvailablePath = [
  links.edm,
  links.edmFirstStep,
  links.edmSecondStep,
  links.edmThirdStep,
  links.edmFourthStep,
  links.profile,
  links.mobileMenu,
  links.sendMessage,
  links.changePassword,
  links.changeSecurityQuestion,
];

export enum locationGroupEnum {
  CLIENT_CASH_USD = "CLIENT_CASH_USD",
  DMA_US_MARKET = "DMA_US_MARKET",
  CLIENT_CASH_EUR = "CLIENT_CASH_EUR",
  CLIENT_CASH = "CLIENT_CASH",
}

export enum accStatuses {
  OPEN = "OPEN",
  NEW = "NEW",
  CLOSE = "CLOSE",
  BLOCKED = "BLOCKED",
  EXPIRED = "EXPIRED",
}

export enum accTypesFull {
  BROKERAGE = "brokerage",
  BROKERAGE_AND_ASSET = "brokerage + asset management",
  ASSET_MANAGEMENT = "asset management",
}

export enum OperatorCondEnum {
  OR = "||",
  NULLISH = "??",
}

export const PORTFOLIO_PATH = "portfolio";

export const CODE_ISO_DUBAI = "784";

export enum PasswordRules {
  PASSWORD_SHORT = "password short",
  PASSWORD_LONG = "password long",
  PASSWORD_IS_VALID = "password is valid",
}

export enum WidgetTypeEnum {
  BUY = "BUY",
  SELL = "SELL",
  MAIN = "MAIN",
  CHANGE = "CHANGE",
}
