import { createReducer } from "~/lib/redux";

import { appErrorAction, appErrorClearAction } from "../actions";

export const STATE_NAME = "error";

export interface AppErrorState {
  error?: Error;
  title?: string;
  message?: string;
}

const defaultState: any = {
  error: undefined,
};

const reducer = createReducer({}, defaultState);

reducer.on(appErrorAction, (state, { error, title, message }): any => ({
  ...state,
  error,
  title,
  message,
}));

reducer.on(appErrorClearAction, (state): any => ({
  ...state,
  error: undefined,
  title: undefined,
  message: undefined,
}));

export default reducer;
