import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";

import tokenService from "~/lib/token";

import { actions as authActions } from "~/modules/auth";

import reducers from "./reducers";
import sagas from "./sagas";

let composeEnhancers = compose;
let initialState = {};

if (import.meta.env.MODE === "development") {
  if (import.meta.env.VITE_REDUX_DEVTOOLS_ENABLED === "true" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  }
}

if (
  import.meta.env.VITE_HOT_LOADER_ENABLED === "true" &&
  import.meta.hot &&
  typeof window !== "undefined" &&
  window.hotInitialState
) {
  initialState = window.hotInitialState;
  delete window.hotInitialState;
}

const sagaMiddleware = createSagaMiddleware();

const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(sagaMiddleware)));

let saga = sagaMiddleware.run(function* (): IterableIterator<unknown> {
  yield sagas();
});

if (import.meta.env.VITE_HOT_LOADER_ENABLED === "true" && import.meta.hot) {
  import.meta.hot.dispose((): void => {
    if (typeof window !== "undefined") {
      window.hotInitialState = store.getState();
    }
  });

  import.meta.hot.accept("./reducers", (): void => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    store.replaceReducer(require("./reducers").default);
  });

  import.meta.hot.accept("./sagas", (): void => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const nextSagas = require("./sagas").default;
    saga.cancel();
    saga.toPromise().then((): void => {
      saga = sagaMiddleware.run(function* (): IterableIterator<unknown> {
        yield nextSagas();
      });
    });
  });
}

tokenService.onStorage((): void => {
  store.dispatch(authActions.authSyncTokenStateAction());
});

export default store;
