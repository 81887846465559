import React, { ReactElement, useMemo } from "react";
import { Link } from "react-router-dom";

import { checkSentStatusTypes, links } from "~/constants/enums";
import { useTranslation } from "~/i18n";
import { ModalButtonGroup, ModalDescriptionGroup, ModalTitleGroup } from "~/shared/ui/Modal";
import colorTable from "~/shared/ui-kit/constants/colors";
import { DownloadDocW8Btn } from "../helper";

interface TSuccessModalContent {
  checkSentStatus: string;
  btnHandler: () => void;
}

export const SuccessModalContent = ({ checkSentStatus, btnHandler }: TSuccessModalContent) => {
  const { t } = useTranslation();

  const modalTextArr = useMemo((): (string | ReactElement)[] => {
    let arrDescr = [
      <>
        {t("MODAL_SUCCESS.desc_2")}{" "}
        <Link to={links.orderStatus}>
          {t("MODAL_SUCCESS.history")} {t("MODAL_SUCCESS.history_2")}
        </Link>
      </>,
    ];
    if (checkSentStatus === checkSentStatusTypes.du) {
      arrDescr.push(t("MODAL_SUCCESS.w8_ben"), t("MODAL_SUCCESS.mail_prefix"));
    }
    return arrDescr;
  }, []);

  const modalTitle =
    checkSentStatus === checkSentStatusTypes.bs ? t("MODAL_SUCCESS.bs_title") : t("MODAL_SUCCESS.du_title");

  return (
    <>
      <ModalTitleGroup
        title={modalTitle}
        iconName="checkboxCurrent"
        iconColor={colorTable.green_50}
        withBackground={{
          backgroundColor: colorTable.lightGreen,
          borderRadius: "16px",
          padding: "14px",
        }}
      />
      <ModalDescriptionGroup arrDescr={modalTextArr} />
      <ModalButtonGroup
        buttons={[
          {
            typeStyle: "black",
            buttonText: t("OK"),
            buttonHandler: btnHandler,
          },
        ]}
        docBtn={checkSentStatus === checkSentStatusTypes.du ? <DownloadDocW8Btn /> : undefined}
      />
    </>
  );
};
