import { cast, Yup } from "~/lib/validation";

export interface KeycloakDecodedTokenModel {
  d_login: string;
  is_temporary_password?: boolean;
  security_question?: boolean;
}

const schema = Yup.object<KeycloakDecodedTokenModel>({
  d_login: Yup.string().required(),
  is_temporary_password: Yup.boolean(),
  security_question: Yup.boolean(),
}).label("KeycloakDecodedTokenModel");

export const castKeycloakDecodedTokenModel = cast<KeycloakDecodedTokenModel>(schema);
