import React, { useMemo } from "react";
import { nanoid } from "nanoid";

import { Trans, useTranslation } from "~/i18n";
import { ModalButtonGroup, ModalDescriptionGroup, ModalTitleGroup } from "~/shared/ui/Modal";
import { BaseTypography } from "~/shared/ui-kit/BaseComponents";
import colorTable from "~/shared/ui-kit/constants/colors";
import { IconNameType } from "~/shared/Icon";
import { useStyles } from "../styles";
import { CLIENT_EMAIL } from "~/constants/common.ts";

interface TMistakeContentProps {
  btnHandler: () => void;
  errorCode?: string;
}

export const MistakeContent = ({ btnHandler, errorCode }: TMistakeContentProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const arrDescr = useMemo(() => {
    const array = [
      <Trans
        i18nKey="W8.agreement.mistake.modal_desc"
        components={[<a key="0" href={`mailto:${CLIENT_EMAIL}`}></a>, <a key="0" href="tel:+971-443-96756"></a>]}
        key={nanoid(5)}
        className={classes.alignedText}
      />,
    ];

    if (errorCode) {
      array.push(
        <BaseTypography fontType="text" tag="span" className={classes.errorCode}>
          {t("ERRORS.error_code") + " "}
          <span className={classes.code}>{errorCode}</span>
        </BaseTypography>
      );
    }
    return array;
  }, [errorCode]);

  return (
    <>
      <ModalTitleGroup
        title={t("W8.agreement.mistake.modal_title")}
        iconName={"chat" as IconNameType}
        withBackground={{ backgroundColor: colorTable.lightGrey }}
      />
      <ModalDescriptionGroup arrDescr={arrDescr} />
      <ModalButtonGroup
        buttons={[
          {
            typeStyle: "black",
            buttonText: t("W8.agreement.mistake.ok"),
            buttonHandler: btnHandler,
          },
        ]}
      />
    </>
  );
};
