import { AxiosError } from "axios";
import { Effect, Event, Store } from "effector";
import { Form } from "effector-forms";
import { Gate } from "effector-react";

import { ShowCaseProductType, ShowCaseProductTypePermission } from "~/constants/enums";
import { ProcessStateRequest } from "~/entities/keycloak";
import { TStoryCard, TStorySlide } from "~/entities/stories/types";
import { TPagination } from "~/shared/types";
import { InvestListItem } from "~/shared/types/investWidget";

export type ProductsHeaderPlates = {
  title: string;
  text: string;
  subtitle: string;
  productId: string;
  productCategory: string;
  tags: Array<{
    name: string;
    value: string;
  }>;
  label: Array<{
    type: string;
    value: string;
  }>;
  description: Array<string>;
};
export type ShowcaseAssetTabHeaderResponse = {
  plate: ProductsHeaderPlates[];
  title: string;
};

export type ShowcaseAssetTabHeader = {
  title: string;
  subTitle?: string;
  plates: AssetHeaderPlateType[];
};

export type AssetHeaderPlateType = {
  riskLevel?: number;
  currentPartText?: number;
  commontPartText?: number;
  commonTitle?: string;
  slideTitle?: string;
  slideText?: string[];
  percentProduct?: string;
  percentText?: string;
  productId?: string;
  tags?: string[] | any;
  type?: ShowCaseProductType | "";
  isProfessional?: boolean;
  individual?: boolean;
  currency?: string;
  currencySymbol?: string;
  subtitle?: string;
  titleHint?: string;
  title?: string;
  productCategory?: any;
  logo?: string;
  backgroundImageLink?: string;
};

export type ListItemProductsType = {
  icon?: any;
  title?: string;
  text?: string;
  percent?: string;
  percentText?: string;
  sumText?: string;
  isTableItem?: boolean;
  type: ShowCaseProductType;
  productId: string;
  subtitleV2?: {
    label: string;
    style: SubtitleV2StyleEnum;
  };
  individual?: boolean;
  validFrom?: string;
  validTo?: string;
  value?: string;
  subValue?: string;
  tags?: any;
  tag?: {
    name: string;
    state: string;
    value: string;
  };
  valueStyle?: string;
  productCategory?: any;
  iconLink?: string;
  iconLinks?: string[];
};

export enum SubtitleV2StyleEnum {
  GREY = "grey",
  RED = "red",
  YELLOW = "yellow",
  GREEN = "green",
}

export type ListCompositionItemType = {
  itemTitle: string;
  itemStock: string;
  itemDescription: string;
  itemIconLink?: string;
};

export type IIFBulletsItem = {
  title?: string;
  description?: string;
  bottomImageLink?: string;
};

export type SectionsListItemType = {
  title: string;
  iconType: string;
  iconName: string;
  bullets?: IIFBulletsItem[];
};

export type SectionedListItemType = {
  displayType?: string;
  sections?: SectionsListItemType[];
  title?: string;
  visibleCount?: number;
};

export type ProductSummaryItem = {
  ticker: string;
  title: string;
  iconLink: string;
};

export type ListItemType = {
  itemTitle: string;
  itemDescription: string;
  itemTooltip?: string;
  iconName?: string;
};

export type ListType = {
  title: string;
  items: ListItemType[];
};

export type DocListType = {
  title?: string;
  items: {
    fileLink: string;
    fileName: string;
    fileExtension?: string;
    reportDate?: string | null;
  }[];
};

export type TCategory = {
  label: string;
  description: string;
  imageLink: string;
};

export type TScripts = {
  title: string;
  categories: TCategory[];
};

export type NotesBuyingInfoType = {
  productCurrency: string;
  minBuySum: number;
  marketingName: string;
  primarySale: boolean;
  price: number;
  maxPrice: number;
  priceNominal: number;
  brokerageFee: number;
};

export enum CurrentProductTypeEnum {
  SP_INDIVIDUAL = "SP_INDIVIDUAL",
  AM_INDIVIDUAL = "AM_INDIVIDUAL",
}

export interface CurrentProductType {
  productId: string;
  type: ShowCaseProductType | "";
  isActual?: boolean;
  productType?: CurrentProductTypeEnum;
  externalId?: string;
  productCategory?: string;
  offerId?: string;
  language?: string;
  title?: string;
  subTitle?: string;
  infoText?: string;
  isStatusProfessionalRequired?: boolean;
  presentation?: {
    link: string;
    fileName?: string;
    type: string;
  };
  whyListBlock?: ListType;
  conditions?: ListType;
  documents?: DocListType;
  disclaimer?: string;
  whoGoverns?: {
    title: string;
    name?: string;
    description?: string;
    iconLink?: string;
  };
  individualGridList?: ListType;
  faqBlock?: ListType;
  widgetInfo?: {
    title: string;
    investsList: InvestListItem[];
    buttonText?: string;
    headerImage?: string;
    buttonEvent?: () => void;
    assetsType?: string;
    disabledButton?: boolean;
    havingSecondBtn?: boolean;
    secondBtnEvent?: () => void;
    secondBtnText?: string;
    disabledSecondBtn?: boolean;
    marginTop?: number;
    havingStarButtons?: boolean;
    starButtonEvent?: () => void;
    starDisabledButton?: boolean;
    strategyType?: string;
    strategyCurrency?: string;
    riskGrade?: number;
    isProfessional?: boolean;
    notesBuyingInfo?: NotesBuyingInfoType;
    closeIdeaReason?: string;
    url?: string;
  };
  userRiskMatch?: boolean;
  compositionStocks?: {
    title?: string;
    arrItems?: ListCompositionItemType[];
  };
  sectionedList?: SectionedListItemType[];
  productSummary?: ProductSummaryItem[];
  additionalPlates?: any;
  imageLink?: string;
  scripts?: TScripts;
  productAction?: ProductAction;
}

export type ProductAction = {
  data?: { url: string };
  behaviors?: { type: BehaviorsTypeEnum; parameter: { title: string; isin?: string; direction?: string } }[];
  label: string;
  type: ProductTypeEnum;
};

export enum ProductTypeEnum {
  URL = "URL",
  BEHAVIOUR_ORIENTED = "BEHAVIOUR_ORIENTED",
}

export enum BehaviorsTypeEnum {
  OTC_TRADE = "OTC_TRADE",
}

export type GetCurrentProductRequest = {
  productId: string;
  productType: ShowCaseProductType;
  language: string;
};

export type GetShowCaseRequest = {
  productType?: ShowCaseProductType;
  language: string;
  quantity?: number;
  isin?: string;
  open?: boolean;
  params?: { [key: string]: string };
};

export enum FiltersArrEnum {
  SELECT = "SELECT",
  MULTISELECT = "MULTISELECT",
  INPUT = "INPUT",
}

export type GetProductFilter = {
  items: InvestIdeasFilterItem[];
  label: string;
  paramKey: string;
  type: FiltersArrEnum;
};

export type InvestIdeasFilterItem = {
  label: string;
  key: string;
};

export enum SortValuesEnum {
  DESC = "desc",
  ASC = "asc",
  EMPTY = "",
}

export enum SortKeysEnum {
  VALID_FROM = "validFrom",
  PROFITABILITY = "profitability",
  INVEST_AMOUNT = "minBuySum",
  ISSUER = "issuer",
  YTM = "ytm",
  MATURITY = "maturity",
  NAME = "name",
  PRICE = "price",
  TRADE_TURNOVER = "tradeTurnover",
  PRICE_CHANGE = "priceChange",
}

export type SortValueStore = {
  sortKey: SortKeysEnum | "";
  sortValue: SortValuesEnum;
};

export type SelectFilterItem = InvestIdeasFilterItem & { isOpen: boolean };

export type SelectFiltersStore = {
  items: SelectFilterItem[];
  paramKey: string;
};

export enum UserPermissions {
  AGREEMENT_ALREADY_EXISTS = "AGREEMENT_ALREADY_EXISTS",
  REQUEST_ALREADY_SENT = "REQUEST_ALREADY_SENT",
  REQUEST_IS_OLD = "REQUEST_IS_OLD",
  ERROR = "ERROR",
}

export interface AssetRequisitesStore {
  haveIbanDontBcsUSD: Array<any>;
  haveIbanDontBcsCNY: Array<any>;
  dontIbanDontBcsUSD: Array<any>;
  dontIbanHaveBcsUSD: Array<any>;
}

export enum StrategyTags {
  ASSET_BACK_ENG_FULL = "asset management",
  ASSET_BACK_RUS_FULL = "доверительное управление",
  STRUCTURE_BACK_ENG_FULL = "structured product",
  STRUCTURE_BACK_ENG_FULL_PLURAL = "structured products",
  STRUCTURE_BACK_RUS_FULL_PLURAL = "структурные продукты",
  STRUCTURE_BACK_RUS_FULL = "структурный продукт",
  ASSET_BACK_ENG_SHORT = "AM",
  ASSET_BACK_RUS_SHORT = "ДУ",
  STRUCTURE_BACK_ENG_SHORT = "SP",
  STRUCTURE_BACK_RUS_SHORT = "СП",
}

export enum Currencies {
  USD = "USD",
  CNY = "CNY",
  RUB = "RUB",
  EUR = "EUR",
}

export interface WhiteSliderBlock {
  title?: string;
  subtitle?: string;
  plates: {
    slideTitle?: string;
    percentProduct?: string;
    percentText?: string;
    productId: string;
    type?: ShowCaseProductType | "";
    typeText?: string;
    icon?: any;
    iconLinks?: string[];
    tags?: { value: string; name: string }[];
  }[];
}

export interface MarketProducts {
  listTabs?: {
    title?: string;
    subtitle?: string;
    tabItems: {
      iconLinks: string[];
      tabTitle: string;
      tabSubtitle: string;
      list: ListItemProductsType[];
    }[];
  };
  brownGeometrySliderBlock?: WhiteSliderBlock;
  whiteSliderBlock?: WhiteSliderBlock;
  platesSliderBlock?: ShowcaseAssetTabHeader;
  bigPlateBlockContent?: {
    title?: string;
    subtitle?: string;
    textBigPlates?: {
      plate?: AssetHeaderPlateType;
      textBlock?: {
        titleOfPart?: string;
        textOfPart?: string;
      }[];
    }[];
  };
  listInvestIdeas: {
    title: string;
    subtitle?: string;
    plates: {
      logo: string;
      productCategory: string;
      productId: string;
      subtitle: string;
      tags: {
        name: string;
        state: string;
        value: string;
      }[];
      title: string;
      titleHint: string;
    }[];
  };
  cbs?: {
    plateType: MarketUCFPlateType.bigPlate;
    productCategory: ShowCaseProductType.CBS;
    productId: string;
    title: string;
    type: string;
    description: string[];
    blockTitle: string;
  };
  bonds: {
    title: string;
    list: IBondsListItem[];
  };
  nonfinancialServices: {
    title?: string;
    subtitle?: string;
    action?: string;
    list: INonfinancialServicesItem[];
  };
}

export enum MarketUCFPlateType {
  brownGeometrySlider = "MEDIUM",
  whiteSlider = "SMALL",
  plateSlider = "BIG",
  bigPlate = "LARGE",
}

export type ShowcaseTabGroupedItem = {
  title?: string;
  subTitle?: string;
  items: ListItemProductsType[];
};

type TNewsSource = {
  id?: string;
  name?: string;
  logo?: string;
};

export type TAssetsInfo = {
  id: string;
  isin: string;
  ticker: string;
  assetIconLink: string;
};

export type TButtonBlockInfos = {
  id: string;
  title: string;
  subtitle?: string;
  icon: string;
};

export type TButtonBlock = {
  id: string;
  link: string;
  type: "ULTIMA_LINK" | "PRODUCT_LINK";
  text: string;
  description: string;
  buttonBlockInfos?: TButtonBlockInfos[];
};

type TGallery = {
  id?: string;
  description?: string;
  pictureLinks?: Array<{
    id?: string;
    link?: string;
  }>;
};

export type TNewsItem = {
  id: string;
  title?: string;
  subtitle?: string;
  publicationDate?: string;
  thumbnail?: string;
  excerpt?: string;
  source?: TNewsSource;
  liked?: boolean;
  likes?: number;
  assetsInfo?: TAssetsInfo[];
  body?: string;
  gallery?: TGallery;
  buttonBlock?: TButtonBlock;
  disclaimer?: string;
  loaded?: boolean;
};

export type TGetNewsListResponse = {
  totalPages?: number;
  totalElements?: number;
  content?: TNewsItem[];
  pageable?: TPagination;
};

export type TGetShowCaseNewsItemData = {
  selectedNews?: TNewsItem;
  id: string;
} & GetShowCaseRequest;

export type TGetShowcaseNewsTabList = {
  page?: number;
  size?: number;
  selectedNewsId?: string;
  publicationDate?: Date;
  title?: string;
  liked?: boolean;
} & GetShowCaseRequest;

export type TPutShowcaseNewsItemLikes = {
  id: string;
};

export type TGetRequesitesParams = {
  productId?: string;
  strategyId?: string;
};

export type TStoryItem = TStoryCard & { quantitySlides?: number; slides?: TStorySlide[]; loaded?: boolean };

export type TStoriesList = TStoryItem[];

export type TModelErrorText = {
  firstSP: string;
  secondSP: string;
  firstAM: string;
  secondAM: string;
  SPbuttonText?: string;
  SPbuttonLink?: string;
  AMbuttonText?: string;
  AMbuttonLink?: string;
  AMOkButtonText?: string;
  AMOkButtonLink?: string;
};

export type TRecentlyViewed = {
  title: string;
  items: {
    productId: string;
    typeProduct: string;
    type: ShowCaseProductType;
    title: string;
  }[];
};

export type TRecentlyViewedItem = Pick<ListItemProductsType, "productId" | "title" | "productCategory"> & {
  marketingName: string;
  iconLinks: string[];
};

export type TGetRecentlyViewedResponse = {
  title: string;
  items: TRecentlyViewedItem[];
};

export interface IBondsListItem {
  logoSrc?: string[];
  name: string;
  time: string;
  percent: string;
  sum: string;
  id: string;
}

export interface INonfinancialServicesItem {
  title: string;
  description: string;
  imageLink?: string;
  id: string;
}

export type TTabsItemWithKey = { index: number; key?: string };

export interface IShowcaseModel {
  getCurrentProduct: Event<GetCurrentProductRequest>;
  resetCurrentProduct: Event<void>;
  $currentProduct: Store<CurrentProductType>;
  $currentProductPending: Store<boolean>;
  getDocument: Event<string>;
  getPrivateDocument: Event<string>;
  $documentStore: Store<any>;
  gate: Gate<any>;
  closePdfModal: Event<void>;
  openingPdfModal: Event<void>;
  getUserPermission: Event<ShowCaseProductTypePermission>;
  getAndLoadDocument: Event<string>;
  $loadingFileName: Store<string>;
  loadingFileNameChanged: Event<string>;
  getProductByIsin: Event<GetShowCaseRequest>;
  $getShowcaseNewsTabListPending: Store<boolean>;
  $isFirstRequestNewsTabList: Store<boolean>;
  getNewsShowCaseList: Event<TGetShowcaseNewsTabList>;
  changeNewsItemLikes: Event<TPutShowcaseNewsItemLikes>;
  resetNewsTabProducts: Event<void>;
  $showcaseNewsTabList: Store<TGetNewsListResponse>;
  putShowcaseNewsItemLikesFx: Effect<TPutShowcaseNewsItemLikes, TNewsItem, AxiosError>;
  getIspImage: Event<string>;
  $ispImage: Store<string | null>;
  getUserPermissionFx: Effect<ShowCaseProductTypePermission, any, AxiosError>;
  getUserPermissionByServiceType: Event<ShowCaseProductTypePermission>;
}

export interface IShowcaseNotesService {
  notesBuyingForm: Form<any>;
  purchasingNote: Event<string>;
  sendNotesForm: Event<void>;
  $processInfo: Store<any>;
  showcaseNotesGate: Gate<any>;
  getProcessFx: Effect<ProcessStateRequest, any, AxiosError>;
  putTaskCompleteFx: Effect<any, any, AxiosError>;
  $getProcessFxPending: Store<boolean>;
  $putTaskCompleteFxPending: Store<boolean>;
  $isOtpProcess: Store<boolean>;
  setShowSuccessMessage: Event<boolean>;
  setShowPendingMessage: Event<boolean>;
  $showSuccessMessage: Store<boolean>;
  $showPendingMessage: Store<boolean>;
  setIsNotify: Event<boolean>;
  otpGate: Gate<any>;
  otpForm: Form<any>;
  checkCorrect: Event<void>;
  resend: Event<void>;
  agree: Event<void>;
  sendOtpCode: Event<void>;
  $otpRequestError: Store<boolean>;
  $otpPending: Store<boolean>;
  $isDisabledActions: Store<boolean>;
  setIsDisabledActions: Event<boolean>;
  $getNotesTabHeaderPending: Store<boolean>;
  $getNotesTabListPending: Store<boolean>;
  $getNotesFiltersPending: Store<boolean>;
  resetNotesTabProducts: Event<void>;
  getNotesShowCase: Event<GetShowCaseRequest>;
  $showcaseTabHeaderNotesPlates: Store<ShowcaseAssetTabHeader>;
  $showcaseNotesTabList: Store<{
    items: ListItemProductsType[];
  }>;
  getNotesFilters: Event<GetShowCaseRequest>;
  $notesFilters: Store<GetProductFilter[]>;
  setChosenNotesFilter: Event<{ key: string; filter: string }>;
  $chosenNotesFilters: Store<{ [key: string]: string[] } | null>;
  setCurrentNotesFilter: Event<void>;
  setSelectNotesFilters: Event<SelectFilterItem>;
  $selectBlockNotesFilters: Store<SelectFiltersStore | null>;
  $currentNotesFilters: Store<{ [key: string]: string[] } | null>;
  closeNotesFilters: Event<void>;
  resetNotesFilters: Event<void>;
  $chosenNotesSort: Store<{
    sortKey: SortKeysEnum | "";
    sortValue: SortValuesEnum;
  }>;
  setCurrentNotesSort: Event<void>;
  setChosenNotesSort: Event<SortValueStore>;
  resetNotesSort: Event<void>;
  setCurrentNotesFiltersAndSort: Event<void>;
  $isOpenSort: Store<boolean>;
  setIsOpenSort: Event<boolean>;
  $isNotesFormActive: Store<boolean>;
  setNotesForm: Event<boolean>;
}
