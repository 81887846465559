import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Box, ClickAwayListener, useMediaQuery } from "@mui/material";

import Icon from "~/shared/Icon";
import colorTable from "~/shared/ui-kit/constants/colors.ts";
import { useStyles } from "./styles.ts";
import { BaseButton, BaseTypography } from "~/shared/ui-kit/BaseComponents";
import { formatRelativeDate } from "~/lib/getDate";
import { NotificationItem } from "~/app/layouts/BaseLayout/components/ViewportHeader/components/NotificationItem";
import { disableScroll, enableScroll } from "~/lib/utils";
import { ProductTabError } from "~/sharedFeatures/USMarket";
import { headerModel } from "~/modules/header-model";
import { useUnit } from "effector-react";
import { notificationService } from "~/modules/notifications";
import Loader from "~/sharedFeatures/Loader";
import { t } from "~/i18n";

export const Notification = () => {
  const { classes } = useStyles();

  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const {
    $unreadPushCount,
    getPushHistoryEv,
    $pushHistory,
    readAllMessagesEv,
    $readAllMessagesFxPending,
    $getPushHistoryFxPending,
    resetPushHistoryEv,
    $isHistoryError,
    incCurrentPage,
    $hasMoreData,
    $getNextPagePushHistoryFxPending,
  } = notificationService;
  const unreadPushCount = useUnit($unreadPushCount);
  const pushHistory = useUnit($pushHistory);
  const readAllMessagesFxPending = useUnit($readAllMessagesFxPending);
  const getPushHistoryFxPending = useUnit($getPushHistoryFxPending);
  const getNextPagePushHistoryFxPending = useUnit($getNextPagePushHistoryFxPending);
  const isHistoryError = useUnit($isHistoryError);
  const hasMoreData = useUnit($hasMoreData);

  useLayoutEffect(() => {
    if (isOpenNotification) getPushHistoryEv();
    return () => {
      resetPushHistoryEv();
    };
  }, [isOpenNotification]);

  const { $headerTheme } = headerModel;
  const headerTheme = useUnit($headerTheme);

  const notificationsWithDates = useMemo(() => {
    return Object.entries(
      [...pushHistory]
        .sort((a, b) => new Date(b.sentDateTime).getTime() - new Date(a.sentDateTime).getTime())
        .reduce((acc: { [key: string]: any[] }, el) => {
          const date = new Date(el.sentDateTime);
          let key = formatRelativeDate(date.toString());

          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(el);

          return acc;
        }, {})
    );
  }, [pushHistory]);

  useEffect(() => {
    if (isMobile && isOpenNotification) {
      disableScroll();
    } else {
      enableScroll();
    }

    return () => enableScroll();
  }, [isMobile, isOpenNotification]);

  const loadMoreNotifications = async () => {
    if (getNextPagePushHistoryFxPending || !hasMoreData) return;

    if (hasMoreData) {
      incCurrentPage();
    }
  };

  const observer = useRef<IntersectionObserver | null>(null);
  const lastNotificationRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (getNextPagePushHistoryFxPending) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreData) {
          loadMoreNotifications();
        }
      });
      if (node) observer.current.observe(node);
    },
    [getNextPagePushHistoryFxPending, hasMoreData]
  );

  return (
    <Box className={classes.container}>
      <Box>
        <Icon
          name="bell"
          size={24}
          withBackground={{ height: 24 }}
          color={
            isOpenNotification
              ? colorTable.nobleOchre
              : headerTheme.isDarkColorTheme
                ? colorTable.white
                : colorTable.anthraciteGray
          }
          onClick={() => setIsOpenNotification((prev) => !prev)}
        />
        {!!unreadPushCount && <Box className={classes.countNotRead}>{unreadPushCount}</Box>}
      </Box>

      {isOpenNotification && (
        <ClickAwayListener onClickAway={() => setIsOpenNotification(false)}>
          <Box className={classes.notificationContainer}>
            <Box className={classes.headWrap}>
              <BaseTypography color={colorTable.charcoalBlack} fontType="headline_semiBold">
                {t("PUSH.modal_notifications.notifications")}
              </BaseTypography>
              <Box style={{ cursor: "pointer" }}>
                <Icon
                  name="crossIcon"
                  size={16}
                  withBackground={{
                    height: 24,
                    width: 24,
                    borderRadius: "50%",
                    backgroundColor: colorTable.white_20,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  color={colorTable.anthraciteGray}
                  onClick={() => setIsOpenNotification((prev) => !prev)}
                />
              </Box>
            </Box>
            {isHistoryError && !getPushHistoryFxPending && (
              <ProductTabError
                containerClassName={classes.errorContainer}
                errorText={t("PUSH.modal_notifications.failed")}
                iconName="flash"
                iconColor={colorTable.charcoalBlack}
                btnCallback={() => {
                  getPushHistoryEv();
                }}
              />
            )}
            {getPushHistoryFxPending ? (
              <Loader className={classes.loaderWrap} />
            ) : (
              <>
                {!!notificationsWithDates.length && !isHistoryError ? (
                  <>
                    <Box className={classes.contentWrap}>
                      {notificationsWithDates.map(([title, content]) => (
                        <Box key={title} className={classes.dateWithContent}>
                          <BaseTypography color={colorTable.moonGrey} fontType="subhead">
                            {title}
                          </BaseTypography>
                          <Box className={classes.notificationsWrap}>
                            {content.map((el, index) => (
                              <NotificationItem
                                ref={content.length - 1 === index ? lastNotificationRef : null}
                                key={el.id}
                                id={el.id}
                                date={el.sentDateTime}
                                description={el.body}
                                title={el.subject}
                                isRead={el.status === "READ"}
                              />
                            ))}
                          </Box>
                        </Box>
                      ))}
                      {getNextPagePushHistoryFxPending && <Loader />}
                    </Box>
                    {!!unreadPushCount && (
                      <BaseButton
                        className={classes.btnReadAll}
                        typeStyle="black"
                        onClick={() => readAllMessagesEv()}
                        disabled={readAllMessagesFxPending}
                        isLoader={readAllMessagesFxPending}
                      >
                        {t("PUSH.modal_notifications.mark_all")}
                        <Box className={classes.countNotReadWhite}>{unreadPushCount}</Box>
                      </BaseButton>
                    )}
                  </>
                ) : (
                  <>
                    {!isHistoryError && (
                      <Box className={classes.notificationEmpty}>
                        <Icon
                          name="bell"
                          size={40}
                          withBackground={{
                            height: 68,
                            width: 68,
                            borderRadius: "16px",
                            backgroundColor: colorTable.white_20,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          color={colorTable.anthraciteGray}
                          onClick={() => setIsOpenNotification((prev) => !prev)}
                        />
                        <BaseTypography color={colorTable.charcoalBlack} fontType="headline_semiBold">
                          {t("PUSH.modal_notifications.empty")}
                        </BaseTypography>
                        <BaseTypography color={colorTable.textSecondary} fontType="text">
                          {t("PUSH.modal_notifications.info")}
                        </BaseTypography>
                      </Box>
                    )}
                  </>
                )}
              </>
            )}
          </Box>
        </ClickAwayListener>
      )}
    </Box>
  );
};
