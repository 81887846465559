import { makeStyles } from "tss-react/mui";

import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";
import { tableFont } from "~/shared/ui-kit/constants/typography";

export default makeStyles()(() => ({
  tooltipWrapper: {
    width: "100%",
  },
  textField: {
    all: "unset",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  outlined: {
    all: "unset",
    display: "none",
  },
  label: {
    all: "unset",
    ...tableFont.subhead,
    color: colorTable.moonGrey,
    width: "100%",
    marginBottom: "8px",
    "&.Mui-focused": {
      color: colorTable.moonGrey,
    },
    "&.Mui-disabled": {
      color: colorTable.moonGrey,
    },
    "&.Mui-error": {
      color: colorTable.moonGrey,
    },
  },
  helperText: {
    all: "unset",
    ...tableFont.caption,
    color: colorTable.moonGrey,
    paddingTop: "8px",

    "&.Mui-error": {
      color: colorTable.red,
    },

    "&.Mui-disabled": {
      color: colorTable.moonGrey,
    },
  },
  inputWrapper: {
    all: "unset",
    border: `1px solid ${borderColors.greyContour}`,
    borderRadius: "4px",
    backgroundColor: colorTable.white,
    display: "flex",
    alignItems: "center",
    maxHeight: "48px",
    boxSizing: "border-box",
    padding: "14px 12px 14px 16px",

    "& .MuiOutlinedInput-notchedOutline": {
      display: "none",
    },

    "&:hover": {
      border: `1px solid ${borderColors.nobleOchre}`,
      background: colorTable.white,
    },

    "&.Mui-focused": {
      borderColor: colorTable.nobleOchre,
    },

    "&.Mui-disabled": {
      borderColor: colorTable.greyContour,
      backgroundColor: colorTable.lightGrey,
      cursor: "not-allowed",
    },

    "&.Mui-error": {
      borderColor: colorTable.red,
    },
  },
  input: {
    all: "unset",
    width: "100%",
    textOverflow: "ellipsis",
    ...tableFont.subhead,
    color: colorTable.charcoalBlack,
    padding: "0",

    "&.MuiInputBase-inputAdornedStart": {
      paddingLeft: "8px",
    },

    "&.MuiInputBase-inputAdornedEnd": {
      paddingRight: "8px",
    },

    "&::placeholder": {
      all: "unset",
      opacity: 1,
      color: colorTable.moonGrey,
      ...tableFont.subhead,
    },

    "&.Mui-disabled": {
      color: colorTable.moonGrey,
    },
  },
}));
