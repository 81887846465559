import { GenderEnum, InvestProfileTypeEnum, StatusW8Enum } from "~/constants/enums";
import { PassportTypeEnum } from "~/pages/ClientProfile/components/Personal&Passport&Address/consts";
import { ProcessesAvailable } from "../client-card/models/CustomerAgreementDTO";

type TExternalSystem = {
  id?: string;
  systemType?: string;
  externalId?: string;
  externalLogin?: string;
  clientId?: string;
};

type TInvestProfile = {
  id?: string;
  processType?: ProcessesAvailable;
  type?: InvestProfileTypeEnum;
  appropriatenessTotalScore?: string;
  riskLevel?: number;
  warning?: boolean;
  engagementLetterReceived?: boolean;
  investmentProfileDate?: string;
  riskAssessmentIndicatorDetails?: TRiskAssessmentIndicatorDetail[];
};

type TRiskAssessmentIndicatorDetail = {
  id?: string;
  riskType?: string;
  score?: string;
  comment?: string;
};

type TEconomicProfile = {
  id: string;
  expectedTransactionsVolume?: string;
  expectedAssetsVolume?: string;
  totalIncome?: number;
  totalNet?: number;
  totalValueOfProperty?: number;
  creditsAndLoans?: number;
  totalDepositsInBankAccounts?: number;
  immovableProperty?: number;
  movableProperty?: number;
  financialInstruments?: number;
  overdraftsAndOtherFormsOfShortTermFinancing?: number;
  otherEarnedIncome?: number;
  annualSalary?: number;
  otherAsset?: number;
  fromSelf?: number;
  commissions?: number;
  dividends?: number;
  rents?: number;
  royalties?: number;
  transFromParents?: number;
  benefitsFinSupport?: number;
  pensions?: number;
  professionalActivity?: {
    id?: string;
    activityType?: "EMPLOYEE";
    otherActivityDesc?: string;
    employerName?: string;
    registrationCountry?: string;
    registrationDate?: string;
    registrationNumber?: string;
    businessCountry?: string;
    country?: string;
    region?: string;
    city?: string;
    street?: string;
    house?: string;
    building?: string;
    position?: string;
    website?: string;
    phoneNumber?: string;
    apartment?: string;
    zipcode?: string;
    licenseInfo?: {
      id?: string;
      type?: string;
      number?: string;
      issuedBy?: string;
      issuedDate?: string;
      expirationDate?: string;
      typesOfLicensedActivities?: string;
      licenseValidFrom?: string;
    };
    isSelfEmployed?: boolean;
  };
};

type TTaxInfo = {
  id?: string;
  tinNumber?: string;
  taxResidencyCountry?: string;
  clientId?: string;
  noTinReason?: string;
  noTinReasonDesc?: string;
  isTaxResident?: boolean;
};

type TIdentityDocument = {
  id?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  firstNameRu?: string;
  lastNameRu?: string;
  middleNameRu?: string;
  type?: PassportTypeEnum;
  serial?: string;
  number?: string;
  issuedBy?: string;
  issuedByRu?: string;
  issueDate?: string;
  expireDate?: string;
  departmentCode?: string;
  status?: string;
  bcsfsIds?: string[];
  serialAndNumber?: string;
};

type TAddressProofDocument = {
  id?: string;
  type?: string;
  bcsfsIds?: string[];
};

type TAdditionalDocument = {
  id?: string;
  type?: string;
  bcsfsIds?: string[];
  status?: string;
};

type TSumSubIntegrationDocument = {
  id?: string;
  type?: string;
  bcsfsIds?: string[];
};

export enum ContactTypeEnum {
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  CITY_PHONE = "CITY_PHONE",
}

type TContact = {
  id?: string;
  type?: string;
  value?: string;
  primary?: boolean;
  preferredFormOfContact?: boolean;
  clientId?: string;
};

type TAdress = {
  id?: string;
  type?: string;
  country?: string;
  state?: string;
  city?: string;
  street?: string;
  house?: string;
  building?: string;
  apartment?: string;
  zipcode?: string;
  address?: string;
};

type TEdoAgreement = {
  id?: string;
  edoAgreementDoc?: string;
  edoAgreementNumber?: string;
  edoAgreementDate?: string;
};

type TPep = {
  id?: string;
  relative?: boolean;
  pepPeriodStart?: string;
  pepPeriodEnd?: string;
  pepFunction?: string;
  pepFirstName?: string;
  pepLastName?: string;
  pepMiddleName?: string;
  relationship?: string;
  pepPassNumber?: string;
  pepPassIssuedWhen?: string;
  pepBirthDay?: string;
  pepPassSeries?: string;
  pepBirthCity?: string;
  pepBirthCountryId?: string;
};

type TAssetRequest = {
  id?: string;
  product?: string;
  strategyType?: string;
  currency?: string;
  term?: string;
  means?: {
    id?: string;
    means?: string;
  }[];
  assets: {
    id?: string;
    assets?: string;
  }[];
};

export enum CurrentClientStatusEnum {
  NEW = "NEW",
  IDENTIFICATION_IN_PROGRESS = "IDENTIFICATION_IN_PROGRESS",
  IDENTIFICATION_IN_PROGRESS_QUESTIONNAIRE_IS_COMPLETED = "IDENTIFICATION_IN_PROGRESS_QUESTIONNAIRE_IS_COMPLETED",
  IDENTIFICATION_COMPLETED = "IDENTIFICATION_COMPLETED",
  IDENTIFICATION_FAILED = "IDENTIFICATION_FAILED",
  BROKERAGE_SERVICES_FORMALIZATION_COMPLETED = "BROKERAGE_SERVICES_FORMALIZATION_COMPLETED",
  TRUST_MANAGEMENT_FORMALIZATION_COMPLETED = "TRUST_MANAGEMENT_FORMALIZATION_COMPLETED",
  TRUST_MANAGEMENT_FORMALIZATION_AND_BROKERAGE_SERVICES = "TRUST_MANAGEMENT_FORMALIZATION_AND_BROKERAGE_SERVICES",
  ELECTRONIC_DOCUMENT_SIGNED = "ELECTRONIC_DOCUMENT_SIGNED",
  ELECTRONIC_DOCUMENT_SIGNED_IN_PROGRESS = "ELECTRONIC_DOCUMENT_SIGNED_IN_PROGRESS",
  ELECTRONIC_DOCUMENT_SIGNED_COMPLETED = "ELECTRONIC_DOCUMENT_SIGNED_COMPLETED",
  ELECTRONIC_DOCUMENT_SIGNED_OFFLINE = "ELECTRONIC_DOCUMENT_SIGNED_OFFLINE",
  ACCOUNT_REQUEST_SENT = "ACCOUNT_REQUEST_SENT",
}

export type TClientCurrent = {
  id: string;
  firstName: string;
  lastName: string;
  middleName: string;
  hasNoMiddleName: boolean;
  clientStatus: CurrentClientStatusEnum;
  gender: GenderEnum;
  birthDay: string;
  qualifiedInvestor: boolean;
  externalSystems: TExternalSystem[];
  investProfiles: TInvestProfile[];
  economicProfile: TEconomicProfile;
  taxInfos: TTaxInfo[];
  identityDocuments: TIdentityDocument[];
  addressProofDocuments: TAddressProofDocument[];
  additionalDocuments: TAdditionalDocument[];
  sumSubIntegrationDocuments: TSumSubIntegrationDocument[];
  contacts: TContact[];
  addresses: TAdress[];
  edoAgreements: TEdoAgreement[];
  digitalId: number;
  channel: string;
  userProperties: {
    id?: string;
    approvedBySeo?: boolean;
    approvedByCompliance?: boolean;
    approvedByRiskOfficer?: boolean;
    riskProfileAnketa?: {
      processType?: string | null;
      dateBirth?: string;
      education?: boolean[];
      finInstr?: boolean[];
      firstTestQuest?: boolean;
      secondTestQuest?: boolean;
      investmentObjective?: boolean[];
      savingsPart?: boolean[];
      investPeriod?: boolean[];
      ratio?: boolean[];
      affordToLose?: boolean[];
    };
    peps?: TPep[];
    isPoliticallyExposedPerson?: boolean;
    isRelatedToPoliticallyExposedPerson?: boolean;
    isUSACitizen?: boolean;
    isActualAddressMatchesRegisteredAddress?: boolean;
    isMailingAddressMatchesRegisteredAddress?: boolean;
    isMailingAddressMatchesActualAddress?: boolean;
    isSumSubstanceConsentAccepted?: boolean;
    isCitizenshipByInvestmentInAnyCountry?: boolean;
    isResidentInOtherCountries?: boolean;
  };
  questionAnswer: {
    id?: string;
    question?: {
      id?: string;
      language?: string;
      questionText?: string;
    };
    answer?: string;
  };
  nationalityCharCode3: string;
  assetRequests: TAssetRequest[];
  lastModifiedDate: string;
  lastModifiedBy: {
    clientId: string;
    fullName: string;
    username: string;
  };
  createdDate: string;
  dlogin: string;
  fullName: string;
  online: true;
  w8Form?: {
    expirationDate?: string;
    status: StatusW8Enum;
  };
  hasMarketData?: boolean;
};
