import { makeStyles } from "tss-react/mui";

import colorTable from "~/shared/ui-kit/constants/colors";
import theme from "~/configs/theme.ts";

export const useStyles = makeStyles()({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px",
    margin: "120px 0",
    [theme.breakpoints.down(768)]: {
      margin: "64px 0 0 0",
    },

    "& > p": {
      color: colorTable.textSecondary,
      textAlign: "center",
    },
    "& *": {
      boxSizing: "border-box",
    },
  },
  btnRefresh: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "6px 16px",
    marginTop: "8px",
    borderRadius: "12px",
  },
});
