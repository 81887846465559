// eslint-disable-next-line no-useless-escape
export const edmTextPattern = /^[A-Z][a-zA-Z\s\-]+$/;

export const onlyDigitsPattern = /^\d+$/;

export const idCardPattern = /^[A-Z0-9]+$/;

// eslint-disable-next-line no-useless-escape
export const postIndexPattern = /^[a-zA-Z0-9\-\.]+$/;

export const edmCityPattern = /^[\w\s,-.]+$/i;

export const addressPattern = /^[a-zA-Z0-9/\\\\-]+$/;
// eslint-disable-next-line no-useless-escape
export const housingPattern = /^[0-9\-]+$/;
// eslint-disable-next-line no-useless-escape
export const issuingPattern = /^[\w\s,\-\.]+$/i;

export const phonePattern = /\+[1-9][0-9]{3,28}$/;

export const emailPattern = /^(?=^.{1,255}$)(.{1,64})@([A-Za-z0-9_-]+)((\.[A-Za-z0-9_-]+){0,7})(\.[A-Za-z]{2,5})$/i;

export const smsCodePattern = /^[0-9]*$/;

export const swiftPattern = /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?/;
export const namePattern = /^[a-zA-Z]+(?:-[a-zA-Z]+)*$/;

export const passwordPattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[\S]*$/;

export const fileNamePattern = /^[-\w\sа-яА-ЯёЁ]+\.[a-zA-Z]{3,4}$/;

export const noDoubleSpacesPattern = /^(?:(?!\s\s).)*$/;

export const usTinPattern = /^\d{3}-\d{2}-\d{4}$|^\d{2}-\d{7}$/g;

export const dLoginPattern = /^d-(\d+)?$/;

export const notDigitPattern = /\D/g;

const LatinLettersNumsWSpacesDotsCommasDashes = /^[a-zA-Z\d\s,.-]+(?:-[a-zA-Z\d\s,.-]+)*(?:\.\d+)?$/;
// eslint-disable-next-line no-useless-escape
const LatinLettersNumsDashesDots = /^[a-zA-Z0-9\-\.]+$/;
// eslint-disable-next-line no-useless-escape
const LatinLettersDashesWSpace = /^[a-zA-Z\s\-]+$/;
const LatinLettersNumsDashesWSpace = /^[a-zA-Z0-9\s-]+$/;
const LatinLettersNumsDashesWSpaceDots = /^[a-zA-Z0-9\s.-]+$/;
// eslint-disable-next-line no-useless-escape
const LatinLettersNumsDashes = /^[a-zA-Z0-9\-]+$/;
// eslint-disable-next-line no-useless-escape
const LatinLettersNumsSlashRevSlashDashes = /^[a-zA-Z0-9\\/\-]+$/;
const LatinLetters = /^[a-zA-Z]+$/;
// eslint-disable-next-line no-useless-escape
const LatinLettersNumsWSpaceSymbols = /^[a-zA-Z0-9\s!@#$%&*()_+\-=\[\]{};':"|,.<>]+$/;
const LatinLettersNumsSpaceAllSpecSymbols = /^[a-zA-Z0-9!@"|?`~'№#$%:><”^’,&.*;}{)(_+=\-\s\\/[\]]*$/;
const LatinLettersNumsSpaceAllSpecSymbolsMaxLength255 = /^[a-zA-Z0-9!@"|?`~'№#$%:><^,&.*;}{)(_+=\-\s\\/[\]]{0,255}$/;

export const cityPattern = LatinLettersNumsWSpacesDotsCommasDashes;
export const zipCodePattern = LatinLettersNumsDashesWSpaceDots;
export const statePattern = LatinLettersDashesWSpace;
export const streetPattern = LatinLettersNumsDashesWSpace;
export const buildingPattern = LatinLettersNumsDashesWSpaceDots;
export const housePattern = LatinLettersNumsDashesWSpace;
export const apartmentPattern = LatinLettersNumsSlashRevSlashDashes;
export const relationsPattern = LatinLetters;
export const companyNamePattern = LatinLettersNumsWSpaceSymbols;
export const positionPattern = LatinLettersNumsDashesWSpace;
export const latinMaxLength255Pattern = LatinLettersNumsSpaceAllSpecSymbolsMaxLength255;

// Добавлено дла нового ui-kit, чтобы выводить типы
export default {
  edmTextPattern,
  onlyDigitsPattern,
  idCardPattern,
  postIndexPattern,
  edmCityPattern,
  addressPattern,
  housingPattern,
  issuingPattern,
  phonePattern,
  swiftPattern,
  namePattern,
  LatinLettersNumsWSpacesDotsCommasDashes,
  LatinLettersNumsDashesDots,
  LatinLettersDashesWSpace,
  LatinLettersNumsDashesWSpace,
  LatinLettersNumsDashesWSpaceDots,
  LatinLettersNumsDashes,
  LatinLettersNumsSlashRevSlashDashes,
  LatinLetters,
  LatinLettersNumsWSpaceSymbols,
  cityPattern,
  zipCodePattern,
  statePattern,
  streetPattern,
  buildingPattern,
  housePattern,
  apartmentPattern,
  relationsPattern,
  companyNamePattern,
  positionPattern,
};

export const defaultInputPattern = LatinLettersNumsSpaceAllSpecSymbols;
