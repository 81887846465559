import colorTable from "~/shared/ui-kit/constants/colors";

export const POINTS_COUNT_PER_SCREEN = 55;
export const APPROX_MOBILE_WIDTH = 220;
export const MIN_MOBILE_STEP = 2;
export const VERTICAL_CARTESIAN_OFFSET = 0.1;
export const HORIZONTAL_LINES_COUNT = 5;
export const CHART_HEIGHT = 340;
export const CHART_LINE_WIDTH = 1.5;
export const DESKTOP_CHART_WIDTH = 776;
export const DESKTOP_CHART_WIDTH_MARKET = 746;
export const ACTIVE_DOT_RADIUS = 6;
export const Y_AXIS_OFFSET_MOBILE = -25;
export const Y_AXIS_OFFSET_DESKTOP = -15;

export const HORIZONTAL_CARTESIAN_OFFSET = 0.05;

export const PAGE_WRAPPER_MOBILE_PADDING_SP = 0.03;
export const PAGE_WRAPPER_MOBILE_PADDING_AM = 30;
export const MOBILE_Y_AXIS_WIDTH = 40;
export const Y_AXIS_CHART_GAP = 4;
export const X_AXIS_INTERVAL = 0;

export const X_AXIS_STYLE = {
  fontSize: "12px",
  fill: colorTable.lightGrey_40,
  fontWeight: 400,
  lineHeight: "16px",
};

export const Y_AXIS_STYLE = {
  fontSize: "12px",
  fill: colorTable.charcoalBlack,
  fontWeight: 400,
  lineHeight: "16px",
};

export const GRADIENT_LIST = [
  { id: `color-${colorTable.red}`, firstStopColor: colorTable.red, secondStopColor: colorTable.white },
  {
    id: `color-${colorTable.green_50}`,
    firstStopColor: colorTable.green_50,
    secondStopColor: colorTable.white,
  },
  {
    id: `color-${colorTable.misteryBlue}`,
    firstStopColor: colorTable.misteryBlue,
    secondStopColor: colorTable.white,
  },
];

export const CHART_LINES_COLORS = [
  colorTable.chart_sky,
  colorTable.chart_teal,
  colorTable.nobleOchre,
  colorTable.orange,
  colorTable.chart_salmon,
  colorTable.chart_purple,
];
export const BARRIER_COLORS = [
  colorTable.barrier_1,
  colorTable.orange,
  colorTable.barrier_2,
  colorTable.barrier_3,
  colorTable.barrier_4,
  colorTable.barrier_5,
  colorTable.barrier_6,
  colorTable.barrier_7,
  colorTable.barrier_8,
  colorTable.barrier_9,
  colorTable.barrier_10,
  colorTable.barrier_11,
];

export const CHART_DATA_RESPONSE_MOCK = {
  chartType: "LINE",
  axisType: "VALUE",
  barriers: [
    { date: "2024.02.01", value: 10, name: "coupon" },
    { date: "2024.03.01", value: 10, name: "coupon" },
    { date: "2024.05.01", value: 15, name: "coupon" },
    { date: "2024.06.01", value: 15, name: "coupon" },
    { date: "2024.07.01", value: 15, name: "coupon" },
    { date: "2024.08.01", value: 10, name: "coupon" },
    { date: "2024.10.01", value: 20, name: "coupon" },

    { date: "2024.02.01", value: -100, name: "autocall" },
    { date: "2024.03.01", value: -100, name: "autocall" },
    { date: "2024.05.01", value: -150, name: "autocall" },
    { date: "2024.06.01", value: -150, name: "autocall" },
    { date: "2024.07.01", value: -150, name: "autocall" },
    { date: "2024.08.01", value: -100, name: "autocall" },
    { date: "2024.09.01", value: -200, name: "autocall" },

    // { date: "2024.02.01", value: 10, name: "Cap" },
    // { date: "2024.03.01", value: 10, name: "Cap" },
    // { date: "2024.05.01", value: 10, name: "Cap" },
    // { date: "2024.06.01", value: 10, name: "Cap" },
    // { date: "2024.07.01", value: 10, name: "Cap" },
    // { date: "2024.08.01", value: 10, name: "Cap" },
    // { date: "2024.09.01", value: 10, name: "Cap" },
  ],
  periods: [
    {
      period: "all",
      profitabilityPeriod: "10",
      couponDate: ["2024.06.01", "2024.11.01", "2024.02.01"],
      periodName: "All",
      product: [
        {
          idAsset: "efca5a5e-01a9-4d3a-8454-ebc99d3ae6ae",
          name: "Актив №1",
          points: [
            {
              date: "2024.01.01",
              value: 10.123,
            },
            {
              date: "2024.02.01",
              value: -20,
            },
            {
              date: "2024.03.01",
              value: -15,
            },
            {
              date: "2024.04.01",
              value: 0,
            },
            {
              date: "2024.05.01",
              value: 5,
            },
            {
              date: "2024.06.01",
              value: 3,
            },
            {
              date: "2024.07.01",
              value: 17,
            },
            {
              date: "2024.08.01",
              value: 20,
            },
            // {
            //   date: "2024.09.01",
            //   value: 21,
            // },
            // {
            //   date: "2024.10.01",
            //   value: 12,
            // },
            // {
            //   date: "2024.11.01",
            //   value: 35,
            // },
            // {
            //   date: "2024.12.01",
            //   value: 30,
            // },
          ],
        },
        // {
        //   idAsset: "dcbca228-3d46-4e5d-83ce-26c8e0a32402",
        //   name: "Revolve Group",
        //   points: [
        //     {
        //       date: "2024.01.01",
        //       value: -10,
        //     },
        //     {
        //       date: "2024.02.01",
        //       value: 20,
        //     },
        //     {
        //       date: "2024.03.01",
        //       value: 15,
        //     },
        //     {
        //       date: "2024.04.01",
        //       value: 0,
        //     },
        //     {
        //       date: "2024.05.01",
        //       value: -5,
        //     },
        //     {
        //       date: "2024.06.01",
        //       value: -3,
        //     },
        //     {
        //       date: "2024.07.01",
        //       value: -17,
        //     },
        //     {
        //       date: "2024.08.01",
        //       value: -20,
        //     },
        //     {
        //       date: "2024.09.01",
        //       value: -21,
        //     },
        //     {
        //       date: "2024.10.01",
        //       value: -12,
        //     },
        //     {
        //       date: "2024.11.01",
        //       value: -33,
        //     },
        //     {
        //       date: "2024.12.01",
        //       value: -31,
        //     },
        //   ],
        // },
        // {
        //   idAsset: "dcbca228-3d46-4e5d-83ce-26c8e0a32403",
        //   name: "Zedge Inc.",
        //   points: [
        //     {
        //       date: "2024.01.01",
        //       value: 0,
        //     },
        //     {
        //       date: "2024.04.01",
        //       value: 2,
        //     },
        //     {
        //       date: "2024.05.01",
        //       value: 5,
        //     },
        //     {
        //       date: "2024.06.01",
        //       value: -13,
        //     },
        //     {
        //       date: "2024.07.01",
        //       value: -1,
        //     },
        //     {
        //       date: "2024.10.01",
        //       value: 17,
        //     },
        //     {
        //       date: "2024.11.01",
        //       value: 15,
        //     },
        //     {
        //       date: "2024.12.01",
        //       value: 10,
        //     },
        //   ],
        // },
      ],
    },
  ],
};

export const AREA_CHART_MOCK = [
  {
    date: "2024.01.01",
    value: 0,
  },
  {
    date: "2024.02.01",
    value: -20,
  },
  {
    date: "2024.03.01",
    value: -15,
  },
  {
    date: "2024.04.01",
    value: 0,
  },
  {
    date: "2024.05.01",
    value: 5,
  },
  {
    date: "2024.06.01",
    value: 3,
  },
  {
    date: "2024.07.01",
    value: 17,
  },
  {
    date: "2024.08.01",
    value: 20,
  },
  {
    date: "2024.09.01",
    value: 21,
  },
  {
    date: "2024.10.01",
    value: 12,
  },
  {
    date: "2024.11.01",
    value: 35,
  },
  {
    date: "2024.12.01",
    value: 30,
  },
];

export const X_AXIS_POINTS_MOCK = ["2024.02.01", "2024.05.01", "2024.08.01", "2024.11.01"];
export const MIN_MOCK = -36;
export const MAX_MOCK = 39;

export const LINE_CHART_DATA_MOCK = [
  { date: "2024.01.01", "Актив №1": 10, "Zedge Inc.": -10, "Revolve Group": 0 },
  { date: "2024.02.01", "Актив №1": -20, "Zedge Inc.": 20 },
  { date: "2024.03.01", "Актив №1": -15, "Zedge Inc.": 15 },
  { date: "2024.04.01", "Актив №1": 0, "Zedge Inc.": 0, "Revolve Group": 2 },
  { date: "2024.05.01", "Актив №1": 5, "Zedge Inc.": -5, "Revolve Group": 5 },
  {
    date: "2024.06.01",
    "Актив №1": 3,
    "Zedge Inc.": -3,
    "Revolve Group": -13,
    "Актив №1-coupon": 3,
    "Zedge Inc.-coupon": -3,
    "Revolve Group-coupon": -13,
  },
  { date: "2024.07.01", "Актив №1": 17, "Zedge Inc.": -17, "Revolve Group": -1 },
  { date: "2024.08.01", "Актив №1": 20, "Zedge Inc.": -20 },
  { date: "2024.09.01", "Актив №1": 21, "Zedge Inc.": -21 },
  { date: "2024.10.01", "Актив №1": 12, "Zedge Inc.": -12, "Revolve Group": 17 },
  {
    date: "2024.11.01",
    "Актив №1": 35,
    "Revolve Group": -35,
    "Zedge Inc.": 15,
    "Актив №1-coupon": 35,
    "Zedge Inc.-coupon": -35,
    "Revolve Group-coupon": 15,
  },
  { date: "2024.12.01", "Актив №1": 30, "Zedge Inc.": -30, "Revolve Group": 10 },
];

export const BARIERS_MOCK = ["2024.06.01", "2024.11.01"];
export const LINE_KEYS_MOCK = {
  "Актив №1": {
    color: colorTable.chart_purple,
    dataKey: "Актив №1",
  },
  "Zedge Inc.": {
    color: colorTable.chart_salmon,
    dataKey: "Zedge Inc.",
  },
  "Revolve Group": {
    color: colorTable.chart_sky,
    dataKey: "Revolve Group",
  },
};
export const SKATTER_KEYS_MOCK = ["Актив №1-coupon", "Zedge Inc.-coupon", "Revolve Group-coupon"];
export const ASSETS_MOCK = [
  {
    idAsset: "efca5a5e-01a9-4d3a-8454-ebc99d3ae6ae",
    name: "Актив №1",
    ticker: "AR",
    isin: "RU000A0J2Q06",
    exchange: "US Securities (composite)",
    currency: "USD",
    startPriceDate: "2020-05-01",
    startPrice: 99.746,
    lastPriceDate: "2024-05-28",
    lastPrice: 88.15801,
    strikePriceDeviation: "110%",
  },
  {
    idAsset: "dcbca228-3d46-4e5d-83ce-26c8e0a32402",
    name: "Revolve Group",
    ticker: "T",
    isin: "JP343000009",
    exchange: "US Securities (composite)",
    currency: "USD",
    startPriceDate: "2020-05-01",
    startPrice: 111.872531,
    lastPriceDate: "2024-05-28",
    lastPrice: 24.486093,
    strikePriceDeviation: "85%",
  },
  {
    idAsset: "dcbca228-3d46-4e5d-83ce-26c8e0a32403",
    name: "Zedge Inc.",
    ticker: "T",
    isin: "US92826C8394",
    exchange: "US Securities (composite)",
    currency: "USD",
    startPriceDate: "2020-05-01",
    startPrice: 111.872531,
    lastPriceDate: "2024-05-28",
    lastPrice: 24.486093,
    strikePriceDeviation: "85%",
  },
];
