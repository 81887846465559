import { pdfBase64DmaType } from "~/entities/dma";

export const checkMaxRequestCount = (count: number) => {
  return count < Number(import.meta.env.VITE_RESEND_REQUEST_COUNTER);
};

export const getFileDMAName = (fileType: pdfBase64DmaType) => {
  switch (fileType) {
    case pdfBase64DmaType.fee_schedule_effective:
      return "Fee Schedule";
    case pdfBase64DmaType.md_terms_deactivation:
      return "Variation to Acceptance General Terms deactivation";
    case pdfBase64DmaType.schedule_e:
      return "SCHEDULE E: DIRECT MARKET ACCESS AND MARKET DATA";
  }
};
