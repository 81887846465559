import { createAction, createAsyncActions } from "~/lib/redux";

export const [
  accountPrintFormsRequestAction,
  accountPrintFormsStartAction,
  accountPrintFormsSuccessAction,
  accountPrintFormsErrorAction,
  accountPrintFormsClearAction,
] = createAsyncActions<any>("ACCOUNT_PRINT_FORMS");

export const [accountRequestStartAction, accountRequestSuccessAction, accountRequestErrorAction] =
  createAsyncActions<any>("ACCOUNT_REQUEST");

export const accountClearAction = createAction("ACCOUNT_CLEAR");
