import history from "~/configs/history";
import store from "~/configs/store";
import { errorsMessages, links, LocalStorageKeysEnum } from "~/constants/enums";
import { ProcessBusinessResponse } from "~/entities/keycloak";
import i18n, { t } from "~/i18n";
import { post } from "~/lib/request";
import { appMessageAction } from "~/modules/app/actions";
import { openAssetManagementShortType, openAssetManagementType } from "~/pages/OpenAssetManagement/services/types";

export const openAssetManagementServiceApi = {
  startAssetManagementOnboarding: async (data: openAssetManagementType): Promise<ProcessBusinessResponse> => {
    const params = {
      strategyType: data.strategyType || null,
      strategyName: data.strategyName || null,
    };
    const businessKey = localStorage.getItem(LocalStorageKeysEnum.onboardingBusinessKey);
    if (!!businessKey) {
      return {
        businessKey: businessKey,
      };
    } else {
      const res = await post(
        "@auth/executor/v2/onboarding/asset-management",
        { data: params },
        {
          headers: {
            "Accept-Language": i18n.language,
          },
        }
      ).catch(() => {
        store.dispatch(
          appMessageAction({
            message: errorsMessages.SUPPORT_SERVICE_2,
            options: { variant: "error" },
          })
        );
        history.push(links.operations);
      });
      return res.data;
    }
  },
  startAssetManagementOnboardingShort: async (data: openAssetManagementShortType): Promise<any> => {
    const params = {
      strategyRelatedOptions: data?.strategyRelatedOptions,
      investMeans: data?.investMeans,
      investInstruments: data?.investInstruments,
      investTerm: data?.investTerm,
      strategies: data?.strategies || [],
    };
    const businessKey = localStorage.getItem(LocalStorageKeysEnum.onboardingBusinessKey);
    if (!!businessKey) {
      return {
        businessKey: businessKey,
      };
    } else {
      const res = await post("@auth/executor/v1/purchasing/am/short", { data: params }).catch(() => {
        store.dispatch(
          appMessageAction({
            message: t("ERRORS.short_AM"),
            options: { variant: "error" },
          })
        );
        history.push(links.operations);
      });
      return res.data;
    }
  },
};
