import { createAsyncReducer } from "~/lib/redux";
import { success } from "~/lib/redux/async";

import {
  anketaGetEconomySectionStartAction,
  anketaGetEconomySectionSuccessAction,
  anketaGetEconomySectionErrorAction,
  anketaSaveEconomySectionSuccessAction,
  anketaUpdateEconomySectionSuccessAction,
} from "../actions";

export const STATE_NAME = "economySection";

const [reducer, defaultState] = createAsyncReducer(
  anketaGetEconomySectionStartAction,
  anketaGetEconomySectionSuccessAction,
  anketaGetEconomySectionErrorAction
);

const successReducer = (_: unknown, payload: any): AnketaEconomySectionState => success<any>(payload);

reducer.on(anketaSaveEconomySectionSuccessAction, successReducer);
reducer.on(anketaUpdateEconomySectionSuccessAction, successReducer);

export type AnketaEconomySectionState = typeof defaultState;

export default reducer;
