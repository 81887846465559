export enum SmsSignStateType {
  None = "None",
  Checking = "Checking",
  Resending = "Resending",
  Signing = "Signing",
}

export enum SmsSignErrorReasonType {
  Unknown = "Unknown",
  Incorrect = "Incorrect",
  Expired = "Expired",
}

export interface SmsSign {
  timestamp: number;
  success?: boolean;
  reason?: SmsSignErrorReasonType;
}
