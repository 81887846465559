import React, { MouseEvent, useCallback, useState } from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import { CircularProgress, Menu, MenuItem } from "@mui/material";
import cn from "classnames";
import { useStore } from "effector-react";

import { useUserDropdownStyles } from "~/app/layouts/BaseLayout/components/ViewportHeader/components/UserDropdownButton/styles";
import { links } from "~/constants/enums";
import { useTranslation } from "~/i18n";
import { useRouterHooks } from "~/lib/router";
import { currentClientModel } from "~/modules/current-client-model";
import { headerModel } from "~/modules/header-model";
import { logoutModel } from "~/modules/keycloak/logout-model";
import { BaseButton } from "~/shared/ui-kit/BaseComponents";
import colorTable from "~/shared/ui-kit/constants/colors.ts";
import Icon from "~/shared/Icon";
import { notificationService } from "~/modules/notifications";

const IS_NEW_HEADER = import.meta.env.VITE_NEW_HEADER_HIDDEN === "false";

const UserDropdownButton = () => {
  const { navigate } = useRouterHooks();
  const { classes } = useUserDropdownStyles();
  const { $currentClient, $getCurrentClientPending } = currentClientModel;
  const currentClient = useStore($currentClient);
  const getCurrentClientPending = useStore($getCurrentClientPending);
  const { $headerTheme } = headerModel;
  const headerTheme = useStore($headerTheme);

  const clientName = `${currentClient?.fullName.split(" ")[0]} ${
    currentClient?.fullName.split(" ")[1] ? currentClient?.fullName.split(" ")[1][0] + "." : ""
  }`;

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>): void => {
      setAnchorEl(event.currentTarget);
      setOpenMenu((prev) => !prev);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback((): void => {
    setAnchorEl(null);
    setOpenMenu((prev) => !prev);
  }, [setAnchorEl]);

  const handleShowProfile = useCallback((): void => {
    setAnchorEl(null);
    navigate(links.profile);
    setOpenMenu((prev) => !prev);
  }, [setAnchorEl, navigate]);

  const onLogout = () => {
    sessionStorage.removeItem("redirectPath");
    notificationService.removePushTokenEv();
    logoutModel.logout();
    setOpenMenu((prev) => !prev);
  };

  if (getCurrentClientPending) {
    return <CircularProgress size={20} thickness={5} />;
  }

  return (
    <>
      <BaseButton
        className={cn(classes.btnClient, classes.newBtnClient)}
        typeStyle="inline"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {IS_NEW_HEADER ? (
          <Icon
            name="profile"
            color={
              Boolean(anchorEl)
                ? colorTable.nobleOchre
                : headerTheme.isDarkColorTheme
                  ? colorTable.white
                  : colorTable.anthraciteGray
            }
            className={classes.iconBtn}
            size={24}
            withBackground={{ height: 24 }}
          />
        ) : (
          <>
            <Icon className={classes.icon} name="profile" color={colorTable.lightGrey_40} size={18} />
            <div
              className={cn(
                classes.name,
                { [classes.active]: openMenu },
                { [classes.whiteText]: headerTheme.isDarkColorTheme }
              )}
            >
              {clientName}
            </div>
            <KeyboardArrowDown fontSize="small" className={cn(classes.arrow, { [classes.arrowRotate]: openMenu })} />
          </>
        )}
      </BaseButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: IS_NEW_HEADER ? 35 : "bottom",
          horizontal: IS_NEW_HEADER ? -100 : -110,
        }}
        disableScrollLock
        MenuListProps={{ className: classes.menu }}
        slotProps={{ paper: { className: classes.paper } }}
      >
        <MenuItem onClick={handleShowProfile} className={classes.menuItem}>
          <Icon name="profile" className={classes.iconBox} size={24} withBackground={{ height: 24 }} />
          {t("MENU.USER.profile")}
        </MenuItem>

        <MenuItem onClick={onLogout} className={classes.menuItem}>
          <Icon
            name="mainExit"
            className={classes.iconBox}
            size={24}
            withBackground={{ height: 24 }}
            color={colorTable.textIconAccentRed}
          />
          {t("MENU.USER.exit")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserDropdownButton;
