import { createAsyncReducer } from "~/lib/redux";
import { success } from "~/lib/redux/async";
import {
  anketaCheckProfileExistenceErrorAction,
  anketaCheckProfileExistenceStartAction,
  anketaCheckProfileExistenceSuccessAction,
  anketaSaveBankSectionSuccessAction,
  anketaSaveCommonSectionSuccessAction,
  anketaSaveEconomySectionSuccessAction,
  anketaSaveInvestSectionSuccessAction,
} from "../actions";

export const STATE_NAME = "profileExistence";

const [reducer, defaultState] = createAsyncReducer(
  anketaCheckProfileExistenceStartAction,
  anketaCheckProfileExistenceSuccessAction,
  anketaCheckProfileExistenceErrorAction
);

export type AnketaProfileExistenceState = typeof defaultState;

reducer.on(
  anketaSaveCommonSectionSuccessAction,
  (state: any): AnketaProfileExistenceState =>
    state.data ? success<any>({ ...state.data, commonSectionCreated: true, profileExists: true }) : state
);

reducer.on(anketaSaveEconomySectionSuccessAction, (state: any): any =>
  state.data ? success<any>({ ...state.data, economSectionCreated: true, profileExists: true }) : state
);

reducer.on(anketaSaveBankSectionSuccessAction, (state: any): any =>
  state.data ? success<any>({ ...state.data, bankSectionCreated: true, profileExists: true }) : state
);

reducer.on(anketaSaveInvestSectionSuccessAction, (state: any): any =>
  state.data ? success<any>({ ...state.data, investSectionCreated: true, profileExists: true }) : state
);

export default reducer;
