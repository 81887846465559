import { DocumentCode } from "~/entities/anketa";
import { AppError } from "~/entities/app";
import { createAction, createAsyncActions } from "~/lib/redux";
import { BankSectionDto, BankSectionSaveRequest } from "~/entities/anketa/models/BankSectionDto";

export const [
  anketaCheckProfileExistenceStartAction,
  anketaCheckProfileExistenceSuccessAction,
  anketaCheckProfileExistenceErrorAction,
] = createAsyncActions<any>("ANKETA_CHECK_PROFILE_EXISTENCE");

export const [anketaGetProfileStartAction, anketaGetProfileSuccessAction, anketaGetProfileErrorAction] =
  createAsyncActions<any>("ANKETA_GET_PROFILE");

export const [
  anketaGetCommonSectionStartAction,
  anketaGetCommonSectionSuccessAction,
  anketaGetCommonSectionErrorAction,
] = createAsyncActions<any>("ANKETA_GET_COMMON_SECTION");

export const [anketaSaveCommonSectionStartAction, anketaSaveCommonSectionSuccessAction] = createAsyncActions<any, any>(
  "ANKETA_SAVE_COMMON_SECTION"
);

export const [anketaUpdateCommonSectionStartAction, anketaUpdateCommonSectionSuccessAction] = createAsyncActions<
  any,
  any
>("ANKETA_UPDATE_COMMON_SECTION");

export const [
  anketaGetEconomySectionStartAction,
  anketaGetEconomySectionSuccessAction,
  anketaGetEconomySectionErrorAction,
] = createAsyncActions<any>("ANKETA_GET_ECONOMY_SECTION");

export const [anketaSaveEconomySectionStartAction, anketaSaveEconomySectionSuccessAction] = createAsyncActions<
  any,
  any
>("ANKETA_SAVE_ECONOMY_SECTION");

export const [anketaUpdateEconomySectionStartAction, anketaUpdateEconomySectionSuccessAction] = createAsyncActions<
  any,
  any
>("ANKETA_UPDATE_ECONOMY_SECTION");

export const [
  anketaGetInvestSectionStartAction,
  anketaGetInvestSectionSuccessAction,
  anketaGetInvestSectionErrorAction,
] = createAsyncActions<any>("ANKETA_GET_INVEST_SECTION");

export const [anketaSaveInvestSectionStartAction, anketaSaveInvestSectionSuccessAction] = createAsyncActions<any, any>(
  "ANKETA_SAVE_INVEST_SECTION"
);

export const [anketaUpdateInvestSectionStartAction] = createAsyncActions<any, any>("ANKETA_UPDATE_INVEST_SECTION");

export const [anketaGetBankSectionStartAction, anketaGetBankSectionSuccessAction, anketaGetBankSectionErrorAction] =
  createAsyncActions<BankSectionDto>("ANKETA_GET_BANK_SECTION");

export const [anketaSaveBankSectionStartAction, anketaSaveBankSectionSuccessAction] = createAsyncActions<
  BankSectionDto,
  BankSectionSaveRequest
>("ANKETA_SAVE_BANK_SECTION");

export const [anketaUpdateBankSectionStartAction, anketaUpdateBankSectionSuccessAction] = createAsyncActions<
  BankSectionDto,
  BankSectionSaveRequest
>("ANKETA_UPDATE_BANK_SECTION");

export const [anketaGetRequiredDocsStartAction, anketaGetRequiredDocsSuccessAction, anketaGetRequiredDocsErrorAction] =
  createAsyncActions<any>("ANKETA_GET_REQUIRED_DOCS");

export const [anketaGetUploadedDocsStartAction, anketaGetUploadedDocsSuccessAction, anketaGetUploadedDocsErrorAction] =
  createAsyncActions<any>("ANKETA_GET_UPLOADED_DOCS");

export const anketaUploadDocStartAction = createAction<any>("ANKETA_UPLOAD_DOC/START");
export const anketaUploadDocSuccessAction = createAction<any, any>(
  "ANKETA_UPLOAD_DOC/SUCCESS",
  (payload: any): any => payload,
  (_: any, meta: any): any => meta
);
export const anketaUploadDocErrorAction = createAction<Error, any>(
  "ANKETA_UPLOAD_DOC/ERROR",
  (error: Error): Error => error,
  (_: Error, meta: any): any => meta
);

export const anketaDeleteDocStartAction = createAction<any>("ANKETA_DELETE_DOC/START");
export const anketaDeleteDocSuccessAction = createAction<undefined, any>(
  "ANKETA_DELETE_DOC/SUCCESS",
  (): any => undefined,
  (meta: any): any => meta
);
export const anketaDeleteDocErrorAction = createAction<Error, any>(
  "ANKETA_DELETE_DOC/ERROR",
  (error: Error): Error => error,
  (_: Error, meta: any): any => meta
);

export const [
  anketaDownloadUplodedFileStartAction,
  anketaDownloadUplodedFileSuccessAction,
  anketaDownloadUplodedFileErrorAction,
] = createAsyncActions<DocumentCode, string, AppError>("ANKETA_DOWNLOAD_UPLOADED_FILE");
