import { createDomain } from "effector";

import { TNotificationParams } from "./types";
import colorTable from "~/shared/ui-kit/constants/colors";

const domain = createDomain("notification modal");

const setShowNotificationModal = domain.createEvent<boolean>();
const setPendingNotificationModal = domain.createEvent<boolean>();
const setNotificationParams = domain.createEvent<TNotificationParams>();

const $showNotificationModal = domain.createStore<boolean>(false);
$showNotificationModal.on(setShowNotificationModal, (_, data) => data);

const $pendingNotificationModal = domain.createStore<boolean>(false);
$pendingNotificationModal.on(setPendingNotificationModal, (_, data) => data);

const $notificationParams = domain.createStore<TNotificationParams>({
  title: "",
  descriptions: [],
  buttons: [],
  icon: "timeCurrentIcon",
  iconColor: colorTable.charcoalBlack,
  iconBackground: null,
  maxWidthContent: null,
});
$notificationParams.on(setNotificationParams, (_, data) => data);

export const notificationModalModel = {
  $notificationParams,
  $showNotificationModal,
  $pendingNotificationModal,
  setShowNotificationModal,
  setNotificationParams,
  setPendingNotificationModal,
};
