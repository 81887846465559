import { makeStyles } from "tss-react/mui";

import { Theme } from "~/configs/theme";
import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    width: "100%",
    position: "relative",
    color: colorTable.white,
    overflow: "hidden",
    borderRadius: "16px",
    padding: "56px",
    marginBottom: "64px",
    [theme.breakpoints.down(992)]: {
      padding: "48px 40px",
    },
    [theme.breakpoints.down(768)]: {
      marginBottom: "40px",
    },
    [theme.breakpoints.down(576)]: {
      padding: "88px 16px 40px",
    },
  },
  img: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  textBox: {
    zIndex: 2,
    position: "relative",
    maxWidth: "578px",
    minHeight: "160px",
    [theme.breakpoints.down(576)]: {
      maxWidth: "100%",
    },
  },
  bannerTitle: {
    color: colorTable.white,
    fontSize: "32px",
    lineHeight: "40px",
    fontWeight: 600,
    marginBottom: "16px",
    [theme.breakpoints.down(992)]: {
      fontSize: "28px",
    },
    [theme.breakpoints.down(576)]: {
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
  bannerText: {
    color: colorTable.white,
    fontSize: "16px",
    lineHeight: "24px",
    opacity: 0.7,
  },
  bannerBtn: {
    background: colorTable.white,
    color: colorTable.darkGrey_100,
    marginTop: "32px",
    minWidth: 135,
    [theme.breakpoints.down(576)]: {
      height: "48px",
      width: "100%",
    },
    "&:hover": {
      background: colorTable.transparent,
      border: `1px solid ${borderColors.white}`,
      color: colorTable.white,
    },
  },
}));
