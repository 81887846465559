import { makeStyles } from "tss-react/mui";
import colorTable from "~/shared/ui-kit/constants/colors.ts";

export const useStyles = makeStyles()({
  container: {
    cursor: "pointer",
    padding: 16,
    width: "100%",
    borderRadius: "16px",
    backgroundColor: colorTable.white_20,
  },
  titleWrap: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    marginBottom: "8px",
  },
  dot: {
    minWidth: 6,
    minHeight: 6,
    width: 6,
    height: 6,
    borderRadius: "50%",
    backgroundColor: colorTable.red,
  },
  date: {
    width: "100%",
    textAlign: "right",
    marginTop: "16px",
  },
});
