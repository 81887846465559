import { makeStyles } from "tss-react/mui";

import { themeV5 } from "~/configs/theme";
import colorTable from "~/shared/ui-kit/constants/colors";
import { BaseModalProps } from "./BaseModal";

export const useStyles = makeStyles<Record<"maxWidthContent", BaseModalProps["maxWidthContent"]>>()(
  (_, { maxWidthContent }) => ({
    wrapperContent: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      maxWidth: "480px",
      gap: "8px",
      textAlign: "center",
      [themeV5.breakpoints.down(992)]: {
        padding: "0 16px",
      },
      [themeV5.breakpoints.down(768)]: {
        width: "100%",
        margin: "50px 0 40px",
      },
    },
    maxWidthContent: { maxWidth: maxWidthContent ?? "480px" },
  })
);

export const useDialogStyles = makeStyles()(() => ({
  paper: {
    overflow: "initial",
    borderRadius: "24px",
    alignItems: "center",
    maxWidth: 970,
    width: "100%",
    maxHeight: "550px",
    height: "100%",
    [themeV5.breakpoints.down(992)]: {
      maxWidth: 768,
    },
    [themeV5.breakpoints.down(768)]: {
      maxHeight: "max-content",
      height: "auto",
      borderRadius: "12px 12px 0px 0px",
    },
  },
}));

export const useIconButtonStyles = makeStyles()(() => ({
  root: {
    position: "absolute",
    cursor: "pointer",
    top: 0,
    right: -60,
    color: colorTable.white,
    [themeV5.breakpoints.down(992)]: {
      right: -40,
    },
  },
  mobileButton: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "50px",
    display: "block",
    backgroundColor: colorTable.transparent,
  },
  mobileButtonLine: {
    "&:before": {
      content: "''",
      position: "absolute",
      left: "50%",
      top: "30%",
      transform: "translate(-50%, -50%)",
      width: "50px",
      height: "5px",
      borderRadius: "94px",
      background: colorTable.lightGrey_55,
    },
  },
}));
