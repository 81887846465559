import { makeStyles } from "tss-react/mui";

import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  title: {
    color: colorTable.charcoalBlack,
  },
  estWrap: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    padding: "24px 0",
    borderRadius: 8,
    backgroundColor: colorTable.white_20,
    overflow: "hidden",

    "& > p": {
      zIndex: 2,
      padding: "0 24px",
      color: colorTable.charcoalBlack,
    },
    "& > span": {
      zIndex: 2,
      padding: "0 24px",
      color: colorTable.textSecondary,
    },
  },
  filledBg: {
    position: "absolute",
    left: 0,
    height: "72px",
    top: 0,
    backgroundColor: colorTable.white_85,
  },
  sell: {
    "& > div": {
      backgroundColor: colorTable.redLight,
    },
    "& > p": {
      color: colorTable.red,
    },
  },
  buy: {
    "& > div": {
      backgroundColor: colorTable.lightGreen,
    },
    "& > p": {
      color: colorTable.green_50,
    },
  },
  hold: {
    "& > div": {
      backgroundColor: colorTable.white_85,
    },
  },
});
