import React, { forwardRef, memo, Ref, RefForwardingComponent, useEffect, useRef, useState } from "react";
import { IconButton, InputAdornment, Tooltip } from "@mui/material";
import TextFieldBase, { TextFieldProps as TextFieldBaseProps } from "@mui/material/TextField";
import cn from "classnames";
import { omit } from "lodash";

import { defaultInputPattern } from "~/constants/regexp";
import InputLoader from "~/features/InputLoader";
import { useStyles } from "./styles";
import Icon from "~/shared/Icon";

export type TextFieldProps = Omit<TextFieldBaseProps, "variant"> & {
  asyncState?: "pending" | "error";
  currentRef?: Ref<HTMLDivElement>;
  isNotLatin?: boolean;
  clearInput?: boolean;
  clearCallBack?: any | (() => any);
  securityAnswerInput?: boolean;
  defaultVisibleValue?: boolean;
};

type OnChangeProps = Omit<TextFieldBaseProps, "variant"> & {
  onChange?: any | (() => any);
};

type TextFieldAsyncProps = TextFieldProps & OnChangeProps;

const TextFieldAsync = ({
  asyncState,
  children,
  select,
  currentRef,
  isNotLatin,
  onChange,
  clearInput,
  clearCallBack,
  securityAnswerInput,
  ...props
}: TextFieldAsyncProps) => {
  const { classes } = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const checkOverflow = () => {
    const input = inputRef.current;
    if (input) {
      const scrollWidth = !!props?.InputProps?.endAdornment ? input.scrollWidth - 1 : input.scrollWidth;

      setIsOverflowing(scrollWidth > input.offsetWidth);
    }
  };

  useEffect(() => {
    checkOverflow();
  }, []);

  const handleMouseEnter = () => {
    checkOverflow();
    if (isOverflowing && props.disabled) {
      setTooltipOpen(true);
    }
  };

  const handleMouseLeave = () => {
    setTooltipOpen(false);
  };

  const handleTouchStart = () => {
    checkOverflow();
    if (isOverflowing && props.disabled) {
      setTooltipOpen(true);
    }
  };

  const handleTouchEnd = () => {
    setTimeout(() => setTooltipOpen(false), 2000); // Keep tooltip open for 2 seconds after touch ends
  };

  if (asyncState === "error") {
    return (
      <TextFieldBase
        {...props}
        onChange={(e) => {
          if (!isNotLatin && !defaultInputPattern.test(e.target.value) && !!e.target.value) {
            return;
          }
          onChange(e);
        }}
        className={cn(classes.textField, props.className)}
        InputLabelProps={{
          className: classes.label,
          shrink: false,
        }}
        FormHelperTextProps={{
          className: classes.helperText,
        }}
        variant="outlined"
        InputProps={{
          inputProps: {
            className: classes.input,
          },
          className: classes.inputWrapper,
        }}
        ref={currentRef}
        select={select}
        disabled
        error
      >
        {select ? "..." : children}
      </TextFieldBase>
    );
  }

  if (clearInput) {
    return (
      <TextField
        onChange={(e: { target: { value: string } }) => {
          if (!isNotLatin && !defaultInputPattern.test(e.target.value) && !!e.target.value) {
            return;
          }
          onChange(e);
        }}
        {...props}
        className={cn(classes.textField, props.className)}
        InputLabelProps={{
          className: classes.label,
          shrink: false,
        }}
        FormHelperTextProps={{
          className: classes.helperText,
        }}
        InputProps={{
          inputProps: {
            className: classes.input,
          },
          className: classes.inputWrapper,
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton className={classes.endBtn} edge="end" onClick={(): void => clearCallBack()}>
                {props.value ? <Icon name="Clear" className={classes.endIcon} /> : ""}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }

  if (securityAnswerInput) {
    return (
      <TextFieldBase
        onChange={(e) => {
          if (!isNotLatin && !defaultInputPattern.test(e.target.value) && !!e.target.value) {
            return;
          }
          onChange(e);
        }}
        {...props}
        className={cn(classes.textField, props.className)}
        InputLabelProps={{
          className: classes.label,
          shrink: false,
        }}
        FormHelperTextProps={{
          className: classes.helperText,
        }}
        variant="outlined"
        InputProps={{
          inputProps: {
            className: classes.input,
          },
          className: classes.inputWrapper,
        }}
        ref={currentRef}
        select={select}
      >
        {children}
      </TextFieldBase>
    );
  }

  if (asyncState === "pending") {
    const { label } = props;
    return (
      <TextFieldBase
        {...props}
        className={cn(classes.textField, props.className)}
        InputLabelProps={{
          className: classes.label,
          shrink: false,
        }}
        FormHelperTextProps={{
          className: classes.helperText,
        }}
        variant="outlined"
        InputProps={{
          inputProps: {
            className: classes.input,
          },
          className: classes.inputWrapper,
        }}
        ref={currentRef}
        select={select}
        onChange={(e) => {
          if (!isNotLatin && !defaultInputPattern.test(e.target.value) && !!e.target.value) {
            return;
          }
          onChange(e);
        }}
        label={
          <>
            <InputLoader />
            {label}
          </>
        }
        disabled
      >
        {select ? "..." : children}
      </TextFieldBase>
    );
  }

  const propsOmit = omit(props, ["style"]);

  return (
    <Tooltip
      title={isOverflowing && props.disabled ? (props.value ?? "") : ""}
      open={tooltipOpen}
      disableHoverListener
      disableTouchListener
      disableFocusListener
      style={props.style}
    >
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <TextFieldBase
          onChange={(e) => {
            if (!isNotLatin && !defaultInputPattern.test(e.target.value) && !!e.target.value) {
              return;
            }
            onChange(e);
          }}
          {...propsOmit}
          className={cn(classes.textField, props.className)}
          InputLabelProps={{
            className: classes.label,
            shrink: false,
          }}
          FormHelperTextProps={{
            ...props.FormHelperTextProps,
            className: classes.helperText,
          }}
          variant="outlined"
          InputProps={{
            inputProps: {
              className: classes.input,
              ref: inputRef,
            },
            className: classes.inputWrapper,
            ...props.InputProps,
          }}
          ref={currentRef}
          select={select}
        >
          {children}
        </TextFieldBase>
      </div>
    </Tooltip>
  );
};

const TextFieldForwarded: RefForwardingComponent<HTMLDivElement, TextFieldProps & OnChangeProps> = (props, ref) => {
  return <TextFieldAsync currentRef={ref} {...props} />;
};

const TextField = forwardRef(TextFieldForwarded);

export default TextField;

export const TextFieldPure = memo(TextField);
