import React, { forwardRef } from "react";
import { Box } from "@mui/material";
import { BaseTypography } from "~/shared/ui-kit/BaseComponents";
import colorTable from "~/shared/ui-kit/constants/colors.ts";
import { useStyles } from "./styles.ts";
import moment from "moment/moment";
import { notificationService } from "~/modules/notifications";
import { useUnit } from "effector-react";

interface IProps {
  id: string;
  title: string | null;
  description: string;
  date: string | null;
  isRead: boolean;
}

export const NotificationItem = forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const { title, isRead, description, date, id } = props as any;

  const { readPushMessageEv, $readAllMessagesFxPending, $readPushByIdFxPending } = notificationService;

  const readAllMessagesFxPending = useUnit($readAllMessagesFxPending);
  const readPushByIdFxPending = useUnit($readPushByIdFxPending);

  const { classes } = useStyles();

  return (
    <Box
      ref={ref}
      className={classes.container}
      onClick={() => {
        if (!isRead && !readAllMessagesFxPending && !readPushByIdFxPending) {
          readPushMessageEv(id);
        }
      }}
    >
      <Box className={classes.titleWrap}>
        {!isRead && <Box className={classes.dot} />}
        {title && (
          <BaseTypography color={colorTable.charcoalBlack} fontType="text_semiBold">
            {title}
          </BaseTypography>
        )}
      </Box>
      <BaseTypography color={colorTable.textSecondary} fontType="subhead">
        {description}
      </BaseTypography>
      {date && (
        <BaseTypography color={colorTable.moonGrey} fontType="caption" className={classes.date}>
          {moment.utc(date).local().format("HH:mm")}
        </BaseTypography>
      )}
    </Box>
  );
});
