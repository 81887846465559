import { createDomain } from "effector";
import { createGate } from "effector-react";

const viewportFixedButtonDomain = createDomain("viewport fixed button domain");
const gate = createGate({ domain: viewportFixedButtonDomain });

const setVisibleViewportFixedButton = viewportFixedButtonDomain.createEvent<boolean>();
const setNameViewportFixedButton = viewportFixedButtonDomain.createEvent<string>();
const setCallbackViewportFixedButton = viewportFixedButtonDomain.createEvent<() => void>();
const setTextViewportFixedButton = viewportFixedButtonDomain.createEvent<string>();
const setIsDisabled = viewportFixedButtonDomain.createEvent<boolean>();
const setContentWrapperClassname = viewportFixedButtonDomain.createEvent<string>();

const $isVisibleViewportFixedButton = viewportFixedButtonDomain.createStore(false);
$isVisibleViewportFixedButton.on(setVisibleViewportFixedButton, (_, data) => data);
$isVisibleViewportFixedButton.reset(gate.close);

const $nameViewportFixedButton = viewportFixedButtonDomain.createStore("");
$nameViewportFixedButton.on(setNameViewportFixedButton, (_, data) => data);
$nameViewportFixedButton.reset(gate.close);

const $contentWrapperClassname = viewportFixedButtonDomain.createStore("");
$contentWrapperClassname.on(setContentWrapperClassname, (_, data) => data);
$contentWrapperClassname.reset(gate.close);

const $callbackViewportFixedButton = viewportFixedButtonDomain.createStore(() => {});
$callbackViewportFixedButton.on(setCallbackViewportFixedButton, (_, data) => data);
$callbackViewportFixedButton.reset(gate.close);

const $textViewportFixedButton = viewportFixedButtonDomain.createStore("");
$textViewportFixedButton.on(setTextViewportFixedButton, (_, data) => data);
$textViewportFixedButton.reset(gate.close);

const $isDisabled = viewportFixedButtonDomain.createStore(false);
$isDisabled.on(setIsDisabled, (_, data) => data);
$isDisabled.reset(gate.close);

const $btnClassName = viewportFixedButtonDomain.createStore("");
const setBtnClassName = viewportFixedButtonDomain.createEvent<string>();
$btnClassName.on(setBtnClassName, (_, data) => data);
$btnClassName.reset(gate.close);

// вторая кнопка
const $hasSecondButton = viewportFixedButtonDomain.createStore<boolean>(false);
const setHasSecondButton = viewportFixedButtonDomain.createEvent<boolean>();
$hasSecondButton.on(setHasSecondButton, (_, data) => data);
$hasSecondButton.reset(gate.close);

const setNameSecondFixedButton = viewportFixedButtonDomain.createEvent<string>();
const setCallbackSecondFixedButton = viewportFixedButtonDomain.createEvent<() => void>();
const setIsDisabledSecondBtn = viewportFixedButtonDomain.createEvent<boolean>();

const $nameSecondFixedButton = viewportFixedButtonDomain.createStore("");
$nameSecondFixedButton.on(setNameSecondFixedButton, (_, data) => data);
$nameSecondFixedButton.reset(gate.close);

const $callbackSecondFixedButton = viewportFixedButtonDomain.createStore(() => {});
$callbackSecondFixedButton.on(setCallbackSecondFixedButton, (_, data) => data);
$callbackSecondFixedButton.reset(gate.close);

const $isDisabledSecond = viewportFixedButtonDomain.createStore(false);
$isDisabledSecond.on(setIsDisabledSecondBtn, (_, data) => data);
$isDisabledSecond.reset(gate.close);

const $btnSecondClassName = viewportFixedButtonDomain.createStore("");
const setBtnSecondClassName = viewportFixedButtonDomain.createEvent<string>();
$btnSecondClassName.on(setBtnSecondClassName, (_, data) => data);
$btnSecondClassName.reset(gate.close);

const $wrapperButtonsClassName = viewportFixedButtonDomain.createStore("");
const setWrapperButtonsClassName = viewportFixedButtonDomain.createEvent<string>();
$wrapperButtonsClassName.on(setWrapperButtonsClassName, (_, data) => data);
$wrapperButtonsClassName.reset(gate.close);

// баннер
const $hasBanner = viewportFixedButtonDomain.createStore<boolean>(false);
const setHasBanner = viewportFixedButtonDomain.createEvent<boolean>();
$hasBanner.on(setHasBanner, (_, data) => data);
$hasBanner.reset(gate.close);

const $bannerText = viewportFixedButtonDomain.createStore({
  title: "",
  subtitle: "",
});
const setBannerText = viewportFixedButtonDomain.createEvent<{ title?: string; subtitle?: string }>();
$bannerText.on(setBannerText, (state, data) => ({ ...state, ...data }));
$bannerText.reset(gate.close);

const $bannerClassName = viewportFixedButtonDomain.createStore("");
const setBannerClassName = viewportFixedButtonDomain.createEvent<string>();
$bannerClassName.on(setBannerClassName, (_, data) => data);
$bannerClassName.reset(gate.close);

const $bannerCallback = viewportFixedButtonDomain.createStore(() => {});
const setBannerCallback = viewportFixedButtonDomain.createEvent<() => void>();
$bannerCallback.on(setBannerCallback, (_, data) => data);
$bannerCallback.reset(gate.close);

export const viewportFixedButtonService = {
  gate,
  setVisibleViewportFixedButton,
  setNameViewportFixedButton,
  setCallbackViewportFixedButton,
  setTextViewportFixedButton,
  setIsDisabled,
  $isVisibleViewportFixedButton,
  $nameViewportFixedButton,
  $callbackViewportFixedButton,
  $textViewportFixedButton,
  $isDisabled,
  $hasSecondButton,
  setHasSecondButton,
  setNameSecondFixedButton,
  setCallbackSecondFixedButton,
  setIsDisabledSecondBtn,
  $nameSecondFixedButton,
  $callbackSecondFixedButton,
  $isDisabledSecond,
  $btnClassName,
  setBtnClassName,
  $btnSecondClassName,
  setBtnSecondClassName,
  setContentWrapperClassname,
  $contentWrapperClassname,
  $hasBanner,
  setHasBanner,
  $bannerText,
  setBannerText,
  $bannerClassName,
  setBannerClassName,
  $bannerCallback,
  setBannerCallback,
  $wrapperButtonsClassName,
  setWrapperButtonsClassName,
};
