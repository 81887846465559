import { makeStyles } from "tss-react/mui";
import colorTable from "~/shared/ui-kit/constants/colors.ts";
import theme from "~/configs/theme.ts";

export const useStyles = makeStyles()({
  container: {
    position: "relative",
    "> div > div:has(> svg)": {
      cursor: "pointer",

      "&:hover > svg > path": {
        stroke: colorTable.nobleOchre,
      },
    },
  },
  countNotRead: {
    position: "absolute",
    top: -5,
    right: -2,
    borderRadius: "50%",
    backgroundColor: colorTable.red,
    width: 14,
    height: 14,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    fontSize: 10,
    lineHeight: "14px",
    color: colorTable.white,
    cursor: "default",
  },
  countNotReadWhite: {
    borderRadius: "50%",
    backgroundColor: colorTable.white,
    width: 16,
    height: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 10,
    lineHeight: "16px",
    color: colorTable.charcoalBlack,
    cursor: "pointer",
  },
  notificationEmpty: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "40px 0",
    marginRight: 24,
    "& > div": {
      marginBottom: "24px",
    },
    "& p:first-of-type": {
      marginBottom: "8px",
    },
    [theme.breakpoints.down(768)]: {
      position: "relative",
      top: "25%",
    },
  },
  notificationContainer: {
    position: "absolute",
    top: 48,
    left: "-211px",
    width: "423px",
    background: colorTable.white,
    borderRadius: "16px",
    padding: "24px",
    paddingRight: 0,
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.08), 0 1px 1px 0 rgba(0, 0, 0, 0.04)",
    [theme.breakpoints.down(768)]: {
      position: "fixed",
      left: "initial",
      right: 0,
      top: 48,
      width: "100vw",
      height: "calc(100vh - 48px)",
      boxShadow: "none",
      overflow: "hidden",
    },
  },
  headWrap: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 24,
    paddingRight: 24,
  },
  btnReadAll: {
    margin: "24px 24px 0 0!important",
    width: "calc(100% - 24px)",
  },
  contentWrap: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
    paddingRight: 12,
    marginRight: 12,
    maxHeight: "50vh",
    overflow: "auto",
    [theme.breakpoints.down(768)]: {
      maxHeight: "calc(100% - 130px)",
    },
    "&::-webkit-scrollbar": {
      width: "4px",
      backgroundColor: colorTable.white_20,
      borderRadius: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: colorTable.nobleOchre,
      borderRadius: "8px",
    },
  },
  notificationsWrap: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
  },
  dateWithContent: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  errorContainer: {
    marginRight: "24px!important",

    [theme.breakpoints.down(768)]: {
      position: "relative",
      top: "25%",
    },
  },
  loaderWrap: {
    paddingRight: 24,

    [theme.breakpoints.down(768)]: {
      paddingRight: 0,
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
});
