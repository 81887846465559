import { createAsyncReducer } from "~/lib/redux";
import { success } from "~/lib/redux/async";

import {
  anketaGetBankSectionStartAction,
  anketaGetBankSectionSuccessAction,
  anketaGetBankSectionErrorAction,
  anketaSaveBankSectionSuccessAction,
  anketaUpdateBankSectionSuccessAction,
} from "../actions";

export const STATE_NAME = "bankSection";

const [reducer, defaultState] = createAsyncReducer(
  anketaGetBankSectionStartAction,
  anketaGetBankSectionSuccessAction,
  // @ts-ignore vite
  anketaGetBankSectionErrorAction
);

const successReducer = (_: unknown, payload: any): AnketaBankSectionState => success<any>(payload);

reducer.on(anketaSaveBankSectionSuccessAction, successReducer);
reducer.on(anketaUpdateBankSectionSuccessAction, successReducer);

export type AnketaBankSectionState = typeof defaultState;

export default reducer;
