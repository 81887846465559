import { makeStyles } from "tss-react/mui";

import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    backgroundColor: colorTable.white_20,
    borderRadius: 16,
    padding: "24px",
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& > p": {
      color: colorTable.charcoalBlack,
    },
  },
  rating: {
    height: 24,
    width: 24,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: colorTable.white,
  },
  greenRating: {
    backgroundColor: colorTable.green_50,
  },
  orangeRating: {
    backgroundColor: colorTable.orange,
  },
  redRating: {
    backgroundColor: colorTable.red,
  },
  description: {
    color: colorTable.textSecondary,
  },
});
