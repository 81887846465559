/**
 * LR parser generated by the Syntax tool.
 *
 * https://www.npmjs.com/package/syntax-cli
 *
 *   npm install -g syntax-cli
 *
 *   syntax-cli --help
 *
 * To regenerate run:
 *
 *   syntax-cli \
 *     --grammar ~/path-to-grammar-file \
 *     --mode <parsing-mode> \
 *     --output ~/path-to-output-parser-file.js
 */
"use strict";
/**
 * Matched token text.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let yytext;
/**
 * Length of the matched token text.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let yyleng;
/**
 * Storage object.
 */
let yy = {};
/**
 * Result of semantic action.
 */
let __;
/**
 * Result location object.
 */
let __loc;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function yyloc(start, end) {
  if (!yy.options.captureLocations) {
    return null;
  }
  // Epsilon doesn't produce location.
  if (!start || !end) {
    return start || end;
  }
  return {
    startOffset: start.startOffset,
    endOffset: end.endOffset,
    startLine: start.startLine,
    endLine: end.endLine,
    startColumn: start.startColumn,
    endColumn: end.endColumn,
  };
}

let EOF = "$";
/**
 * List of productions (generated by Syntax tool).
 */
let productions = [
  [
    -1,
    1,
    function (_1) {
      __ = _1;
    },
  ],
  [
    0,
    1,
    function (_1) {
      __ = _1;
    },
  ],
  [
    0,
    2,
    function (_1, _2) {
      __ = _1 + " " + _2;
    },
  ],
  [
    1,
    4,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function (_1, _2, _3, _4) {
      __ = new Array(_3).fill(9).join("");
    },
  ],
  [
    1,
    1,
    function (_1) {
      __ = _1;
    },
  ],
  [
    2,
    1,
    function (_1) {
      __ = Number(_1);
    },
  ],
];
/**
 * Encoded tokens map.
 */
let tokens = { NUMBER: "3", "'['": "4", "']'": "5", $: "6" };
/**
 * Parsing table (generated by Syntax tool).
 */
let table = [
  { 0: 1, 1: 2, 2: 3, 3: "s4" },
  { 1: 5, 2: 3, 3: "s4", 6: "acc" },
  { 3: "r1", 6: "r1" },
  { 3: "r4", 4: "s6", 6: "r4" },
  { 3: "r5", 4: "r5", 5: "r5", 6: "r5" },
  { 3: "r2", 6: "r2" },
  { 2: 7, 3: "s4" },
  { 5: "s8" },
  { 3: "r3", 6: "r3" },
];
/**
 * Parsing stack.
 */
let stack = [];
/**
 * Tokenizer instance.
 */
let tokenizer;
/**
 * Generic tokenizer used by the parser in the Syntax tool.
 *
 * https://www.npmjs.com/package/syntax-cli
 *
 * See `--custom-tokinzer` to skip this generation, and use a custom one.
 */
let lexRules = [
  [
    /^\[/,
    function () {
      return "'['";
    },
  ],
  [
    /^\]/,
    function () {
      return "']'";
    },
  ],
  [/^\s+/, function () {}],
  [
    /^\d+/,
    function () {
      return "NUMBER";
    },
  ],
];
let lexRulesByConditions = { INITIAL: [0, 1, 2, 3] };
let EOF_TOKEN = {
  type: EOF,
  value: "",
};
tokenizer = {
  initString: function (string) {
    this._string = string;
    this._cursor = 0;
    this._states = ["INITIAL"];
    this._tokensQueue = [];
    this._currentLine = 1;
    this._currentColumn = 0;
    this._currentLineBeginOffset = 0;
    /**
     * Matched token location data.
     */
    this._tokenStartOffset = 0;
    this._tokenEndOffset = 0;
    this._tokenStartLine = 1;
    this._tokenEndLine = 1;
    this._tokenStartColumn = 0;
    this._tokenEndColumn = 0;
    return this;
  },
  /**
   * Returns tokenizer states.
   */
  getStates: function () {
    return this._states;
  },
  getCurrentState: function () {
    return this._states[this._states.length - 1];
  },
  pushState: function (state) {
    this._states.push(state);
  },
  begin: function (state) {
    this.pushState(state);
  },
  popState: function () {
    if (this._states.length > 1) {
      return this._states.pop();
    }
    return this._states[0];
  },
  getNextToken: function () {
    let _a;
    // Something was queued, return it.
    if (this._tokensQueue.length > 0) {
      return this.onToken(this._toToken(this._tokensQueue.shift()));
    }
    if (!this.hasMoreTokens()) {
      return this.onToken(EOF_TOKEN);
    }
    let string = this._string.slice(this._cursor);
    let lexRulesForState = lexRulesByConditions[this.getCurrentState()];
    for (let i = 0; i < lexRulesForState.length; i++) {
      let lexRuleIndex = lexRulesForState[i];
      let lexRule = lexRules[lexRuleIndex];
      let matched = this._match(string, lexRule[0]);
      // Manual handling of EOF token (the end of string). Return it
      // as `EOF` symbol.
      if (string === "" && matched === "") {
        this._cursor++;
      }
      if (matched !== null) {
        yytext = matched;
        yyleng = yytext.length;
        let token = lexRule[1].call(this);
        if (!token) {
          return this.getNextToken();
        }
        // If multiple tokens are returned, save them to return
        // on next `getNextToken` call.
        if (Array.isArray(token)) {
          let tokensToQueue = token.slice(1);
          token = token[0];
          if (tokensToQueue.length > 0) {
            (_a = this._tokensQueue).unshift.apply(_a, tokensToQueue);
          }
        }
        return this.onToken(this._toToken(token, yytext));
      }
    }
    if (this.isEOF()) {
      this._cursor++;
      return EOF_TOKEN;
    }
    this.throwUnexpectedToken(string[0], this._currentLine, this._currentColumn);
  },
  /**
   * Throws default "Unexpected token" exception, showing the actual
   * line from the source, pointing with the ^ marker to the bad token.
   * In addition, shows `line:column` location.
   */
  throwUnexpectedToken: function (symbol, line, column) {
    let lineSource = this._string.split("\n")[line - 1];
    let lineData = "";
    if (lineSource) {
      let pad = " ".repeat(column);
      lineData = "\n\n" + lineSource + "\n" + pad + "^\n";
    }
    throw new SyntaxError(
      "".concat(lineData, 'Unexpected token: "').concat(symbol, '" ') + "at ".concat(line, ":").concat(column, ".")
    );
  },
  getCursor: function () {
    return this._cursor;
  },
  getCurrentLine: function () {
    return this._currentLine;
  },
  getCurrentColumn: function () {
    return this._currentColumn;
  },
  _captureLocation: function (matched) {
    let nlRe = /\n/g;
    // Absolute offsets.
    this._tokenStartOffset = this._cursor;
    // Line-based locations, start.
    this._tokenStartLine = this._currentLine;
    this._tokenStartColumn = this._tokenStartOffset - this._currentLineBeginOffset;
    // Extract `\n` in the matched token.
    let nlMatch;
    while ((nlMatch = nlRe.exec(matched)) !== null) {
      this._currentLine++;
      this._currentLineBeginOffset = this._tokenStartOffset + nlMatch.index + 1;
    }
    this._tokenEndOffset = this._cursor + matched.length;
    // Line-based locations, end.
    this._tokenEndLine = this._currentLine;
    this._tokenEndColumn = this._currentColumn = this._tokenEndOffset - this._currentLineBeginOffset;
  },
  _toToken: function (tokenType, yytext) {
    if (yytext === void 0) {
      yytext = "";
    }
    return {
      // Basic data.
      type: tokenType,
      value: yytext,
      // Location data.
      startOffset: this._tokenStartOffset,
      endOffset: this._tokenEndOffset,
      startLine: this._tokenStartLine,
      endLine: this._tokenEndLine,
      startColumn: this._tokenStartColumn,
      endColumn: this._tokenEndColumn,
    };
  },
  isEOF: function () {
    return this._cursor === this._string.length;
  },
  hasMoreTokens: function () {
    return this._cursor <= this._string.length;
  },
  _match: function (string, regexp) {
    let matched = string.match(regexp);
    if (matched) {
      // Handle `\n` in the matched token to track line numbers.
      this._captureLocation(matched[0]);
      this._cursor += matched[0].length;
      return matched[0];
    }
    return null;
  },
  /**
   * Allows analyzing, and transforming token. Default implementation
   * just passes the token through.
   */
  onToken: function (token) {
    return token;
  },
};
/**
 * Expose tokenizer so it can be accessed in semantic actions.
 */
yy.lexer = tokenizer;
yy.tokenizer = tokenizer;
/**
 * Global parsing options. Some options can be shadowed per
 * each `parse` call, if the optations are passed.
 *
 * Initalized to the `captureLocations` which is passed
 * from the generator. Other options can be added at runtime.
 */
yy.options = {
  captureLocations: false,
};
/**
 * Parsing module.
 */
let yyparse = {
  /**
   * Sets global parsing options.
   */
  setOptions: function (options) {
    yy.options = options;
    return this;
  },
  /**
   * Returns parsing options.
   */
  getOptions: function () {
    return yy.options;
  },
  /**
   * Parses a string.
   */
  parse: function (string, parseOptions) {
    if (!tokenizer) {
      throw new Error("Tokenizer instance wasn't specified.");
    }
    tokenizer.initString(string);
    /**
     * If parse options are passed, override global parse options for
     * this call, and later restore global options.
     */
    let globalOptions = yy.options;
    if (parseOptions) {
      yy.options = Object.assign({}, yy.options, parseOptions);
    }
    /**
     * Allow callers to do setup work based on the
     * parsing string, and passed options.
     */
    yyparse.onParseBegin(string, tokenizer, yy.options);
    stack.length = 0;
    stack.push(0);
    let token = tokenizer.getNextToken();
    let shiftedToken = null;
    do {
      if (!token) {
        // Restore options.
        yy.options = globalOptions;
        unexpectedEndOfInput();
      }
      let state = stack[stack.length - 1];
      let column = tokens[token.type];
      // eslint-disable-next-line
      if (!table[state].hasOwnProperty(column)) {
        yy.options = globalOptions;
        unexpectedToken(token);
      }
      let entry = table[state][column];
      // Shift action.
      if (entry[0] === "s") {
        let loc = null;
        if (yy.options.captureLocations) {
          loc = {
            startOffset: token.startOffset,
            endOffset: token.endOffset,
            startLine: token.startLine,
            endLine: token.endLine,
            startColumn: token.startColumn,
            endColumn: token.endColumn,
          };
        }
        shiftedToken = this.onShift(token);
        stack.push(
          { symbol: tokens[shiftedToken.type], semanticValue: shiftedToken.value, loc: loc },
          Number(entry.slice(1))
        );
        token = tokenizer.getNextToken();
      }
      // Reduce action.
      else if (entry[0] === "r") {
        let productionNumber = entry.slice(1);
        let production = productions[productionNumber];
        let hasSemanticAction = typeof production[2] === "function";
        let semanticValueArgs = hasSemanticAction ? [] : null;
        let locationArgs = hasSemanticAction && yy.options.captureLocations ? [] : null;
        if (production[1] !== 0) {
          let rhsLength = production[1];
          while (rhsLength-- > 0) {
            stack.pop();
            let stackEntry = stack.pop();
            if (hasSemanticAction) {
              semanticValueArgs.unshift(stackEntry.semanticValue);
              if (locationArgs) {
                locationArgs.unshift(stackEntry.loc);
              }
            }
          }
        }
        let reduceStackEntry = { symbol: production[0] };
        if (hasSemanticAction) {
          yytext = shiftedToken ? shiftedToken.value : null;
          yyleng = shiftedToken ? shiftedToken.value.length : null;
          let semanticActionArgs = locationArgs !== null ? semanticValueArgs.concat(locationArgs) : semanticValueArgs;
          production[2].apply(production, semanticActionArgs);
          reduceStackEntry.semanticValue = __;
          if (locationArgs) {
            reduceStackEntry.loc = __loc;
          }
        }
        let nextState = stack[stack.length - 1];
        let symbolToReduceWith = production[0];
        stack.push(reduceStackEntry, table[nextState][symbolToReduceWith]);
      }
      // Accept.
      else if (entry === "acc") {
        stack.pop();
        let parsed = stack.pop();
        if (stack.length !== 1 || stack[0] !== 0 || tokenizer.hasMoreTokens()) {
          // Restore options.
          yy.options = globalOptions;
          unexpectedToken(token);
        }
        // eslint-disable-next-line
        if (parsed.hasOwnProperty("semanticValue")) {
          yy.options = globalOptions;
          yyparse.onParseEnd(parsed.semanticValue);
          return parsed.semanticValue;
        }
        yyparse.onParseEnd();
        // Restore options.
        yy.options = globalOptions;
        return true;
      }
    } while (tokenizer.hasMoreTokens() || stack.length > 1);
  },
  setTokenizer: function (customTokenizer) {
    tokenizer = customTokenizer;
    return yyparse;
  },
  getTokenizer: function () {
    return tokenizer;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onParseBegin: function (string, tokenizer, options) {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onParseEnd: function (parsed) {},
  /**
   * Allows analyzing, and transforming shifted token. Default implementation
   * just passes the token through.
   */
  onShift: function (token) {
    return token;
  },
};

function unexpectedToken(token) {
  if (token.type === EOF) {
    unexpectedEndOfInput();
  }
  tokenizer.throwUnexpectedToken(token.value, token.startLine, token.startColumn);
}

function unexpectedEndOfInput() {
  parseError("Unexpected end of input.");
}

function parseError(message) {
  throw new SyntaxError(message);
}

export default yyparse;
