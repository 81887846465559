import colorTable from "~/shared/ui-kit/constants/colors";

export const ICON_BACKGROUND_MOCK = {
  padding: "7px",
  borderRadius: "50%",
  backgroundColor: colorTable.lightGrey,
};

export const TITLE_MOCK = "Title";
export const NAME_MOCK = "Pavel";
export const ICON_NAME_SECTIONED_MOCK = "ICON_CHARTS";
export const SUBTITLE_MOCK = "Subtitle";
export const BUTTON_APPLY_MOCK = "Apply";
export const BUTTON_CANCEL_MOCK = "Cancel";
export const SUM_MOCK = "300000";
export const SUM_CURRENCY_MOCK = "3000%";
export const AMOUNT_PERCENT_MOCK = "25%";
export const DESCRIPTION_MOCK =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";
export const MONEY = "275 000 $";
export const MONEY_WITH_PERCENT = "-1 165 $ (5.07%)";
export const STATUS = "Pending";
export const FIELD_VALUE_MOCK = "field value";
export const INPUL_LABEL_MOCK = "Input label";
export const BUTTON_TEXT_MOCK = "Button text";

export const ID_MOCK = "123456";
export const TYPE_MOCK = "default";
export const LINK_MOCK = "www.link-mock.ru";
export const TIMER_MOCK = 1000;
export const DOLLAR_MOCK = "$";
export const SP_MOCK = "SP";
export const CUSTOM_ERROR_MOCK = "Custom error text";

export const SHOW_MORE_MOCK = "Show more";
export const SHOW_LESS_MOCK = "Show less";

export const ARR_STRING_MOCK = ["string1", "string2", "string3"];

export const NO_PRODUCT_MOCK = "There are no products available at the moment. Please try again later";

export const DEATH_MARKET_MOCK = [
  { buying: 102, price: "155.22" },
  { selling: 66, price: "156.97" },
  { selling: 10, price: "12.64" },
  { buying: 1090, price: "12555.66" },
];

type Estimates = {
  title: string;
  recommended: string;
  numOfRecommended: number;
  type: "BUY" | "SELL" | "HOLD";
}[];

export const ESTIMATES_GRAPH_MOCK: Estimates = [
  {
    title: "Buying",
    recommended: "16 ppl.",
    numOfRecommended: 16,
    type: "BUY",
  },
  {
    title: "Selling",
    recommended: "9 ppl.",
    numOfRecommended: 9,
    type: "SELL",
  },
  {
    title: "Hold",
    recommended: "2 ppl.",
    numOfRecommended: 2,
    type: "HOLD",
  },
];

export const NULL_FUNCTION = () => null;

export const POINTS_MOCK = [
  { description: "Подбор объектов инвестирования" },
  { description: "Сопровождение на этапе оплаты" },
  { description: "Полное юридическое сопровождение сделки" },
  { description: "Помощь в продаже имеющихся объектов недвижимости" },
  { description: "Услуги по инвестиционному консалтингу недвижимости" },
];
