import { makeStyles } from "tss-react/mui";

import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()((theme) => ({
  wrapper: {
    padding: "16px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `1px solid ${colorTable.greyContour}`,
    cursor: "pointer",
    "&:hover": {
      background: colorTable.white_20,
    },
  },
  iconTextWrapper: {
    display: "flex",
    gap: "16px",
  },
  favoriteIconWrapper: {
    position: "relative",
  },

  favoritesIcon: {
    [theme.breakpoints.up(993)]: {
      "&:hover": {
        color: colorTable.yellow_20,
      },
    },
  },

  textWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  favoritesWrapper: {
    display: "flex",
    gap: "12px",
  },
  subtitle: {
    color: colorTable.lightGrey_40,
  },
  profitability: {
    color: colorTable.green_50,
    whiteSpace: "nowrap",
  },
  priceWrapper: {
    textAlign: "right",
    position: "relative",
  },
  seconSubtitleWrapper: {
    display: "flex",
    gap: "4px",
    justifyContent: "end",
  },
  seconSubtitleText: {
    color: colorTable.charcoalBlack,
  },
  seconSubtitle: {
    color: colorTable.green_50,
  },
}));
