import { createSelector } from "reselect";
import { get } from "lodash";

import { RootState } from "~/configs/reducers";
import { createAsyncSelector } from "~/lib/redux/async";

import { AuthState, STATE_NAME } from "./reducer";
import { isRequiredToSetSecurityQuestion } from "~/entities/auth/utils/is";

export const authStateSelector = (state: RootState): AuthState => state[STATE_NAME];

export const authUserNameSelector = createSelector(authStateSelector, (state): string =>
  get(state, "data.result") ? get(state, "data.userName") || "" : ""
);

export const authIsRequiredToSetSecurityQuestion = createSelector(authStateSelector, (state): boolean =>
  isRequiredToSetSecurityQuestion(state.data)
);

export const authAsyncSelector = createAsyncSelector(authStateSelector);
