import { useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useEvent } from "effector-react";

import { headerModel } from "~/modules/header-model";

export const useChangeHeaderTheme = (
  backgroundPageMobile = "#1D0553",
  mediaQuery: string = "(max-width: 992px)",
  backgroundPageDesctop = "",
  isDarkColorThemeMobile = true,
  isDarkColorThemeDesktop = false
) => {
  const { changeHeaderTheme } = headerModel;
  const changeHeader = useEvent(changeHeaderTheme);
  const isTabletOrMobile = useMediaQuery(mediaQuery);

  return useEffect(() => {
    isTabletOrMobile
      ? changeHeader({
          isDarkColorTheme: isDarkColorThemeMobile,
          background: backgroundPageMobile,
        })
      : changeHeader({ isDarkColorTheme: isDarkColorThemeDesktop, background: backgroundPageDesctop });
    return () => {
      changeHeader({ isDarkColorTheme: false, background: "" });
    };
  }, [isTabletOrMobile]);
};
