import { ContentType, get, post, put } from "~/lib/request";
import { deleteTokenFb } from "~/configs/firebase.ts";

export const registerPushToken = async (params: { token: string; device: string }) => {
  const { token, device } = params;

  const res = await post("@auth/push-service/api/v1/devices/token", {
    data: {
      device,
      token,
    },
  });
  return res;
};

export const deactivatePushToken = async () => {
  await deleteTokenFb();
};

export const readAllPushMessages = async () => {
  const res = await put(
    "@auth/notifier/notifications/client/push/mark_all_read",
    {
      data: {
        status: "READ",
      },
    },
    { contentType: ContentType.FormData }
  );
  return res;
};

export const readPushById = async (id: string) => {
  const res = await put(
    `@auth/notifier/notifications/client/push/${id}/status`,
    {
      data: {
        status: "READ",
      },
    },
    { contentType: ContentType.FormData }
  );
  return res;
};

export const getPushHistory = async (page?: string) => {
  const res = await get("@auth/notifier/notifications/client/push/history", { params: { page: page } });
  return res.data;
};

export const getUnreadPushCount = async () => {
  const res = await get("@auth/notifier/notifications/client/push/count-unread");
  return res.data.unreadNotifications;
};
