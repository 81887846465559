import { cast, Yup } from "~/lib/validation";

interface Question {
  question: string;
}
export interface KeycloakSecurityQuestionsModel {
  content: {
    questions: Question[];
  };
}

const schema = Yup.object<KeycloakSecurityQuestionsModel>({
  content: Yup.object<KeycloakSecurityQuestionsModel["content"]>({
    questions: Yup.array<KeycloakSecurityQuestionsModel["content"]["questions"]>()
      .of<Question>(
        Yup.object({
          question: Yup.string().required(),
        })
          .required()
          .label("KeycloakSecurityQuestionModel"),
      )
      .required(),
  }).required(),
}).label("KeycloakSecurityQuestionsModel");

export const castKeycloakSecurityQuestionsModel = cast<KeycloakSecurityQuestionsModel>(schema);
