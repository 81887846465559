import { createReducer, Reducer } from "~/lib/redux";
import { AsyncData, AsyncDataPhase, initial } from "~/lib/redux/async";

import { anketaSaveBankSectionStartAction, anketaUpdateBankSectionStartAction } from "../actions";
import { BankSectionDto } from "~/entities/anketa/models/BankSectionDto";

export const STATE_NAME = "saveOrUpdateBankSection";

export type AnketaSaveOrUpdateBankSectionState = AsyncData<BankSectionDto>;

export const defaultState: AnketaSaveOrUpdateBankSectionState = initial();

const reducer: Reducer<AnketaSaveOrUpdateBankSectionState> = createReducer<AnketaSaveOrUpdateBankSectionState>(
  {},
  defaultState
);

const pendingReducer = (state: AnketaSaveOrUpdateBankSectionState): AnketaSaveOrUpdateBankSectionState => ({
  ...state,
  phase: AsyncDataPhase.Pending,
});

reducer.on(anketaSaveBankSectionStartAction, pendingReducer);
reducer.on(anketaUpdateBankSectionStartAction, pendingReducer);

export default reducer;
