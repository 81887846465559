import { clientChannels, lastKeyProcessNames } from "~/constants/enums";
import {
  ChangePasswordGetProcessResponse,
  ChangePasswordRequest,
  ChangePasswordResponse,
  ChangeTemporaryPasswordRequest,
  CountryCode,
  LoginNoOtpResponse,
  LoginOtpResponse,
  LoginRequest,
  LoginSessionsResponse,
  LoginWithOtpCodeRequest,
  OtpCodeRequest,
  OtpCodeResponse,
  ProcessBusinessRequest,
  ProcessBusinessResetPasswordRequest,
  ProcessBusinessResponse,
  ProcessStateRequest,
  ResetByPassportRequest,
  SecurityQuestionRequest,
  SecurityQuestionResponse,
} from "~/entities/keycloak/types";
import {
  INVALID_OTP_FIRST_BLOCK,
  processResultEnum,
  taskDefinitionKeyEnum,
  TEMPORARY_BLOCKED_USER_AFTER_SET_QUESTION,
  USER_RESET_PASSWORD_BLOCKED,
} from "~/entities/productBuy/types";
import i18n from "~/i18n";
import { delay } from "~/lib/delay";
import { ContentType, get, post, put } from "~/lib/request";
import { result } from "~/lib/validation";
import { getUserTokenInfo } from "~/modules/userToken/api";
import { KeycloakOtpCheckRequestModel } from "./models/KeycloakOtpCheckModel";
import { castKeycloakResponseModel, KeycloakResponseModel } from "./models/KeycloakResponseModel";
import {
  castKeycloakSecurityQuestionsModel,
  KeycloakSecurityQuestionsModel,
} from "./models/KeycloakSecurityQuestionsModel";
import { castKeycloakTokenModel, KeycloakTokenModel, RegistrationDataModel } from "./models/KeycloakTokenModel";

const bcsAzpHeader = import.meta.env.VITE_CLIENT_ID || "";
const bcsAzpHeaders = bcsAzpHeader ? { "BCS-AZP": bcsAzpHeader } : {};

export const keycloakRefreshToken = async (refreshToken: string): Promise<KeycloakTokenModel> => {
  const xChannel = localStorage.getItem("X-Channel") || clientChannels.UCF_WEB;
  const response = await post(
    "@auth/token/refresh",
    {
      data: {
        refresh_token: refreshToken,
      },
    },

    {
      headers: {
        "X-Channel": xChannel,
      },
      contentType: ContentType.JSON,
      isPrivate: false,
    }
  );
  return result(response, castKeycloakTokenModel);
};

export const keycloakChangePassword = async (
  userName: string,
  currentPassword: string,
  newPassword: string
): Promise<KeycloakResponseModel> => {
  const params = {
    clientId: getUserTokenInfo().id,
  };
  const response = await post(
    "@auth/auth/realms/Broker/bcs-api/otp/change-password",
    {
      data: {
        userName,
        currentPassword,
        newPassword,
      },
      params,
    },
    {
      contentType: ContentType.FormUrlencoded,
      headers: bcsAzpHeaders,
    }
  );

  return result(response, castKeycloakResponseModel);
};

export const keycloakResetPassword = async (
  userName: string,
  answer: string,
  password?: string
): Promise<KeycloakResponseModel> => {
  const response = await post(
    "@auth/auth/realms/Broker/bcs-api/otp/security-questions/reset-password",
    {
      data: {
        userName,
        answer,
        password,
      },
    },
    {
      contentType: ContentType.FormUrlencoded,
      headers: bcsAzpHeaders,
    }
  );

  return result(response, castKeycloakResponseModel);
};

export const keycloakInitialCredentialsPassword = async (
  question: string,
  answer: string,
  password?: string
): Promise<KeycloakResponseModel> => {
  const response = await post(
    "@auth/auth/realms/Broker/bcs-api/otp/initial-credentials",
    {
      data: {
        question,
        answer,
        password,
      },
    },
    {
      contentType: ContentType.FormUrlencoded,
      headers: bcsAzpHeaders,
    }
  );

  return result(response, castKeycloakResponseModel);
};

export const keycloakCheckOtp = async ({ sid, otp }: KeycloakOtpCheckRequestModel): Promise<KeycloakResponseModel> => {
  const response = await post(
    "@auth/auth/realms/Broker/bcs-api/keycloak/otp/check",
    {
      data: {
        sid,
        otp,
      },
    },
    {
      contentType: ContentType.FormUrlencoded,
      headers: bcsAzpHeaders,
    }
  );

  return result(response, castKeycloakResponseModel);
};

export const sendOtp = async ({ sid, otp }: KeycloakOtpCheckRequestModel): Promise<KeycloakResponseModel> => {
  const params = {
    clientId: getUserTokenInfo().id,
    clientLogin: getUserTokenInfo().login,
  };
  const response = await put("@auth/executor/v1/task/complete", {
    data: {
      taskId: sid,
      variables: {
        enteredCode: otp,
      },
    },
    params,
  });

  return response.data;
};

export const sendOtpNew = async (data: any): Promise<KeycloakResponseModel> => {
  const params = {
    clientId: getUserTokenInfo().id,
    clientLogin: getUserTokenInfo().login,
  };
  const response = await put("@auth/executor/v1/task/complete", {
    data,
    params,
  });

  return response.data;
};

export const keycloakSecurityQuestions = async (): Promise<KeycloakSecurityQuestionsModel> =>
  result(
    await get("@auth/auth/realms/Broker/bcs-api/security-questions/list", undefined, {
      headers: i18n.language === "en" ? bcsAzpHeaders : undefined,
      isPrivate: i18n.language === "en",
    }),
    castKeycloakSecurityQuestionsModel
  );

export const keycloakSecurityQuestion = async (userName: string): Promise<KeycloakResponseModel> =>
  result(
    await get(
      "@auth/auth/realms/Broker/bcs-api/otp/security-questions",
      { params: { userName } },
      { headers: bcsAzpHeaders }
    ),
    castKeycloakResponseModel
  );

export const keycloakChangeSecurityQuestion = async (
  question: string,
  answer: string
): Promise<KeycloakResponseModel> =>
  result(
    await post(
      "@auth/auth/realms/Broker/bcs-api/otp/security-questions",
      {
        data: {
          question,
          answer,
        },
      },
      {
        contentType: ContentType.FormUrlencoded,
        headers: bcsAzpHeaders,
      }
    ),
    castKeycloakResponseModel
  );

export const authApi = {
  registration: async (registrationData: RegistrationDataModel) => {
    const response = await post(
      "@auth/executor/v1/users/registration",
      {
        data: registrationData,
      },
      {
        contentType: ContentType.JSON,
        isPrivate: false,
        headers: {
          "captcha-response": registrationData.recaptcha,
          "X-Channel": clientChannels.UCF_WEB,
        },
      }
    );

    return response;
  },

  customerPrivacyNotice: async () => {
    const response = await get("@auth/document/business/documents/file/base64/type?docType=CPN");

    return response.data;
  },

  completeTask: async (data: any) => {
    const response = await put(
      "@auth/executor/v1/task/complete",
      {
        data,
      },
      {
        contentType: ContentType.JSON,
        isPrivate: false,
      }
    );
    return response;
  },

  completeTaskPrivate: async (data: any) => {
    const response = await put(
      "@auth/executor/v1/task/complete",
      {
        data,
      },
      {
        contentType: ContentType.JSON,
      }
    );
    return response;
  },

  unblockingUser: async (data: ProcessBusinessRequest): Promise<ProcessBusinessResponse> => {
    const response = await post(
      "@auth/executor/v1/users/unlock",
      {
        data,
      },
      {
        contentType: ContentType.JSON,
        headers: {
          ...bcsAzpHeaders,
          "captcha-response": data.recaptcha,
          "X-Channel": clientChannels.UCF_WEB,
        },
      }
    );
    return response.data;
  },

  getAvailableResetPasswordTypes: async (login: string) => {
    const res = await get(
      `@auth/auth/realms/Broker/bcs-api/available-reset-password-types/${login}`,
      {},
      {
        contentType: ContentType.FormUrlencoded,
        headers: bcsAzpHeaders,
      }
    );
    return res.data;
  },
  getSecurityQuestions: async (data: SecurityQuestionRequest): Promise<SecurityQuestionResponse> => {
    const res = await get(
      "@auth/auth/realms/Broker/bcs-api/otp/security-questions",
      {
        params: data,
      },
      {
        contentType: ContentType.FormUrlencoded,
        headers: bcsAzpHeaders,
      }
    );
    return res.data;
  },
  getProcessBusinessKey: async ({
    username,
    session,
    recaptcha,
    otpSecret,
  }: ProcessBusinessResetPasswordRequest): Promise<ProcessBusinessResponse> => {
    const res = await post(
      "@auth/executor/v1/users/reset",
      {
        data: {
          username,
          session,
          otpSecret,
        },
      },
      {
        contentType: ContentType.JSON,
        headers: {
          ...bcsAzpHeaders,
          "captcha-response": recaptcha,
          "X-Channel": clientChannels.UCF_WEB,
        },
      }
    );
    return res.data;
  },

  getProcessState: async (data?: ProcessStateRequest): Promise<any> => {
    if (!data) return;

    await delay(500);
    const request = async (): Promise<any> => {
      const res = await get(
        "@auth/executor/v1/process/state",
        {
          params: data,
        },
        {
          contentType: ContentType.JSON,
          headers: bcsAzpHeaders,
        }
      ).then((res) => res.data);

      if (
        res.processResult === processResultEnum.SUCCESSFUL_REGISTRATION ||
        res.processResult === processResultEnum.USER_ALREADY_EXISTS ||
        res.processResult === processResultEnum.SUCCESSFUL_RESET_PASSWORD ||
        res.activeTasks[0]?.taskDefinitionKey === taskDefinitionKeyEnum.OTP_CONFIRMATION ||
        res.activeTasks[0]?.taskDefinitionKey === taskDefinitionKeyEnum.ENTER_USERNAME ||
        res.processResult === processResultEnum.INVALID_ANSWER ||
        res.processResult === processResultEnum.CANNOT_SEND_SMS ||
        res.processResult === processResultEnum.MAXIMUM_NUMBER_OF_INPUT_ATTEMPTS ||
        res.processResult === processResultEnum.USER_NOT_SET_QUESTION_ANSWER ||
        res.processResult === processResultEnum.USER_ACCOUNT_DISABLED ||
        res.processResult === TEMPORARY_BLOCKED_USER_AFTER_SET_QUESTION ||
        res.processResult === USER_RESET_PASSWORD_BLOCKED ||
        res.processResult === INVALID_OTP_FIRST_BLOCK ||
        res.processResult === processResultEnum.SUCCESSFUL_UNLOCK_ACCOUNT ||
        res.processResult === processResultEnum.INVALID_OTP_FIRST_BLOCK ||
        res.processResult === processResultEnum.INVALID_OTP_SECOND_BLOCK ||
        res.processResult === processResultEnum.USERNAME_PERMANENTLY_BLOCKED ||
        res.processResult === processResultEnum.WAITING_BODR_DESICION ||
        res.processResult === processResultEnum.CANNOT_SEND_OTP_CODE ||
        res.processResult === processResultEnum.FORBIDDEN ||
        res.processResult === processResultEnum.SUCCESS ||
        res.processResult === processResultEnum.DATA_MISMATCH ||
        res.processResult === processResultEnum.DATA_OF_BIRTH_MISMATCH ||
        res.processResult === processResultEnum.COUNTRY_OF_RESIDENCE_MISMATCH ||
        res.processResult === processResultEnum.COUNTRY_OF_CITIZENSHIP_MISMATCH ||
        res.processResult === processResultEnum.PRINT_FORM_ERROR ||
        res.processResult === processResultEnum.ERROR_SENDING_BODR_REQUEST ||
        res.processResult === processResultEnum.ERROR_STATUS ||
        res.processResult === processResultEnum.GO_TO_SUPPORT ||
        res.processResult === processResultEnum.USER_PERMANENTLY_BLOCKED ||
        res.processResult === processResultEnum.PAYMENT_PROOF_DOCUMENT_SENT ||
        res.processResult === processResultEnum.OTP_SECRET_DOES_NOT_MATCH ||
        res.processResult === processResultEnum.UNSUPPORTED_ERROR ||
        res.processResult === processResultEnum.TIME_EXPIRED ||
        res.processResult === processResultEnum.VALIDATION_ERROR ||
        res.processResult === processResultEnum.CANNOT_CHECK_OTP_COUNTER ||
        res.processResult === processResultEnum.SERVER_ERROR ||
        res.processResult === processResultEnum.SECURITY_QUESTION_TIME_EXPIRED ||
        res.processResult === processResultEnum.ERROR_SENDING_ACCOUNT_REQUEST ||
        res.processResult === processResultEnum.SAVE_ERROR ||
        res.processResult === processResultEnum.RISK_PROFILE_MISMATCH ||
        res.processResult === processResultEnum.INTERNAL_SERVER_ERROR ||
        res.processResult === processResultEnum.ERROR_SENDING_TRADER_REQUEST
      ) {
        return res;
      }

      if (
        res.state === processResultEnum.DONE &&
        res?.processKey === lastKeyProcessNames.PROCESS_KEY_SET_PERMANENT_PASSWORD
      ) {
        return res;
      }

      if (res.activeTasks.length === 0 && !res?.resultData?.accessToken) {
        await delay(1500);
        return request();
      }
      return res;
    };
    return request();
  },

  getProcessStateEDM: async (data?: ProcessStateRequest): Promise<any> => {
    if (!data) return;

    await delay(500);
    const request = async (): Promise<any> => {
      const res = await get(
        "@auth/executor/v1/process/state",
        {
          params: {
            processBusinessKey: data?.processBusinessKey,
            sumSubToken: data?.sumSubToken,
          },
        },
        {
          contentType: ContentType.JSON,
          headers: bcsAzpHeaders,
        }
      ).then((res) => res.data);

      if (
        res.processResult === processResultEnum.SUCCESSFUL_REGISTRATION ||
        res.processResult === processResultEnum.USER_ALREADY_EXISTS ||
        res.processResult === processResultEnum.SUCCESSFUL_RESET_PASSWORD ||
        res.activeTasks[0]?.taskDefinitionKey === taskDefinitionKeyEnum.OTP_CONFIRMATION ||
        res.activeTasks[0]?.taskDefinitionKey === taskDefinitionKeyEnum.ENTER_USERNAME ||
        res.processResult === processResultEnum.INVALID_ANSWER ||
        res.processResult === processResultEnum.CANNOT_SEND_SMS ||
        res.processResult === processResultEnum.MAXIMUM_NUMBER_OF_INPUT_ATTEMPTS ||
        res.processResult === processResultEnum.USER_NOT_SET_QUESTION_ANSWER ||
        res.processResult === processResultEnum.USER_ACCOUNT_DISABLED ||
        res.processResult === TEMPORARY_BLOCKED_USER_AFTER_SET_QUESTION ||
        res.processResult === USER_RESET_PASSWORD_BLOCKED ||
        res.processResult === INVALID_OTP_FIRST_BLOCK ||
        res.processResult === processResultEnum.SUCCESSFUL_UNLOCK_ACCOUNT ||
        res.processResult === processResultEnum.INVALID_OTP_FIRST_BLOCK ||
        res.processResult === processResultEnum.INVALID_OTP_SECOND_BLOCK ||
        res.processResult === processResultEnum.USERNAME_PERMANENTLY_BLOCKED ||
        res.processResult === processResultEnum.WAITING_BODR_DESICION ||
        res.processResult === processResultEnum.CANNOT_SEND_OTP_CODE ||
        res.processResult === processResultEnum.FORBIDDEN ||
        res.processResult === processResultEnum.SUCCESS ||
        res.processResult === processResultEnum.INVALID_OTP_FIRST_BLOCK ||
        res.processResult === processResultEnum.INVALID_OTP_SECOND_BLOCK
      ) {
        return res;
      }

      if (res.state === "DONE" || (!!res.processResult && !data?.emptyProcessResultRefresh)) {
        return res;
      }

      if (res.activeTasks.length === 0) {
        await delay(1500);
        return request();
      }
      return res;
    };
    return request();
  },

  getQuestionList: async (): Promise<any> => {
    const res = await get(
      "@auth/client/questions",
      { params: { size: 100 } },
      {
        contentType: ContentType.JSON,
        headers: bcsAzpHeaders,
      }
    );
    return res.data;
  },

  setQuestionWithoutCamunda: async (data: {questionId: string, answer: string}): Promise<any> => {
    const res = await post("@msclient/questions/answer", { data });
    return res.data;
  },

  resetByDocuments: async (data: ResetByPassportRequest) => {
    const res = await post(
      "@auth/auth/realms/Broker/bcs-api/otp/activation-reset-by-birthday-passport",
      {
        data,
      },
      { contentType: ContentType.FormUrlencoded, headers: bcsAzpHeaders }
    );
    return res.data;
  },
  otpCode: async (data: OtpCodeRequest): Promise<OtpCodeResponse> => {
    const res = await post(
      "@auth/auth/realms/Broker/bcs-api/keycloak/otp/check",
      {
        data,
      },
      { contentType: ContentType.FormUrlencoded, headers: bcsAzpHeaders }
    );
    return res.data;
  },
  login: async ({ username, password, recaptcha, region }: LoginRequest): Promise<LoginSessionsResponse> => {
    const res = await post(
      "@auth/token",
      {
        data: {
          username,
          password,
          region,
        },
      },
      {
        headers: {
          "captcha-response": recaptcha,
          "X-Channel": clientChannels.UCF_WEB,
        },
        contentType: ContentType.JSON,
        isPrivate: false,
      }
    );
    return res.data;
  },

  loginWithOtpCode: async ({
    username,
    password,
    session_id,
    session_code,
    recaptcha,
    region,
  }: LoginWithOtpCodeRequest): Promise<LoginOtpResponse | LoginNoOtpResponse> => {
    const res = await post(
      "@auth/token",
      {
        data: {
          username,
          password,
          session_id,
          session_code,
          region,
        },
      },
      {
        contentType: ContentType.JSON,
        headers: {
          "captcha-response": recaptcha,
          "X-Channel": clientChannels.UCF_WEB,
        },
        isPrivate: false,
      }
    );
    return res.data;
  },

  getCountryCode: async (): Promise<CountryCode[]> => {
    const res = await get("@auth/country/countries/allowed", { params: { size: 500, sort: "ASC" } });
    return res.data.content;
  },
};

export const changePasswordApi = {
  changePassword: async (data: ChangePasswordRequest): Promise<ChangePasswordResponse> => {
    const params = {
      clientId: getUserTokenInfo().id,
      clientLogin: getUserTokenInfo().login,
    };
    const res = await post(
      "@auth/executor/v1/users/password",
      //@ts-ignore
      { data, params }
    );
    return res.data;
  },
  sendOtpCode: async (data: OtpCodeRequest): Promise<OtpCodeResponse> => {
    const params = {
      clientId: getUserTokenInfo().id,
      clientLogin: getUserTokenInfo().login,
    };
    const res = await put("@auth/executor/v1/task/complete", {
      data,
      params,
    });
    return res.data;
  },

  changeTemporaryPassword: async ({
    recaptcha,
    password,
    username,
    otpSecret,
  }: ChangeTemporaryPasswordRequest): Promise<string> => {
    const res = await post(
      "@auth/executor/v1/users/password/temporary",
      {
        data: {
          username,
          password,
          otpSecret,
        },
      },
      {
        headers: {
          "captcha-response": recaptcha,
          "X-Channel": clientChannels.UCF_WEB,
        },
      }
    );
    return res.data;
  },

  getProcess: async (businessKey: string): Promise<ChangePasswordGetProcessResponse> => {
    const params = {
      clientId: getUserTokenInfo().id,
      clientLogin: getUserTokenInfo().login,
      processBusinessKey: businessKey,
    };
    const res = await get(
      "@auth/executor/v1/process/state",
      { params },
      { headers: bcsAzpHeaders, contentType: ContentType.FormUrlencoded }
    );
    return res.data;
  },
};
