import { combineReducers } from "redux";

export const STATE_NAME = "offer";

import listReducer, { STATE_NAME as LIST_STATE_NAME, OfferListState } from "./reducers/list";
import detailsReducer, { STATE_NAME as DETAILS_STATE_NAME, DetailsState } from "./reducers/details";

export interface OfferState {
  [LIST_STATE_NAME]: OfferListState;
  [DETAILS_STATE_NAME]: DetailsState;
}

export default combineReducers<OfferState>({
  [LIST_STATE_NAME]: listReducer,
  [DETAILS_STATE_NAME]: detailsReducer,
});
