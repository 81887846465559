import contentDesposition from "content-disposition";
import { filter, find, forEach, get, reduce } from "lodash";
import { SagaIterator } from "redux-saga";
import { all, call, CallEffect, put, race, take, takeEvery } from "redux-saga/effects";

import { LOCALE_STORAGE_KEY } from "~/constants/money";
import { AppLogicError, AppLogicErrorVariant, AppResponseError, AppValidationError } from "~/entities/app";
import { isAppLocale } from "~/entities/app/models/AppLocale";
import { SmsSignErrorReasonType } from "~/entities/common";
import i18n, { t } from "~/i18n";
import { dataURIToBlob, downloadBlob } from "~/lib/file";
import { Action, takeEveryAction, takeLatestAction } from "~/lib/redux";
import { get as requestGet } from "~/lib/request";
import * as storage from "~/lib/storage";
import tokenService from "~/lib/token";
import { supportTelephone } from "~/lib/utils";
import { actions as appActions } from "~/modules/app";
import { actions as authActions } from "~/modules/auth";
import { actions as clientCardActions } from "~/modules/client-card";
import {
  appBootErrorAction,
  appBootRequestAction,
  appBootStartAction,
  appBootSuccessAction,
  appDefaultErrorMessageAction,
  appErrorAction,
  appMessageAction,
  appSaveFilesErrorAction,
  appSaveFilesRequestAction,
  appSaveFilesStartAction,
  appSaveFilesSuccessAction,
  appSetLocaleAction,
  appSmsSignCheckErrorAction,
  appSmsSignCheckSuccessAction,
  appSmsSignResendErrorAction,
  appSmsSignSignErrorAction,
} from "./actions";

export function* appBootSaga(): SagaIterator {
  try {
    const locale = storage.get(LOCALE_STORAGE_KEY) ?? "";
    if (isAppLocale(locale)) {
      yield put(appSetLocaleAction(locale));
    }
  } catch {} // eslint-disable-line no-empty

  try {
    const token = tokenService.get();
    if (!token) {
      yield put(appBootSuccessAction());
      return;
    }

    yield put(appBootStartAction());

    yield put(clientCardActions.clientCardRequestAction());

    const { success } = yield race({
      success: take(clientCardActions.clientCardSuccessAction.getType()),
      error: take(clientCardActions.clientCardErrorAction.getType()),
    });

    if (!success) {
      tokenService.clear();
      yield put(authActions.authSyncTokenStateAction());
    }

    yield put(appBootSuccessAction());
  } catch (error) {
    yield put(appBootErrorAction(error as Error));
  }
}

export function setLocaleSaga({ payload }: Action<any>): void {
  if (payload !== i18n.language) {
    try {
      storage.set(LOCALE_STORAGE_KEY, payload);
    } catch {} // eslint-disable-line no-empty

    i18n.changeLanguage(payload);
  }
}

export function* appValidationErrorSaga(action: any): SagaIterator {
  const { error, payload } = action;
  if (error && payload && AppValidationError.is(payload)) {
    // eslint-disable-next-line no-console
    console.warn(payload.error);
    if (import.meta.env.MODE === "development") {
      yield put(
        appMessageAction({
          message: "ValidationError: " + get(payload, "error.message"),
          options: { variant: "warning" },
        })
      );
    }
  }
}

export function* appDefaultErrorMessageActionSage(): SagaIterator {
  yield put(
    appActions.appMessageAction({
      message: t("ERRORS.default", { tel: supportTelephone() }),
      options: { variant: "error" },
    })
  );
}

export function* appBaseResponseErrorsSaga({ error, payload }: any): SagaIterator {
  if (error && payload && AppResponseError.is(payload)) {
    if (import.meta.env.MODE === "development") {
      // eslint-disable-next-line no-console
      console.warn({ AppResponseError: payload });
    }

    const status = get(payload, "error.response.status", 0);
    if (status === 401) {
      tokenService.clear();
      yield put(authActions.authSyncTokenStateAction());
    }
  }
}

function* smsSignCheckSuccessSaga({ payload }: Action<any>): SagaIterator {
  const { success, reason } = payload;

  if (success === false) {
    switch (reason) {
      case SmsSignErrorReasonType.Incorrect:
        yield put(
          appActions.appMessageAction({
            message: t("ERRORS.sms-incorrect"),
            options: { variant: "error" },
          })
        );
        return;
      case SmsSignErrorReasonType.Expired:
        yield put(
          appActions.appMessageAction({
            message: t("ERRORS.sms-expired"),
            options: { variant: "error" },
          })
        );
        return;
    }

    yield put(
      appActions.appMessageAction({
        message: t("ERRORS.default", { tel: supportTelephone() }),
        options: { variant: "error" },
      })
    );
  }
}

const defaultChooser = (status: number): boolean => [400, 401, 403, 409, 500].indexOf(status) > -1;

export const responseErrorsSagaCreator = (chooser: (status: number) => boolean = defaultChooser) => {
  return function* responseErrorsSaga({ error, payload }: any): SagaIterator {
    if (error && payload && AppResponseError.is(payload)) {
      const status = get(payload, "error.response.status", 0);

      if (!payload.notified && chooser(status)) {
        payload.setNotified("responseErrorsSagaCreator");
        yield put(appErrorAction({ error: payload, reason: "responseErrorsSagaCreator" }));
      }
    }
  };
};

const errorSaga = responseErrorsSagaCreator();

export function* saveFilesSaga(action: ReturnType<typeof appSaveFilesRequestAction>): SagaIterator {
  try {
    yield put(appSaveFilesStartAction(action.payload));

    const responses = yield all(
      reduce(
        // do requests for URLs only
        filter(action.payload.data, (file): boolean => !file.isBase64),
        (requests, file) => {
          requests[file.data] = call(requestGet, file.data, undefined, {
            isBlob: true,
          });

          return requests;
        },
        {} as { [data: string]: CallEffect }
      )
    );

    // handle all URL requests and save files
    forEach(responses, (response, url): void => {
      const file = find(action.payload.data, { data: url });
      const blob = get(response, "data");
      const contentHeader = get(response, "headers.content-disposition");
      let name = file?.filename;

      if (contentHeader) {
        const disposition = contentDesposition.parse(contentHeader);

        if (!name && disposition.parameters.filename) {
          name = decodeURI(disposition.parameters.filename);
        }
      }

      if (name) {
        downloadBlob(name, blob);
      } else {
        throw new AppLogicError(AppLogicErrorVariant.Unreachable);
      }
    });

    // handle all base64 requests and save files
    forEach(
      filter(action.payload.data, (file): boolean => !!file.isBase64),
      (file): void => {
        if (file.filename) {
          const blob = dataURIToBlob(file.data);

          downloadBlob(file.filename, blob);
        }
      }
    );

    yield put(appSaveFilesSuccessAction());

    if (action.payload.success) yield call(action.payload.success);

    const message = t("GENERAL.file-success-download");
    yield put(appActions.appMessageAction({ message, options: { variant: "success" } }));
  } catch (e) {
    if (action.payload.error) yield call(action.payload.error);

    const message = t("GENERAL.file-fail-download");
    yield put(appActions.appMessageAction({ message, options: { variant: "error" } }));

    yield put(appSaveFilesErrorAction.asError(e as Error));
  }
}

export default function* (): SagaIterator {
  yield all([
    takeLatestAction(appBootRequestAction, appBootSaga),
    takeEvery("*", appBaseResponseErrorsSaga),
    takeEvery("*", appValidationErrorSaga),
    takeEveryAction(appSmsSignCheckErrorAction, errorSaga),
    takeEveryAction(appSmsSignResendErrorAction, errorSaga),
    takeEveryAction(appSmsSignSignErrorAction, errorSaga),
    takeEveryAction(appSmsSignCheckSuccessAction, smsSignCheckSuccessSaga),
    takeEveryAction(appSaveFilesRequestAction, saveFilesSaga),
    takeEveryAction(appDefaultErrorMessageAction, appDefaultErrorMessageActionSage),
    takeEvery(appSetLocaleAction.getType(), setLocaleSaga),
  ]);
}
