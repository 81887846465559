import { clientChannels } from "~/constants/enums";
import i18n from "~/i18n";
import { get, post, put } from "~/lib/request";

export const getIdentification = async (): Promise<any> => {
  const res = await post("@auth/executor/v1/onboarding/identification");
  return res.data;
};

export const getIdentificationV2 = async (): Promise<any> => {
  const res = await post("@auth/executor/v2/onboarding/identification");
  return res.data;
};

export const getIdentificationV3 = async (): Promise<any> => {
  const res = await post("@auth/executor/v3/onboarding/identification", undefined, {
    headers: {
      "X-Channel": clientChannels.UCF_WEB,
      "Accept-Language": i18n.language,
    },
  });
  return res.data;
};

export const putTaskComplete = async (requestData: any): Promise<any> => {
  if (Object.keys(requestData).length) {
    const res = await put("@auth/executor/v1/task/complete", { data: requestData });
    return res.data;
  }
};

export const getVerificationStatus = async (): Promise<any> => {
  const res = await get("@auth/client-verificator/v1/client-verificator/verification/status");
  return res.data;
};

export const getLastKey = async (params: any): Promise<any> => {
  const res = await get("@auth/executor/v1/process/active/last", { params });
  return res.data;
};
