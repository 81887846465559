import { get } from "~/lib/request";
import { notificationModel } from "~/modules/notification-model";
import { pdfBase64DocInfo } from "~/shared/types";

export const getImageById = async (id: string, service: string): Promise<string | undefined> => {
  let res: Record<"data", Blob> | undefined;

  try {
    res = await get(
      `@auth/bcsfs/v1/file/${service}/${id}`,
      {},
      {
        isBlob: true,
      }
    );
  } catch (error) {
    return undefined;
  }

  if (res) {
    return new Promise<string | undefined>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(res!.data);
    }).catch((err) => {
      notificationModel.errorDefaultServer(err);
      return Promise.resolve(undefined);
    });
  }
};

export const loadArrayPdf = async (data: string[]) => {
  try {
    const files = await Promise.all(
      data.map((item) =>
        get(
          `@auth/executor/v1/${item}`,
          {},
          {
            isBlob: true,
          }
        ).then((res) => {
          const name = res.headers["content-disposition"].match(/filename=(.*?)\.pdf/)?.[1];
          return new Promise<pdfBase64DocInfo>((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64data = (<string>reader.result).split(",")[1];

              resolve({
                content: base64data,
                type: name,
              });
            };
            reader.onerror = reject;
            reader.readAsDataURL(res.data);
          });
        })
      )
    );
    return files;
  } catch {
    return [];
  }
};

export const loadArrayPdfByUrl = async ({ data, domainRequest = "" }: { data: string[]; domainRequest?: string }) => {
  try {
    const files = await Promise.all(
      data.map((item) =>
        get(
          `${domainRequest}${item}`,
          {},
          {
            isBlob: true,
          }
        ).then((res) => {
          const name = res.headers["content-disposition"].match(/filename=(.*?)\.pdf/)?.[1];
          return new Promise<pdfBase64DocInfo>((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64data = (<string>reader.result).split(",")[1];

              resolve({
                content: base64data,
                type: name,
              });
            };
            reader.onerror = reject;
            reader.readAsDataURL(res.data);
          });
        })
      )
    );
    return files;
  } catch {
    return [];
  }
};
