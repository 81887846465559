import React, { ReactElement, useState } from "react";
import { Box } from "@mui/material";
import cn from "classnames";
import { makeStyles } from "tss-react/mui";

import colorTable from "~/shared/ui-kit/constants/colors";
import { IconProps } from "./types";
import AndroidAppQr from "./icons/Android_app_qr.svg?react";
import AndroidApp from "./icons/AndroidApp.svg?react";
import AppQR from "./icons/app_qr.svg?react";
import AppleAppQr from "./icons/Apple_app_qr.svg?react";
import AppleApp from "./icons/AppleApp.svg?react";
import ArrowFilledSelect from "./icons/ArrowFilledSelect.svg?react";
import AttentionIcon from "./icons/Attention_24.svg?react";
import Buy from "./icons/Buy.svg?react";
import CalendarIcon from "./icons/CalendarIcon.svg?react";
import ChartArrowRight from "./icons/chart_arrow_right.svg?react";
import ChartLines from "./icons/ChartLines.svg?react";
import ChartPie from "./icons/ChartPie.svg?react";
import ChatIcon from "./icons/ChatIcon.svg?react";
import CheckboxFilter from "./icons/checkbox_filter.svg?react";
import CheckboxEmptyFilter from "./icons/checkbox_filter_empty.svg?react";
import ChevronAccordion from "./icons/ChevronAccordion.svg?react";
import ChevronIcon from "./icons/ChevronIcon.svg?react";
import ChevronSmall from "./icons/ChevronSmall.svg?react";
import DefaultProductIcon from "./icons/coloredIcons/activities_icon.svg?react";
import Alto from "./icons/coloredIcons/alto.svg?react";
import Bonds from "./icons/coloredIcons/bonds.svg?react";
import CircleIcon1 from "./icons/coloredIcons/circle_icon_1.svg?react";
import CircleIcon2 from "./icons/coloredIcons/circle_icon_2.svg?react";
import CircleIcon3 from "./icons/coloredIcons/circle_icon_3.svg?react";
import CircleIcon4 from "./icons/coloredIcons/circle_icon_4.svg?react";
import CircleIcon5 from "./icons/coloredIcons/circle_icon_5.svg?react";
import CurrencyCny from "./icons/coloredIcons/currency_cny.svg?react";
import CurrencyEur from "./icons/coloredIcons/currency_eur.svg?react";
import CurrencyRub from "./icons/coloredIcons/currency_rub.svg?react";
import CurrencyUsd from "./icons/coloredIcons/currency_usd.svg?react";
import Forward from "./icons/coloredIcons/forward.svg?react";
import Note from "./icons/coloredIcons/note.svg?react";
import PreIpo from "./icons/coloredIcons/pre_ipo.svg?react";
import Sunrun from "./icons/coloredIcons/sunrun.svg?react";
import Copy from "./icons/Copy.svg?react";
import CouponDate from "./icons/couponDate.svg?react";
import Currency from "./icons/Currency.svg?react";
import DeligatedManagement from "./icons/Deligated-Management.svg?react";
import Document from "./icons/DocumentIcon.svg?react";
import DoneIcon from "./icons/Done.svg?react";
import DownloadArrow from "./icons/DownloadArrow.svg?react";
import EmptyFolder from "./icons/empty_folder.svg?react";
import ErrorOrderStatus from "./icons/ErrorOrderStatus.svg?react";
import EyeHide from "./icons/EyeHide.svg?react";
import EyeShow from "./icons/EyeShow.svg?react";
import Facebook from "./icons/Facebook.svg?react";
import Favorites from "./icons/Favorites.svg?react";
import Filter from "./icons/Filter.svg?react";
import Fire from "./icons/Fire.svg?react";
import Flash from "./icons/Flash.svg?react";
import NoticeAlert from "./icons/HotNewsAlert.svg?react";
import InfoNoticeIcon from "./icons/InfoNotice.svg?react";
import InfoIcon from "./icons/InfoIcon.svg?react";
import Information_light from "./icons/Information_light.svg?react";
import Instagram from "./icons/Instagram.svg?react";
import Invalid_browser from "./icons/Invalid_browser.svg?react";
import LockFilled from "./icons/Lock.svg?react";
import Lock from "./icons/Lock_24.svg?react";
import MarginTrade from "./icons/Margin_trade.svg?react";
import Mobile from "./icons/Mobile.svg?react";
import Pending from "./icons/Pending.svg?react";
import Percent from "./icons/Percent.svg?react";
import PercentCircle from "./icons/PercentCircle.svg?react";
import ProfitabilityUp from "./icons/profitability_up.svg?react";
import Replenishment from "./icons/Replenishment.svg?react";
import Reports from "./icons/Reports.svg?react";
import Sell from "./icons/Sell.svg?react";
import Shield from "./icons/Shield.svg?react";
import SortingDefault from "./icons/SortingDefault.svg?react";
import SortingDesc from "./icons/SortingDesc.svg?react";
import SortingIcon from "./icons/SortingIcon.svg?react";
import AlphabetIcon from "./icons/stockIcons/AlphabetInc.svg?react";
import AnalogDevicesIcon from "./icons/stockIcons/AnalogDevicesIcon.svg?react";
import DynatraceIcon from "./icons/stockIcons/DynatraceIcon.svg?react";
import MarvelIcon from "./icons/stockIcons/MarvellIcon.svg?react";
import NewmontIcon from "./icons/stockIcons/NewmontCorporation.svg?react";
import VisaIcon from "./icons/stockIcons/VisaInc.svg?react";
import SuccessStatus from "./icons/SuccessStatus.svg?react";
import SwapRepo from "./icons/SwapRepo.svg?react";
import TableDoubleArrow from "./icons/TableDoubleArrow.svg?react";
import Terminal from "./icons/Terminal.svg?react";
import TimeCurrentIcon from "./icons/Time_current.svg?react";
import Tranche from "./icons/Tranche.svg?react";
import UsdIcon from "./icons/UsdIcon.svg?react";
import Withdraw from "./icons/Withdraw.svg?react";
import Check from "./icons/Checkmark.svg?react";
import Cboe from "./icons/Cboe.svg?react";
import DMAChartListItem from "./icons/DMAChartListItem.svg?react";
import PdfDocument from "./icons/PdfDocument.svg?react";
import FavoritesFilled from "./icons/FavoritesFilled.svg?react";
import Risk from "./icons/Risk.svg?react";
import Reload from "./icons/Reload.svg?react";
import FavoritesAdd from "./icons/FavoritesAdd.svg?react";
import Folder from "./icons/Folder.svg?react";
import FolderAdd from "./icons/FolderAdd.svg?react";
import FolderSimple from "./icons/FolderSimple.svg?react";
import AddToFolder from "./icons/AddToFolder.svg?react";
import FavoritesDelete from "./icons/FavoritesDelete.svg?react";
import EditIcon from "./icons/EditIcon.svg?react";
import RiskIcon from "./icons/Risk_24.svg?react";
import TestingIcon from "./icons/Test_24.svg?react";
import WorldIcon from "./icons/WorldIcon.svg?react";
import CrossIcon from "./icons/CrossIcon.svg?react";
import AccountInvestment from "./icons/AccountInvestment.svg?react";
import Building from "./icons/Building.svg?react";
import BankIcon from "./icons/BankIcon.svg?react";
import NumbersRound1 from "./icons/numbers/numbers_round_1.svg?react";
import NumbersRound2 from "./icons/numbers/numbers_round_2.svg?react";
import NumbersRound3 from "./icons/numbers/numbers_round_3.svg?react";
import NumbersRound4 from "./icons/numbers/numbers_round_4.svg?react";
import NumbersRound5 from "./icons/numbers/numbers_round_5.svg?react";
import NumbersRound6 from "./icons/numbers/numbers_round_6.svg?react";
import NumbersRound7 from "./icons/numbers/numbers_round_7.svg?react";
import NumbersRound8 from "./icons/numbers/numbers_round_8.svg?react";
import NumbersRound9 from "./icons/numbers/numbers_round_9.svg?react";
import TrashIcon from "./icons/TrashIcon.svg?react";
import SortAsc from "./icons/SortAsc.svg?react";
import SortDesc from "./icons/SortDesc.svg?react";
import More from "./icons/More.svg?react";
import TrashEmptyIcon from "./icons/TrashEmptyIcon.svg?react";
import Education from "./icons/education.svg?react";
import ChangeLangSvg from "./icons/changeLang.svg?react";
import Candles from "./icons/candles.svg?react";
import CheckedCheckbox from "./icons/checkedCheckbox.svg?react";
import Checkbox from "./icons/checkbox.svg?react";
import CheckboxCurrent from "./icons/CheckboxCurrent.svg?react";
import Dot from "./icons/dot.svg?react";
import GoForwardSvg from "./icons/goForward.svg?react";
import ButtonPlus from "./icons/button_plus.svg?react";
import ArrowDownwardRoundedSvg from "./icons/arrow-downward-rounded.svg?react";
import Bell from "./icons/bell.svg?react";
import Profile from "./icons/Profile.svg?react";
import DefaultProduct from "./icons/default_product.svg?react";
import MinusButton from "./icons/minusButton.svg?react";
import PlusButton from "./icons/plusButton.svg?react";
import BurgerMinimalButton from "./icons/Burger_menu.svg?react";
import Coupon from "./icons/coupon.svg?react";
import InformationGrey from "./icons/Information_grey.svg?react";
import Reject from "./icons/reject.svg?react";
import Clear from "./icons/clear.svg?react";
import ModalCross from "./icons/modal_cross.svg?react";
import BlockAccount from "./icons/blockAccount.svg?react";
import AuthError from "./icons/authError.svg?react";
import AccountAlreadyRegistered from "./icons/AccountAlreadyRegistered.svg?react";
import SuccessRegister from "./icons/successRegister.svg?react";
import Triangle from "./icons/Triangle.svg?react";
import I from "./icons/i.svg?react";
import Docs from "./icons/docs.svg?react";
import DocsBlack from "./icons/docs_black.svg?react";
import Like from "./icons/like.svg?react";
import LikeFilled from "./icons/like_filled.svg?react";
import LikeFilter from "./icons/like_filter.svg?react";
import LikeFilterFilled from "./icons/like_filter_filled.svg?react";
import Lenta from "./icons/lenta.svg?react";
import Flag from "./icons/flag.svg?react";
import StructureProduct24 from "./icons/structure-product_24.svg?react";
import Trade from "./icons/trade.svg?react";
import GoldStar from "./icons/gold_star.svg?react";
import SelectArrow from "./icons/selectArrow.svg?react";
import DateCalendaBlack from "./icons/dateCalendarBlack.svg?react";
import CheckboxDisable from "./icons/checkboxDisable.svg?react";
import CheckboxGreen from "./icons/checkbox_green.svg?react";
import CheckedCheckboxDisable from "./icons/checkedCheckboxDisable.svg?react";
import BlackCircle from "./icons/BlackCircle.svg?react";
import GrayCircle from "./icons/GrayCircle.svg?react";
import CrownGold from "./icons/Crown_gold.svg?react";
import Crown from "./icons/Crown_slider_icon.svg?react";
import CrownEmpty from "./icons/crown_empty.svg?react";
import MainExit from "./icons/mainExit.svg?react";
import Question from "./icons/question.svg?react";
import MinimalDollar from "./icons/minimalDollarIcon.svg?react";
import MinimalRub from "./icons/currency_rub.svg?react";
import MinimalCny from "./icons/currency_CNY.svg?react";
import MinimalDh from "./icons/currency_dh.svg?react";
import MinimalEur from "./icons/currency_euro.svg?react";
import DownloadDoc from "./icons/Documents_PDF.svg?react";
import GlobalIndex from "./icons/GlobalIndex.svg?react";
import WalletGrey from "./icons/wallet_grey.svg?react";
import PortfolioIcon from "./icons/PortfolioIcon.svg?react";
import Knight from "./icons/Knight.svg?react";
import MenuProfile from "./icons/menu_profile.svg?react";
import PaginationArrow from "./icons/pagination_arrow.svg?react";
import PaginationArrowDisabled from "./icons/pagination_arrow_disabled.svg?react";
import PaginationArrowBig from "./icons/pagination_arrow_big.svg?react";
import PaginationArrowBigDisabled from "./icons/pagination_arrow_big_disabled.svg?react";
import Plans from "./icons/plans.svg?react";
import Umbrella from "./icons/umbrella.svg?react";
import Report from "./icons/report.svg?react";
import Portfel from "./icons/portfel.svg?react";
import AccountCardIcon from "./icons/accountCard.svg?react";
import Accomplished from "./icons/accomplished.svg?react";
import AssetsEuro from "./icons/euro.svg?react";
import AssetsDirham from "./icons/dirham.svg?react";
import AssetsCNY from "./icons/cny.svg?react";
import AssetsDollar from "./icons/dollar.svg?react";
import AssetsRub from "./icons/rub.svg?react";
import Portfolio from "./icons/portfolio.svg?react";
import Check_details from "./icons/check_details.svg?react";
import Personal from "./icons/personal.svg?react";
import Ru from "./icons/ru.svg?react";
import En from "./icons/en.svg?react";
import TestIcon from "./icons/test.svg?react";
import BankSection from "./icons/bankSection.svg?react";
import DocumentsIcon from "./icons/documentsIcon.svg?react";
import ConfirmationDocs from "./icons/ConfirmationDocs.svg?react";
import File from "./icons/File.svg?react";
import MessageIcon from "./icons/MessageIcon.svg?react";
import ChatBox from "./icons/ChatBox.svg?react";
import MarginTrading from "./icons/MarginTrading.svg?react";
import CallIcon from "./icons/CallIcon.svg?react";
import ClockIcon from "./icons/ClockIcon.svg?react";
import CheckIcon from "./icons/CheckIcon.svg?react";
import CloseIcon from "./icons/CloseIcon.svg?react";
import LockIcon from "./icons/LockIcon.svg?react";
import LoupeIcon from "./icons/LoupeIcon.svg?react";
import QuestionMarkIcon from "./icons/QuestionMarkIcon.svg?react";
import TrustIcon from "./icons/TrustIcon.svg?react";
import WarningIcon from "./icons/WarningIcon.svg?react";
import TooltipIcon from "./icons/tooltip-icon.svg?react";
import ExclaminationIcon from "./icons/exclamation_icon.svg?react";
import ForYouBigIcon from "./icons/for_you_big_icon.svg?react";
import TabsBank from "./icons/tabs_bank.svg?react";
import TabsDocument from "./icons/tabs_documents.svg?react";
import TabsPortfolio from "./icons/tabs_porftolio.svg?react";
import CrossStandart from "./icons/cross_standart.svg?react";
import DocumentsSectionPic1 from "./icons/documentsSectionPic1.svg?react";
import DocumentsSectionPic2 from "./icons/documentsSectionPic2.svg?react";
import DocumentsSectionPic3 from "./icons/documentsSectionPic3.svg?react";
import DocumentsSectionPic4 from "./icons/documentsSectionPic4.svg?react";
import SquareThreePoints from "./icons/squareThreePoints.svg?react";
import ProfileIcon from "./icons/profile_icon.svg?react";
import Notification from "./icons/notification.svg?react";

/*
Правила добавления иконок:

1. Прежде чем добавить новую икноку, запускаем storybook и смотрим на наличие вашей икноки.
2. Имя новой иконки должно отражать ее суть, а не место применения, вторым словом можно отразить характеристику. 
Пример: LockIcon, LockCircleIcon.
3. Если иконка одноцветная, то в файле svg меняем цвет на "currentColor", чтобы потом задать ей цвет через prop color.
4. Если иконка емеет background, то добавляем ее без него. Все доболнительные обертки можно создать в styles или через prop withBackground.
*/
export const IconMap = {
  chatBox: ChatBox,
  profileIcon: ProfileIcon,
  squareThreePoints: SquareThreePoints,
  documentsSectionPic1: DocumentsSectionPic1,
  documentsSectionPic2: DocumentsSectionPic2,
  documentsSectionPic3: DocumentsSectionPic3,
  documentsSectionPic4: DocumentsSectionPic4,
  CrossStandart,
  tabsPortfolio: TabsPortfolio,
  tabsDocument: TabsDocument,
  tabsBank: TabsBank,
  forYouBigIcon: ForYouBigIcon,
  exclaminationIcon: ExclaminationIcon,
  tooltipIcon: TooltipIcon,
  clockIcon: ClockIcon,
  callIcon: CallIcon,
  checkIcon: CheckIcon,
  closeIcon: CloseIcon,
  lockIcon: LockIcon,
  loupeIcon: LoupeIcon,
  questionMarkIcon: QuestionMarkIcon,
  trustIcon: TrustIcon,
  warning: WarningIcon,
  arrowDownwardRounded: ArrowDownwardRoundedSvg,
  accountInvestment: AccountInvestment,
  building: Building,
  education: Education,
  changeLang: ChangeLangSvg,
  candles: Candles,
  checkedCheckbox: CheckedCheckbox,
  checkbox: Checkbox,
  checkboxCurrent: CheckboxCurrent,
  dot: Dot,
  goForward: GoForwardSvg,
  button_plus: ButtonPlus,
  bell: Bell,
  profile: Profile,
  default_product: DefaultProduct,
  minusButton: MinusButton,
  plusButton: PlusButton,
  trashIcon: TrashIcon,
  burgerMinimal: BurgerMinimalButton,
  coupon: Coupon,
  informationGrey: InformationGrey,
  reject: Reject,
  Clear: Clear,
  modalCross: ModalCross,
  blockAccount: BlockAccount,
  authError: AuthError,
  accountAlreadyRegistered: AccountAlreadyRegistered,
  SuccessRegister: SuccessRegister,
  triangle: Triangle,
  i: I,
  docs: Docs,
  docsBlack: DocsBlack,
  like: Like,
  likeFilled: LikeFilled,
  likeFilter: LikeFilter,
  likeFilterFilled: LikeFilterFilled,
  lenta: Lenta,
  flag: Flag,
  structureProduct24: StructureProduct24,
  trade: Trade,
  goldStar: GoldStar,
  selectArrow: SelectArrow,
  dateCalendarBlack: DateCalendaBlack,
  checkboxDisable: CheckboxDisable,
  checkboxGreen: CheckboxGreen,
  checkedCheckboxDisable: CheckedCheckboxDisable,
  blackCircle: BlackCircle,
  grayCircle: GrayCircle,
  crownGold: CrownGold,
  crown: Crown,
  crownEmpty: CrownEmpty,
  mainExit: MainExit,
  question: Question,
  numbersRound1: NumbersRound1,
  numbersRound2: NumbersRound2,
  numbersRound3: NumbersRound3,
  numbersRound4: NumbersRound4,
  numbersRound5: NumbersRound5,
  numbersRound6: NumbersRound6,
  numbersRound7: NumbersRound7,
  numbersRound8: NumbersRound8,
  numbersRound9: NumbersRound9,
  minimalDollar: MinimalDollar,
  minimalRub: MinimalRub,
  minimalCny: MinimalCny,
  minimalDh: MinimalDh,
  minimalEur: MinimalEur,
  downloadDoc: DownloadDoc,
  globalIndex: GlobalIndex,
  walletGrey: WalletGrey,
  portfolioIcon: PortfolioIcon,
  menuProfile: MenuProfile,
  paginationArrow: PaginationArrow,
  paginationArrowDisabled: PaginationArrowDisabled,
  paginationArrowBig: PaginationArrowBig,
  paginationArrowBigDisabled: PaginationArrowBigDisabled,
  plans: Plans,
  umbrella: Umbrella,
  report: Report,
  portfel: Portfel,
  accountCardIcon: AccountCardIcon,
  accomplished: Accomplished,
  assetsEuro: AssetsEuro,
  assetsDirham: AssetsDirham,
  assetsCNY: AssetsCNY,
  assetsDollar: AssetsDollar,
  assetsRub: AssetsRub,
  portfolio: Portfolio,
  check_details: Check_details,
  knight: Knight,
  personal: Personal,
  testIcon: TestIcon,
  bankSection: BankSection,
  documentsIcon: DocumentsIcon,
  confirmationDocs: ConfirmationDocs,
  file: File,
  messageIcon: MessageIcon,
  marginTrading: MarginTrading,
  bankIcon: BankIcon,
  arrowFilledSelect: ArrowFilledSelect,
  bonds: Bonds,
  document: Document,
  sortAsc: SortAsc,
  sortDesc: SortDesc,
  more: More,
  trashEmptyIcon: TrashEmptyIcon,
  replenishment: Replenishment,
  withdraw: Withdraw,
  cboe: Cboe,
  reports: Reports,
  downloadArrow: DownloadArrow,
  DMAChartListItem: DMAChartListItem,
  pdfDocument: PdfDocument,
  tableDoubleArrow: TableDoubleArrow,
  check: Check,
  crossIcon: CrossIcon,
  circleLock: Lock,
  attentionIcon: AttentionIcon,
  chat: ChatIcon,
  appleApp: AppleApp,
  eyeShow: EyeShow,
  eyeHide: EyeHide,
  androidApp: AndroidApp,
  facebook: Facebook,
  instagram: Instagram,
  infoIcon: InfoIcon,
  favorites: Favorites,
  favoritesFilled: FavoritesFilled,
  deligatedManagement: DeligatedManagement,
  terminal: Terminal,
  chartPie: ChartPie,
  chartLines: ChartLines,
  marginTrade: MarginTrade,
  appQR: AppQR,
  marvelIcon: MarvelIcon,
  analogDevicesIcon: AnalogDevicesIcon,
  dynatraceIcon: DynatraceIcon,
  alphabetIcon: AlphabetIcon,
  newmontIcon: NewmontIcon,
  visaIcon: VisaIcon,
  chevronIcon: ChevronIcon,
  usdIcon: UsdIcon,
  circleIcon1: CircleIcon1,
  circleIcon2: CircleIcon2,
  circleIcon3: CircleIcon3,
  circleIcon4: CircleIcon4,
  circleIcon5: CircleIcon5,
  currrencyCny: CurrencyCny,
  currrencyEur: CurrencyEur,
  currrencyRub: CurrencyRub,
  currrencyUsd: CurrencyUsd,
  defaultProductIcon: DefaultProductIcon,
  forward: Forward,
  alto: Alto,
  doneIcon: DoneIcon,
  infoNoticeIcon: InfoNoticeIcon,
  noticeAlert: NoticeAlert,
  couponDate: CouponDate,
  chevronAccordion: ChevronAccordion,
  pending: Pending,
  errorOrderStatus: ErrorOrderStatus,
  sortingDesc: SortingDesc,
  successStatus: SuccessStatus,
  sortingIcon: SortingIcon,
  sortingDefault: SortingDefault,
  chartArrowRight: ChartArrowRight,
  profitabilityUp: ProfitabilityUp,
  risk: Risk,
  mobile: Mobile,
  lock: LockFilled,
  ru: Ru,
  en: En,
  note: Note,
  appleAppQr: AppleAppQr,
  androidAppQr: AndroidAppQr,
  checkboxFilter: CheckboxFilter,
  checkboxEmptyFilter: CheckboxEmptyFilter,
  percent: Percent,
  preIpo: PreIpo,
  reload: Reload,
  flash: Flash,
  buy: Buy,
  sell: Sell,
  sunrun: Sunrun,
  tranche: Tranche,
  emptyFolder: EmptyFolder,
  fire: Fire,
  calendarIcon: CalendarIcon,
  currency: Currency,
  shield: Shield,
  percentCircle: PercentCircle,
  filter: Filter,
  timeCurrentIcon: TimeCurrentIcon,
  information_light: Information_light,
  swapRepo: SwapRepo,
  invalid_browser: Invalid_browser,
  copy: Copy,
  favoritesAdd: FavoritesAdd,
  folder: Folder,
  folderSimple: FolderSimple,
  favoritesDelete: FavoritesDelete,
  editIcon: EditIcon,
  riskIcon: RiskIcon,
  testingIcon: TestingIcon,
  worldIcon: WorldIcon,
  chevronSmall: ChevronSmall,
  folderAdd: FolderAdd,
  addToFolder: AddToFolder,
  notification: Notification,
};

export type IconNameType = keyof typeof IconMap;

interface ImgIconProps extends IconProps {
  icon?: string;
}

const useStyles = makeStyles<Pick<IconProps, "name" | "size" | "color" | "withBackground">>()(
  (_, { name, size, color, withBackground }) => ({
    root: {
      width: 20,
      height: 20,
    },
    size: {
      width: size,
      height: size,
    },
    color: {
      color,
    },
    withBackground: {
      padding: withBackground?.padding,
      borderRadius: withBackground?.borderRadius,
      backgroundColor: withBackground?.backgroundColor,
      width: withBackground?.width || "fit-content",
      height: withBackground?.height,
      display: withBackground?.display || "block",
      alignItems: withBackground?.alignItems || "initial",
      justifyContent: withBackground?.justifyContent || "initial",
      margin: withBackground?.margin,
    },
    name: {
      backgroundImage: name && IconMap[name] ? `url("${IconMap[name]}")` : "none",
      backgroundRepeat: "no-repeat",
    },
  })
);

const ImgIcon = ({ className, name, size = 20, icon, fallbackSrc }: ImgIconProps): ReactElement => {
  const { classes } = useStyles({ name, size });
  const [imageSrc, setImageSrc] = useState<string | undefined>(icon);

  const handleImageError = () => {
    if (fallbackSrc) {
      setImageSrc(fallbackSrc);
    }
  };

  return <img src={imageSrc} className={cn(classes.size, className)} onError={handleImageError} />;
};

/**
 *
 * @param className
 * @param name - name of svg component from map
 * @param color - color of svg component
 * @param withBackground - styles for svg wrapper bacground
 * @param size - default is 20px
 * @param icon - SVGComponent(can be controlled by classname) or image source(for backend img urls)
 * @param fallbackSrc - if there is no image source(from backend) change it on fallbackSrc
 * @returns react svg component, img or svg element
 */

const Icon = ({
  className,
  name,
  size = 20,
  icon,
  fallbackSrc,
  color = colorTable.charcoalBlack,
  withBackground,
  wrapperClassName,
  onClick,
}: IconProps): ReactElement => {
  const { classes } = useStyles({ name, size, color, withBackground });

  if (icon) {
    if (typeof icon === "string") {
      return <ImgIcon className={className} name={name} size={size} icon={icon} fallbackSrc={fallbackSrc} />;
    }

    const SVGIcon = icon;
    return <SVGIcon className={cn(classes.size, className)} />;
  }

  const IconComponent = name && IconMap[name];

  return (
    <>
      {!!IconComponent && (
        <Box className={cn(classes.withBackground, wrapperClassName)} onClick={onClick}>
          <IconComponent className={cn(classes.size, classes.color, className)} />
        </Box>
      )}
    </>
  );
};

export default Icon;
