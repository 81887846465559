import { makeStyles } from "tss-react/mui";

import colorTable from "~/shared/ui-kit/constants/colors";
import { Theme } from "~/configs/theme";

export const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    padding: "24px",
    width: "100%",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "space-between",
    background: colorTable.lightOrange,
    cursor: "pointer",
    [theme.breakpoints.down(768)]: {
      padding: "12px",
    },
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  footerTextWrapper: {
    [theme.breakpoints.down(768)]: {
      gap: "0px",
    },
  },
  color: {
    color: colorTable.orange,
  },
  footerTitle: {
    [theme.breakpoints.down(768)]: {
      fontSize: "14px",
    },
  },
  footerDescription: {
    [theme.breakpoints.down(768)]: {
      fontSize: "12px",
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    transform: "rotate(-90deg)",
  },
  iconMobile: {
    transform: "initial",
  },
}));
