import { makeStyles } from "tss-react/mui";

import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()({
  container: {
    padding: "24px",
    borderRadius: "16px",
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.08), 0 1px 1px 0 rgba(0, 0, 0, 0.04)",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  head: {
    display: "flex",
    justifyContent: "space-between",

    "& > div": {
      display: "flex",
      gap: "16px",
    },
  },
  titleWrap: {
    "& > span:last-child": {
      color: colorTable.charcoalBlack,
    },
  },
  subtitle: {
    color: colorTable.moonGrey,
  },
});
