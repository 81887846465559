import { cast, Yup } from "~/lib/validation";

export interface BankResponseItem {
  city: string;
  country: string;
  fullName: string;
  swiftCode: string;
}

export interface BankResponse {
  items: BankResponseItem[];
  itemsCount: number;
}

export const schema = Yup.object<BankResponse>({
  items: Yup.array().of<BankResponseItem>(
    Yup.object<BankResponseItem>({
      city: Yup.string().required(),
      country: Yup.string().required(),
      fullName: Yup.string().required(),
      swiftCode: Yup.string().required(),
    }).label("BankResponseItem"),
  ),
  itemsCount: Yup.number().required(),
}).label("BankResponse");

export const caseBankResponse = cast<BankResponse>(schema);
