import { createAsyncReducer } from "~/lib/redux";

import {
  anketaGetInvestSectionStartAction,
  anketaGetInvestSectionSuccessAction,
  anketaGetInvestSectionErrorAction,
} from "../actions";

export const STATE_NAME = "investSection";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const [reducer, defaultState] = createAsyncReducer(
  anketaGetInvestSectionStartAction,
  anketaGetInvestSectionSuccessAction,
  anketaGetInvestSectionErrorAction
);

export default reducer;
