import { SelectChangeEvent } from "@mui/material";
import { Event } from "effector";

import { CODE_ISO_DUBAI } from "~/constants/enums";
import { notDigitPattern } from "~/constants/regexp";
import { CountryCode } from "~/entities/keycloak";
import parser from "~/lib/inputMaskParser";

export const setCountry = (
  e: SelectChangeEvent<string>,
  countryCodes: CountryCode[],
  phoneNumber: string,
  onChangeInput: (value: string) => string,
  setCountryCode: Event<string>,
  codeIso: string
) => {
  const countryCodeCurrent = countryCodes.find((code) => code.codeIso === codeIso) ?? null;
  const countryCodeFuture = countryCodes.find((code) => code.codeIso === e.target.value) ?? null;

  if (countryCodeCurrent && countryCodeFuture) {
    const newPhoneValue = countryCodeFuture.phoneCode + phoneNumber.slice(countryCodeCurrent.phoneCode.length);

    onChangeInput(newPhoneValue);
  }

  setCountryCode(e.target.value);
};

export function getCountryByPhonePrefix(
  phoneNumber: string,
  countryCodes: CountryCode[],
  codeIso: string,
  setCountryCode: Event<string>
) {
  const phoneNumberWithoutNonDigits = phoneNumber.replace(notDigitPattern, "");

  let foundCountry: string | null = null;

  let allPossibleCountry: CountryCode[] = [];

  for (let length = 1; length <= 4; length++) {
    const possiblePrefix = "+" + phoneNumberWithoutNonDigits.substring(0, length);

    const possibleCountry = countryCodes.filter((country) => country.phoneCode === possiblePrefix);

    if (!!possibleCountry.length) {
      allPossibleCountry = [...allPossibleCountry, ...possibleCountry];
      foundCountry = possibleCountry[0].codeIso;
    }
  }

  const isCurrentCountryInPossibleCountry = !!allPossibleCountry.find((code) => code.codeIso === codeIso);

  if (allPossibleCountry.length > 1 && isCurrentCountryInPossibleCountry) {
    let firstCountyCode = allPossibleCountry[0].phoneCode;
    const countryWithOnePhoneCode = allPossibleCountry.filter((code) => code.phoneCode === firstCountyCode);

    if (countryWithOnePhoneCode.length === allPossibleCountry.length) {
      return;
    }
  }

  if (foundCountry) {
    setCountryCode(foundCountry);
  }
}

const stringToNumber = (str: string): number => {
  const match = str.match(/\[(\d+)\]/);

  if (match) {
    return Number(match[1]);
  }

  return 0;
};

export type TMasksForDubai = {
  sum: number;
  mask: string;
}[];

export const getMasksForDubai = (phoneCode: string, phoneCodeLength: number): TMasksForDubai => {
  const parts = phoneCode.split(" ");
  // Ищем числа в квадратных скобках с помощью регулярного выражения
  const variantsString = parts[0].match(/\[([^\]]+)\]/)?.[1] ?? "[1]";
  // Пропускаем первую часть маски "1[1,2,3]" и получаем оставшуюся длину маски
  const sumLastPart = parts.slice(1).reduce((acc, value) => acc + stringToNumber(value), 0);

  // Разделяем числа по запятой и преобразуем их в массив чисел
  const variantsNumbers = variantsString.split(",").map(Number);

  const result = variantsNumbers.map((number) => ({
    sum: number + sumLastPart + phoneCodeLength,
    mask: `1[${phoneCodeLength}] 1[${number}] ${parts.slice(1).join(" ")}`,
  }));

  return result;
};

export const isFieldPhoneNumber = (phoneNumber: string, countryCodes: CountryCode[], codeIso: string) => {
  if (phoneNumber && countryCodes && codeIso) {
    const phoneNumberOnlyDigitsLength = ("+" + phoneNumber?.replace(notDigitPattern, "")).length;
    const currentCountry = countryCodes?.find((country) => country.codeIso === codeIso);
    const phoneCodeLength = currentCountry?.phoneCode?.length ? currentCountry?.phoneCode?.length : 0;

    if (!currentCountry?.phoneMask) {
      return phoneNumberOnlyDigitsLength > phoneCodeLength;
    }

    if (codeIso === CODE_ISO_DUBAI) {
      const masksForDubai = currentCountry ? getMasksForDubai(currentCountry?.phoneMask, phoneCodeLength) : null;

      return !!masksForDubai?.some((mask) => mask.sum === phoneNumberOnlyDigitsLength);
    }

    const phoneLength = parser
      .parse(currentCountry?.phoneMask ? `1[${phoneCodeLength}] ` + currentCountry?.phoneMask : "")
      .replace(notDigitPattern, "").length;

    return phoneNumberOnlyDigitsLength === phoneLength;
  }
};
