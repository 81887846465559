import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { deleteToken } from "@firebase/messaging";
import { clientChannels } from "~/constants/enums";

export const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

export const FIREBASE_VAPID_KEY = import.meta.env.VITE_FIREBASE_VAPID_KEY;

const initMessaging = () => {
  const xChannel = localStorage.getItem("X-Channel") || clientChannels.UCF_WEB;
  return xChannel === clientChannels.UCF_WEB ? getMessaging(firebaseApp) : undefined;
};

export const firebaseApp = initializeApp(firebaseConfig);
export const messaging = initMessaging();

export const requestForToken = async () => {
  if (messaging) return await getToken(messaging, { vapidKey: FIREBASE_VAPID_KEY });
};

export const registerServiceWorker = async () => {
  const xChannel = localStorage.getItem("X-Channel") || clientChannels.UCF_WEB;
  if (typeof Notification === "undefined" || xChannel !== clientChannels.UCF_WEB) return;
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register("/firebase-messaging-sw.js");
      return registration;
    } catch (error) {
      console.error("Service Worker registration failed:", error);
    }
  } else {
    console.warn("Service Worker is not supported in this browser.");
  }
};

export const deleteTokenFb = async () => {
  if (messaging) await deleteToken(messaging);
};
