import { createDomain, forward, sample } from "effector";
import { createGate } from "effector-react";

import { edmClientStatuses, STATUSES_W8_CAN_NOT_START_PROCESS, StatusW8Enum } from "~/constants/enums";
import { TClientCurrent } from "~/entities/current-client/types";
import { dmaModel } from "~/modules/dma/dma-model";
import { edmService } from "~/pages/EDM/services/edm-services";
import { clientCardModel } from "../client-card-model";
import { currentClientModel } from "../current-client-model";

const clientProfileDomain = createDomain("client profile domain");
const gate = createGate({ domain: clientProfileDomain });

const $clientProfile = clientProfileDomain.createStore<TClientCurrent>({} as TClientCurrent);
$clientProfile.on(dmaModel.getCurrentClientFx.doneData, (_, data) => data);
$clientProfile.reset(gate.close);

const $showW8Button = clientProfileDomain.createStore<boolean>(false);

const $showW8Banner = clientProfileDomain.createStore<boolean>(false);

forward({ from: gate.open, to: dmaModel.getCurrentClientFx });

sample({
  clock: gate.open,
  source: clientCardModel.$hasBoOrDu,
  fn: (hasBoOrDu) => {
    if (import.meta.env.VITE_W8_HIDDEN === "true") return false;

    return hasBoOrDu;
  },
  target: $showW8Button,
});

sample({
  source: [$showW8Button, currentClientModel.$currentClient] as const,
  fn: ([showW8Button, currentClient]) =>
    showW8Button &&
    !STATUSES_W8_CAN_NOT_START_PROCESS.includes(currentClient?.w8Form?.status ?? StatusW8Enum.NOT_ISSUED),
  target: $showW8Banner,
});

sample({
  source: dmaModel.getCurrentClientFx.doneData,
  fn: (data: any) => Object.values(edmClientStatuses).includes(data?.clientStatus),
  target: edmService.$isEdm,
});

export const clientProfileModel = {
  $clientProfile,
  $clientProfilePending: dmaModel.getCurrentClientFx.pending,
  gate,
  $showW8Button,
  $showW8Banner,
};
