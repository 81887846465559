// eslint-disable-next-line no-restricted-imports
import { initReactI18next } from "react-i18next";
import i18n, { TFunction } from "i18next";

import { i18nextPostProcessor } from "~/lib/date";
import { formatMoney, formatPercent } from "~/lib/numeral";
import en from "./locales/en.yaml";
import format from "./locales/format.yaml";
import ru from "./locales/ru.yaml";
import { CLIENT_EMAIL, INFO_EMAIL } from "~/constants/common.ts";

const replaceVariablesInTranslations = (
  obj: string | Record<string, unknown>,
  variables: Record<string, string>
): string | Record<string, unknown> => {
  if (typeof obj === "string") {
    let result = obj;
    for (const [key, value] of Object.entries(variables)) {
      const placeholder = `{{${key}}}`;
      result = result.replace(new RegExp(placeholder, "g"), value);
    }
    return result;
  } else if (typeof obj === "object" && obj !== null) {
    return Object.keys(obj).reduce(
      (acc, key) => {
        acc[key] = replaceVariablesInTranslations(obj[key] as string | Record<string, unknown>, variables);
        return acc;
      },
      {} as Record<string, unknown>
    );
  }
  return obj;
};

const translationsWithVariablesRu = replaceVariablesInTranslations(ru, {
  clientEmail: CLIENT_EMAIL,
  infoEmail: INFO_EMAIL,
});
const translationsWithVariablesEn = replaceVariablesInTranslations(en, {
  clientEmail: CLIENT_EMAIL,
  infoEmail: INFO_EMAIL,
});

i18n
  .use(initReactI18next)
  .use(i18nextPostProcessor)
  .init({
    resources: {
      en: {
        translation: translationsWithVariablesEn,
        format,
      },
      ru: {
        translation: translationsWithVariablesRu,
        format,
      },
    },
    lng: "en",
    fallbackLng: "en",
    ns: ["translation", "format"],
    defaultNS: "translation",
    debug: import.meta.env.MODE === "development",
    react: {
      useSuspense: false,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "ul", "li", "ol"],
    },
    interpolation: {
      escapeValue: false,
      format: (value, formatValue, lng): string => {
        switch (formatValue) {
          case "money-with-decimal":
          case "money-without-decimal":
            return formatMoney(lng || "", value, "{{ currency }}", formatValue === "money-with-decimal");
          case "percent-with-decimal":
          case "percent-without-decimal":
            return formatPercent(lng || "", value, formatValue === "percent-with-decimal");
        }
        return value;
      },
    },
  });

// @ts-ignore
export const t: TFunction = (...props) => i18n.t(...props);

// eslint-disable-next-line no-restricted-imports
export type { WithTranslation } from "react-i18next";
// eslint-disable-next-line no-restricted-imports
export { Trans, Translation, useTranslation, withTranslation } from "react-i18next";
export default i18n;
