import { createAsyncReducer } from "~/lib/redux";

import {
  requestsPrintFormsClearAction,
  requestsPrintFormsStartAction,
  requestsPrintFormsSuccessAction,
} from "../actions";

export const STATE_NAME = "printForms";

const [reducer, defaultState] = createAsyncReducer(
  requestsPrintFormsStartAction,
  requestsPrintFormsSuccessAction,
  requestsPrintFormsClearAction
);

export type RequestsPrintFormsState = typeof defaultState;

export default reducer;
