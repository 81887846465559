import { createAsyncReducer } from "~/lib/redux";

import {
  requestsPostFundsWithdrawalClearAction,
  requestsPostFundsWithdrawalStartAction,
  requestsPostFundsWithdrawalSuccessAction,
} from "../actions";

export const STATE_NAME = "postFundsWithdrawal";

const [reducer, defaultState] = createAsyncReducer(
  requestsPostFundsWithdrawalStartAction,
  requestsPostFundsWithdrawalSuccessAction,
  // @ts-ignore vite
  requestsPostFundsWithdrawalClearAction
);

export type RequestsPostFundsWithdrawalState = typeof defaultState;

export default reducer;
