import { createAsyncActions } from "~/lib/redux";

import {
  BOBrokerReportRequestDTO,
  BrokerReportRequestDTO,
  FundsWithdrawalRequestDTO,
  FundsWithdrawalResponseDTO,
} from "~/entities/requests";

export const [
  requestsPostBrokerReportStartAction,
  requestsPostBrokerReportSuccessAction,
  requestsPostBrokerReportClearAction,
] = createAsyncActions<BOBrokerReportRequestDTO, BrokerReportRequestDTO>("REQUESTS_POST_BROKER_REPORT");

export const [
  requestsPostFundsWithdrawalStartAction,
  requestsPostFundsWithdrawalSuccessAction,
  requestsPostFundsWithdrawalClearAction,
] = createAsyncActions<FundsWithdrawalResponseDTO, FundsWithdrawalRequestDTO>("REQUESTS_POST_FUNDS_WITHDRAWAL");

export interface PrintFiles {
  requestId: string;
  printFiles: { name: string; data: string } | null;
}

export const [
  requestsPrintFormsStartAction,
  requestsPrintFormsSuccessAction,
  requestsPrintFormsErrorAction,
  requestsPrintFormsClearAction,
] = createAsyncActions<PrintFiles, string>("REQUESTS_PRINT_FORMS");
