import { memo, useEffect } from "react";
import { getUserTokenInfo } from "~/modules/userToken/api";

export const NewChat = memo(() => {
  const clientId = getUserTokenInfo().id;

  useEffect(() => {
    window.contextProvider = () => ({
      CustomerID: { value: clientId, isDisplayable: true }, // id клиента
    });

    // Функция для передачи контекста
    window.callContext = () => {
      if (window.Microsoft && window.Microsoft.Omnichannel && window.Microsoft.Omnichannel.LiveChatWidget) {
        window.Microsoft.Omnichannel.LiveChatWidget.SDK.setContextProvider(window.contextProvider);
      }
    };

    // Функция отвечающая за костомизацию чата дока с параметрами для костамизации
    window.lcw = () => {
      return {
        styleProps: {
          generalStyles: {
            top: "unset",
            left: "unset",
            bottom: "70px",
            right: "30px",
          },
        },
        headerProps: {
          controlProps: {
            hideCloseButton: true,
          },
        },
      };
    };

    if (!document.getElementById("Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window")) {
      window.addEventListener("lcw:ready", function handleLivechatReadyEvent() {
        window.callContext();
      });

      const isProduction = import.meta.env.VITE_PUBLIC_URL === "/online/";
      const script = document.createElement("script");
      script.id = "Microsoft_Omnichannel_LCWidget";
      script.src = "https://oc-cdn-ocuae-uae.azureedge.net/livechatwidget/scripts/LiveChatBootstrapper.js";
      script.setAttribute("data-lcw-version", "prod");
      script.setAttribute("data-color-override", "#2E2F31");
      script.setAttribute("data-customization-callback", "lcw");

      if (isProduction) {
        script.setAttribute("data-app-id", "4c95e7cb-4179-499e-b424-1e881bcf70f2");
        script.setAttribute("data-org-id", "c68e8b98-09c4-ef11-b8e5-6045bd14c152");
        script.setAttribute(
          "data-org-url",
          "https://m-c68e8b98-09c4-ef11-b8e5-6045bd14c152.ae.omnichannelengagementhub.com"
        );
      } else {
        script.setAttribute("data-app-id", "c775760f-a83b-491d-9452-a29e52f1ccfb");
        script.setAttribute("data-org-id", "067c9115-55ae-ef11-8a67-6045bd6a237b");
        script.setAttribute(
          "data-org-url",
          "https://m-067c9115-55ae-ef11-8a67-6045bd6a237b.ae.omnichannelengagementhub.com"
        );
      }

      document.body.appendChild(script);
    }

    return () => {
      document.querySelectorAll("#Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window").forEach((node) => node.remove());
    };
  }, []);

  return null;
});
