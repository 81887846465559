import { BuyingDocument, BuyProductResult, Commission } from "~/entities/product";
import { createAction, createAsyncActions } from "~/lib/redux";

export const [getProductCommissionStartAction, getProductCommissionSuccessAction, getProductCommissionErrorAction] =
  createAsyncActions<Commission, { agreementNumber: string; investmentSum: number }>("PRODUCT_COMMISSION");

export const [buyProductStartAction, buyProductSuccessAction, buyProductErrorAction, buyProductClearAction] =
  createAsyncActions<
    { applicationId: BuyProductResult; printForms: BuyingDocument[] },
    { agreementNumber: string; investmentSum: number }
  >("PRODUCT_BUY");

export const productClearAction = createAction("PRODUCT_CLEAR");
