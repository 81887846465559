import React, { useEffect } from "react";
import { cssTransition, ToastContainer } from "react-toastify";
import cn from "classnames";
import { makeStyles } from "tss-react/mui";
import "react-toastify/dist/ReactToastify.css";
import "./app.css";

import { Router } from "~/app/router";
import { registerServiceWorker } from "~/configs/firebase.ts";

const useStyles = makeStyles()({
  wrapper: {
    overflow: "initial",
    wordBreak: "break-word",
    "& .Toastify__toast": {
      padding: "16px",
      borderRadius: "8px",
    },
    "& .Toastify__toast-body": {
      padding: 0,
      margin: 0,
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
  mobileStyle: {
    width: "95%",
  },
});

const App = () => {
  const { classes } = useStyles();
  const zoom = cssTransition({
    enter: "zoom-enter",
    exit: "zoom-exit",
  });

  useEffect(() => {
    void registerServiceWorker();
  }, []);

  return (
    <>
      <ToastContainer
        theme="colored"
        className={cn(classes.wrapper)}
        position="top-right"
        transition={zoom}
        hideProgressBar
        autoClose={10000}
        limit={4}
        style={{ top: "1em" }}
      />
      <Router />
    </>
  );
};

export default App;
