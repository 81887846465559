import React, { memo } from "react";
import { Box, BoxProps, CircularProgress, Grid } from "@mui/material";
import cn from "classnames";
import { omit } from "lodash";

import colorTable from "~/shared/ui-kit/constants/colors";
import { useStyles } from "./styles";

interface Props extends BoxProps {
  height100?: boolean;
  size?: 16 | 28 | 42 | 54;
  children?: never;
  isPage?: boolean;
  className?: string;
  color?: colorTable;
}

const height100style = { height: "100%" };

const Loader = ({
  height100 = true,
  size = 42,
  isPage,
  className,
  color = colorTable.charcoalBlack,
  ...props
}: Props) => {
  const { classes } = useStyles({ color });

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={cn(isPage ? classes.loaderPage : "", className)}
      style={height100 ? height100style : undefined}
    >
      <Box {...omit(props, ["children"])}>
        <CircularProgress size={size} className={classes.colorPrimary} />
      </Box>
    </Grid>
  );
};

export default memo(Loader);
