import { createDomain, sample } from "effector";
import {
  deactivatePushToken,
  getPushHistory,
  getUnreadPushCount,
  readAllPushMessages,
  readPushById,
  registerPushToken,
} from "~/entities/notifications";
import { logoutModel } from "~/modules/keycloak/logout-model";
import { AxiosError } from "axios";

type TPushItem = {
  body: string;
  clientId: string;
  from: string | null;
  id: string;
  notificationType: string;
  sentDateTime: string | Date;
  status: string;
  subject: string | null;
  to: string;
};

const pushDomain = createDomain("notifications domain");

const registerPushTokenFx = pushDomain.createEffect({
  handler: registerPushToken,
});
const readPushByIdFx = pushDomain.createEffect({
  handler: readPushById,
});
const readAllMessagesFx = pushDomain.createEffect({
  handler: readAllPushMessages,
});
const getPushHistoryFx = pushDomain.createEffect({
  handler: getPushHistory,
});
const getNextPagePushHistoryFx = pushDomain.createEffect<any, any, AxiosError>({
  handler: getPushHistory,
});
const removePushTokenFx = pushDomain.createEffect({
  handler: deactivatePushToken,
});
const checkUnreadPushFx = pushDomain.createEffect({
  handler: getUnreadPushCount,
});

const $pushToken = pushDomain.createStore<string | null>(null);
const $pushHistory = pushDomain.createStore<TPushItem[]>([]);
const $unreadPushCount = pushDomain.createStore<number>(0);
const $isHistoryError = pushDomain.createStore<boolean>(false);

const setPushToken = pushDomain.createEvent<string>();
const registerPushTokenEv = pushDomain.createEvent<string>();
const readPushMessageEv = pushDomain.createEvent<string>();
const getPushHistoryEv = pushDomain.createEvent();
const removePushTokenEv = pushDomain.createEvent();
const checkUnreadPushEv = pushDomain.createEvent();
const readAllMessagesEv = pushDomain.createEvent();
const resetPushHistoryEv = pushDomain.createEvent();

$isHistoryError.reset([getPushHistoryFx.doneData]);
$isHistoryError.on(getPushHistoryFx.failData, () => true);

$pushHistory.reset([removePushTokenEv, logoutModel.logout, resetPushHistoryEv]);

$pushToken.on(setPushToken, (_, token) => token);
$pushToken.reset([removePushTokenEv, logoutModel.logout]);

const $currentPage = pushDomain.createStore<number>(0);
const incCurrentPage = pushDomain.createEvent();
$currentPage.on(incCurrentPage, () => $currentPage.getState() + 1);
$currentPage.reset([removePushTokenEv, logoutModel.logout, resetPushHistoryEv]);

const $hasMoreData = pushDomain.createStore<boolean>(true);
$hasMoreData.on(getNextPagePushHistoryFx.doneData, (_, data) => !data.last);
$hasMoreData.reset([removePushTokenEv, logoutModel.logout, resetPushHistoryEv]);

sample({
  clock: $currentPage,
  source: $currentPage,
  filter: (data) => data !== 0,
  target: getNextPagePushHistoryFx,
});

$pushHistory.on(getNextPagePushHistoryFx.doneData, (prevData, data) => [...prevData, ...data.content]);

sample({
  clock: registerPushTokenEv,
  fn: (token) => ({
    token,
    device: "WEB",
  }),
  target: registerPushTokenFx,
});

sample({
  clock: readPushMessageEv,
  target: readPushByIdFx,
});

sample({
  clock: [getPushHistoryEv, readPushByIdFx.doneData, readAllMessagesFx.doneData],
  target: getPushHistoryFx.prepend(() => {}),
});

sample({
  clock: [getPushHistoryEv, readPushByIdFx.doneData, readAllMessagesFx.doneData],
  target: checkUnreadPushFx,
});

sample({
  source: getPushHistoryFx.doneData,
  fn: (data) => data.content,
  target: $pushHistory,
});

sample({
  clock: [removePushTokenEv, logoutModel.logout],
  target: removePushTokenFx,
});

sample({
  clock: checkUnreadPushEv,
  target: checkUnreadPushFx,
});

sample({
  source: checkUnreadPushFx.doneData,
  target: $unreadPushCount,
});

sample({
  clock: readAllMessagesEv,
  target: readAllMessagesFx,
});

export const notificationService = {
  registerPushTokenEv,
  readPushMessageEv,
  getPushHistoryEv,
  $pushHistory,
  $unreadPushCount,
  setPushToken,
  $pushToken,
  removePushTokenEv,
  checkUnreadPushEv,
  readAllMessagesEv,
  $readAllMessagesFxPending: readAllMessagesFx.pending,
  $getPushHistoryFxPending: getPushHistoryFx.pending,
  $readPushByIdFxPending: readPushByIdFx.pending,
  resetPushHistoryEv,
  $isHistoryError,
  incCurrentPage,
  $hasMoreData,
  $getNextPagePushHistoryFxPending: getNextPagePushHistoryFx.pending,
};
