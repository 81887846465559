import { AxiosError, AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse } from "axios";
import { get, has } from "lodash";

import { appApi, AppError, AppResponseError } from "~/entities/app";
import {
  api,
  castKeycloakResponseFailModel,
  isErrorResponseShouldToUseRefreshToken,
  isTokenErrorResponse,
  KeycloakResponseError,
} from "~/entities/keycloak";
import tokenService from "~/lib/token";
import { technicalWorksModel } from "~/modules/app/technical-works-model";
import { logoutModel } from "~/modules/keycloak/logout-model";
import { notificationService } from "~/modules/notifications";

let isCheckingTechnicalWorks = false;

const checkTechnicalWorksCall = async () => {
  const res = await appApi.checkTechnicalWorks();
  return res;
};

export const refreshTokenCall = async (refreshToken: string): Promise<string> => {
  const result = await api.keycloakRefreshToken(refreshToken);
  tokenService.set(result);
  return result.access_token;
};

export const checkTechnicalWorksInterceptor = (instance: AxiosInstance) => {
  return instance.interceptors.request.use(
    (res): Promise<AxiosRequestConfig> => {
      if (!isCheckingTechnicalWorks) {
        isCheckingTechnicalWorks = true;
        checkTechnicalWorksCall()
          .then((data) => {
            if (data?.enabled) {
              technicalWorksModel.setTechWorkError(data);
            }
          })
          .finally(() => {
            isCheckingTechnicalWorks = false;
          });
      }
      return Promise.resolve(res);
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    }
  );
};

export const keycloakResponseModelInterceptor = (instance: AxiosInstance): number => {
  return instance.interceptors.response.use(
    (res): AxiosResponse => {
      const data = castKeycloakResponseFailModel(get(res, "data"));
      if (data) {
        throw new KeycloakResponseError(data);
      }

      return res;
    },
    (error: AxiosError): Promise<never> => {
      const data = has(error, "response.data.state")
        ? castKeycloakResponseFailModel(get(error, "response.data"))
        : null;

      if (data) {
        return Promise.reject(new KeycloakResponseError(data, error));
      }

      return Promise.reject(error);
    }
  );
};

export const appResponseErrorInterceptor = (instance: AxiosInstance): number => {
  return instance.interceptors.response.use(undefined, (error: AxiosError): Promise<never> => {
    if (error && !AppError.is(error)) {
      if (error.isAxiosError) {
        return Promise.reject(new AppResponseError(error));
      }

      return Promise.reject(new AppError(error));
    }

    return Promise.reject(error);
  });
};

let refreshCall: Promise<string> | undefined;

export const refreshTokenInterceptor = (instance: AxiosInstance): number => {
  const refreshTokenInterceptorId = instance.interceptors.response.use(
    undefined,
    (error: unknown): Promise<unknown> => {
      if (!isErrorResponseShouldToUseRefreshToken(error) || !AppResponseError.is(error)) {
        return Promise.reject(error);
      }

      const config = error.error.config;

      const t = tokenService.get();

      if (!t) {
        return Promise.reject(error);
      }

      refreshCall = refreshCall || refreshTokenCall(t.refresh_token);

      // Create interceptor that will bind all the others requests
      // until refreshCall is resolved
      const requestQueueInterceptorId = instance.interceptors.request.use(
        (c: AxiosRequestConfig): Promise<AxiosRequestConfig> =>
          refreshCall
            ? refreshCall.then((accessToken): AxiosRequestConfig => {
                c.headers.Authorization = `Bearer ${accessToken}`;
                return c;
              })
            : Promise.resolve(c)
      );

      return refreshCall
        .then((accessToken): AxiosPromise<unknown> => {
          instance.interceptors.request.eject(requestQueueInterceptorId);
          refreshCall = undefined;
          config.headers.Authorization = `Bearer ${accessToken}`;

          instance.interceptors.response.eject(refreshTokenInterceptorId);
          return instance(config).finally(() => {
            refreshTokenInterceptor(instance);
          });
        })
        .catch((e): Promise<unknown> => {
          if (
            e.error.response.status === 401 ||
            (e.error.response.data.error === "invalid_grant" && e.error.response.status === 400)
          ) {
            notificationService.removePushTokenEv();
            logoutModel.logout();
          }
          instance.interceptors.request.eject(requestQueueInterceptorId);
          refreshCall = undefined;
          if (isTokenErrorResponse(e)) {
            error.parent = e;
            return Promise.reject(error);
          }

          return Promise.reject(e);
        });
    }
  );

  return refreshTokenInterceptorId;
};
