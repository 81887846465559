import { clientChannels, LocalStorageKeysEnum } from "~/constants/enums";
import { ProcessBusinessResponse } from "~/entities/keycloak";
import i18n from "~/i18n";
import { ContentType, get, post, put } from "~/lib/request";
import { downloadBlob } from "~/lib/utils";
import {
  DownloadFileRequest,
  TCurrency,
  TIndustryResponse,
  TSearchCurrencyParams,
  UploadFile,
} from "~/pages/OpenAccount/services/types";

export const brokerServiceApi = {
  startBrokerServiceOnboarding: async (): Promise<ProcessBusinessResponse> => {
    const businessKey = localStorage.getItem(LocalStorageKeysEnum.onboardingBusinessKey);
    if (!!businessKey) {
      return {
        businessKey: businessKey,
      };
    } else {
      const res = await post("@auth/executor/v2/onboarding/broker-service", undefined, {
        headers: {
          "X-Channel": clientChannels.UCF_WEB,
          "Accept-Language": i18n.language,
        },
      });
      return res.data;
    }
  },

  getBrokerInfo: async (requestData: any): Promise<any> => {
    const res = await put("@auth/executor/v1/task/complete", { data: requestData });
    return res.data;
  },

  putTaskComplete: async (requestData: any): Promise<any> => {
    if (Object.keys(requestData).length !== 0) {
      const res = await put("@auth/executor/v1/task/complete", { data: requestData });
      return res.data;
    }
  },

  uploadingFiles: async (data: UploadFile): Promise<any> => {
    const uploadData = {
      files: data?.file,
      docType: data?.type,
    };
    const res = await post(
      `@auth/executor/v1/task/file?taskId=${data?.taskId}`,
      { data: uploadData },
      { contentType: ContentType.FormData }
    );
    return res.data;
  },

  downloadingFiles: async (data: DownloadFileRequest): Promise<any> => {
    const res = await get(`@auth/bcsfs/v1/client-files/file?fileId=${data?.fileId}`, {}, { isBlob: true });
    downloadBlob(res.data, `${data.name}`);
  },

  downloadingFilesList: async (): Promise<any> => {
    const res = await post("@auth/bcsfs/v1/client-files/list");
    return res.data;
  },

  getBanks: async (): Promise<any> => {
    const res = await get("@auth/bank/banks", { params: { size: 30 } });
    return res.data;
  },

  searchBanks: async (data: any): Promise<any> => {
    const params = {
      id: data.id,
      size: 30,
    };
    const res = await get("@auth/bank/banks", { params });
    return res.data;
  },

  searchSwifts: async (): Promise<any> => {
    const res = await get("@auth/bank/banks", { params: { size: 30 } });
    return res.data;
  },

  searchCurrency: async (data: TSearchCurrencyParams): Promise<[TCurrency]> => {
    const params = {
      alphabeticCode: data.code,
      size: 1,
    };
    const res = await get("@auth/country/currencies", { params });
    return res.data?.content;
  },

  getIndustries: async (): Promise<TIndustryResponse> => {
    const params = {
      page: 0,
      size: 20,
    };
    const res = await get("@auth/dictionary/industries", {
      params,
    });
    return res.data;
  },

  searchCurrencyAll: async (): Promise<TCurrency[]> => {
    const params = {
      size: 500,
    };
    const res = await get("@auth/country/currencies", { params });
    return res.data?.content;
  },

  getDocumentById: async (docId: number) => {
    const res = await get(`@auth/document/business/documents/file/base64?id=${docId}`);
    return res.data;
  },
};
