import { createElement } from "react";
import ReactDOM from "react-dom";
import "~/configs/appdynamics";
import "~/i18n";
import "~/lib/polyfills";

import Root from "~/app";

ReactDOM.render(createElement(Root), document.getElementById("app"));

if (import.meta.env.VITE_HOT_LOADER_ENABLED === "true" && import.meta.hot) {
  import.meta.hot.accept();
}
