// eslint-disable-next-line simple-import-sort/imports
import React, { useCallback, useEffect, useState } from "react";
import { matchPath } from "react-router-dom";
import { AppBar, Box, Container, Grid, Tab, Tabs, TabsActions, Toolbar, Tooltip, useMediaQuery } from "@mui/material";
import { useStore } from "effector-react";
import cn from "classnames";

import { useTranslation } from "~/i18n";
import AppIconViewport from "~/features/AppIconViewport";
import UserDropdownButton from "./components/UserDropdownButton";
import SwitchLocaleViewport from "~/features/SwitchLocaleViewport";
import ContentWrapperViewport from "~/features/ContentWrapperViewport";
import { ContactUs } from "~/features/ContactUs";
import { colors, colorsLogo, filtres, links } from "~/constants/enums";
import { headerModel } from "~/modules/header-model";
import { useHeaderStyles } from "~/app/layouts/BaseLayout/components/ViewportHeader/styles";
import { edmService } from "~/pages/EDM/services/edm-services";
import { urlBack, useRouterHooks } from "~/lib/router";
import { logoutModel } from "~/modules/keycloak/logout-model";
import colorTable from "~/shared/ui-kit/constants/colors";
import Icon from "~/shared/Icon";
import { Notification } from "~/app/layouts/BaseLayout/components/ViewportHeader/components/Notification";
import { notificationService } from "~/modules/notifications";

interface HeaderProps {
  unAuthorized?: boolean;
  isMenu?: boolean;
  className?: string;
}

const IS_NEW_HEADER = import.meta.env.VITE_NEW_HEADER_HIDDEN === "false";

// This callback updates tabsIndicator position after mount
const tabsActionCallback = (ref: TabsActions | null): void => {
  if (ref && ref.updateIndicator) {
    setTimeout(ref.updateIndicator, 100);
  }
};

const matchPaths = (pathname: string): { pathname: string } | null => {
  return (
    [links.operations, links.forYou]
      .map((item): { pathname: string } | null =>
        matchPath(
          {
            path: item,
          },
          pathname
        )
      )
      .find(Boolean) || null
  );
};

const ViewportHeader = ({ unAuthorized, isMenu, className }: HeaderProps) => {
  const { $isEdm } = edmService;

  const isEdm = useStore($isEdm);
  const { navigate, location } = useRouterHooks();

  const isMobile = useMediaQuery("(max-width: 768px)");

  const matches = matchPaths(location.pathname);

  const [isSideMenu, setIsSideMenu] = useState(false);

  const { t } = useTranslation();
  const { classes } = useHeaderStyles();

  const handleTabChange = useCallback(
    (_: unknown, value: string): void => {
      if (!matches || matches.pathname !== value || value !== location.pathname) {
        navigate(value);
      }
    },
    [navigate, matches, location.pathname]
  );

  const selected =
    location.pathname.includes(links.operations) || location.pathname.includes(links.openAssetManagement);
  const selectedForYou = location.pathname.includes(links.forYou);
  const selectedServices = location.pathname.includes(links.services);

  const handleOperations = useCallback((): void => {
    navigate(links.operationsAssets);
    setIsSideMenu(false);
  }, [navigate]);

  const handleForYou = useCallback((): void => {
    navigate(links.forYouCommon);
    setIsSideMenu(false);
  }, [navigate]);

  const handleShowDocs = useCallback((): void => {
    navigate(links.servicesDocuments, { state: { filterDefault: filtres.docs } });
    setIsSideMenu(false);
  }, [navigate]);

  const handleShowProfile = useCallback((): void => {
    navigate(links.profile);
    setIsSideMenu(false);
  }, [navigate]);

  const classesSelectedTab = useCallback((isSelected: boolean) => {
    return cn({
      [classes.selected]: isSelected && !IS_NEW_HEADER,
      [classes.tab]: !isSelected && !IS_NEW_HEADER,
      [classes.newSelected]: isSelected && IS_NEW_HEADER,
      [classes.newTab]: !isSelected && IS_NEW_HEADER,
    });
  }, []);

  useEffect(() => {
    document.body.style.width = "100%";

    return () => {
      document.body.style.width = "";
    };
  }, []);

  const { $headerTheme } = headerModel;
  const headerTheme = useStore($headerTheme);

  return (
    <AppBar
      position={headerTheme.isStaticMobileHeader ? "static" : "sticky"}
      className={cn(classes.appBar, className)}
      style={headerTheme.background ? { background: headerTheme.background } : {}}
    >
      {!unAuthorized && (
        <Box className={cn(classes.sideMenu, { [classes.isSideMenu]: isSideMenu })}>
          <Box className={classes.menuItem} onClick={handleShowProfile}>
            {t("MENU.USER.profile")}
          </Box>
          {!isEdm && (
            <>
              <Box className={classes.menuItem} onClick={handleOperations}>
                {t("MENU.HEADER.operations")}
              </Box>
              <Box className={classes.menuItem} onClick={handleForYou}>
                {t("MENU.HEADER.forYou")}
              </Box>
              <Box className={classes.menuItem} onClick={handleShowDocs}>
                {t("DOCUMENTS.label")}
              </Box>
            </>
          )}
          <Box
            className={classes.menuItem}
            onClick={() => {
              notificationService.removePushTokenEv();
              logoutModel.logout();
            }}
          >
            {t("MENU.USER.exit")}
          </Box>
        </Box>
      )}
      <Container className={classes.h100}>
        <ContentWrapperViewport className={classes.headerContentWrapper}>
          <Toolbar className={`${classes.h100} ${classes.headerContainer}`} disableGutters>
            <Grid item>
              {!unAuthorized ? (
                <AppIconViewport
                  color={headerTheme.isDarkColorTheme ? colorsLogo.white : colorsLogo.dark}
                  wrapWithRootLink
                />
              ) : (
                <a href="https://www.bcscapitaldifc.com/">
                  <AppIconViewport color={headerTheme.isDarkColorTheme ? colorsLogo.white : colorsLogo.dark} />
                </a>
              )}
            </Grid>
            {!unAuthorized && !headerTheme.isHiddenTabMenu && (
              <Grid item className={classes.tabs}>
                <Tabs
                  value={matches?.pathname || false}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor={headerTheme.isDarkColorTheme ? "primary" : "secondary"}
                  className={cn(classes.h100, classes.tabsContainer, IS_NEW_HEADER && classes.newTabsContainer)}
                  action={tabsActionCallback}
                >
                  <Tab
                    className={classesSelectedTab(selected)}
                    label={t("MENU.HEADER.operations")}
                    value={links.operationsAssets}
                    disableRipple
                  />
                  <Tab
                    label={t("MENU.HEADER.forYou")}
                    value={links.forYouCommon}
                    className={classesSelectedTab(selectedForYou)}
                    disableRipple
                  />
                  <Tab
                    className={classesSelectedTab(selectedServices)}
                    label={t("MENU.HEADER.services")}
                    value={links.servicesAll}
                    disableRipple
                  />
                </Tabs>
              </Grid>
            )}
            {IS_NEW_HEADER ? (
              <Box className={classes.rightSide}>
                <Box className={classes.actionsWrap}>
                  {!unAuthorized && !isMobile && (
                    <Tooltip title={t("MENU.HEADER.tooltip")} className={classes.sendMessage} enterTouchDelay={0}>
                      <Box>
                        <ContactUs to={links.sendMessage} size={24} />
                      </Box>
                    </Tooltip>
                  )}
                  {!unAuthorized && (
                    <>
                      <Notification /> <UserDropdownButton />
                    </>
                  )}
                </Box>

                {(!isMobile || unAuthorized) && (
                  <Grid item className={classes.space}>
                    <SwitchLocaleViewport colorText={colors.black} withoutIcon sizeIcon={20} className="onlyText" />
                  </Grid>
                )}

                {isSideMenu && !unAuthorized && (
                  <div className={classes.closeMenuElem} onClick={() => isSideMenu && setIsSideMenu(!isSideMenu)}></div>
                )}
                {!unAuthorized &&
                  (isMenu ? (
                    <Box
                      onClick={() => {
                        const portfolioNoteInfo = localStorage.getItem("portfolioNoteInfoMenu");
                        if (portfolioNoteInfo) {
                          localStorage.setItem("portfolioNoteInfo", portfolioNoteInfo);
                          localStorage.removeItem("portfolioNoteInfoMenu");
                        }
                        navigate(urlBack);
                      }}
                    >
                      <Icon name="modalCross" size={24} withBackground={{ height: 24 }} />
                    </Box>
                  ) : (
                    <Box
                      className={classes.burgerButton}
                      onClick={() => {
                        const portfolioNoteInfo = localStorage.getItem("portfolioNoteInfo");
                        if (portfolioNoteInfo) {
                          localStorage.setItem("portfolioNoteInfoMenu", portfolioNoteInfo);
                        }
                        navigate(links.mobileMenu);
                      }}
                    >
                      <Icon
                        className={classes.burgerButtonIcon}
                        name="burgerMinimal"
                        color={headerTheme.isDarkColorTheme ? colorTable.white : colorTable.anthraciteGray}
                        size={24}
                        withBackground={{ height: 24 }}
                      />
                    </Box>
                  ))}
              </Box>
            ) : (
              <div className={classes.userTabsBox}>
                {!unAuthorized && (
                  <Grid item className={classes.userDropdownWrapper}>
                    <UserDropdownButton />
                  </Grid>
                )}
                <Grid item className={classes.space}>
                  <SwitchLocaleViewport colorText={colors.black} withoutIcon sizeIcon={20} className="onlyText" />
                </Grid>
                {!unAuthorized && (
                  <Tooltip title={t("MENU.HEADER.tooltip")} className={classes.sendMessage} enterTouchDelay={0}>
                    <Grid item className={classes.space}>
                      <ContactUs to={links.sendMessage} />
                    </Grid>
                  </Tooltip>
                )}
                {isSideMenu && !unAuthorized && (
                  <div className={classes.closeMenuElem} onClick={() => isSideMenu && setIsSideMenu(!isSideMenu)}></div>
                )}
                {!unAuthorized &&
                  (isMenu ? (
                    <Box
                      onClick={() => {
                        const portfolioNoteInfo = localStorage.getItem("portfolioNoteInfoMenu");
                        if (portfolioNoteInfo) {
                          localStorage.setItem("portfolioNoteInfo", portfolioNoteInfo);
                          localStorage.removeItem("portfolioNoteInfoMenu");
                        }
                        navigate(urlBack);
                      }}
                    >
                      <Icon name="modalCross" size={24} />
                    </Box>
                  ) : (
                    <Box
                      className={classes.burgerButton}
                      onClick={() => {
                        const portfolioNoteInfo = localStorage.getItem("portfolioNoteInfo");
                        if (portfolioNoteInfo) {
                          localStorage.setItem("portfolioNoteInfoMenu", portfolioNoteInfo);
                        }
                        navigate(links.mobileMenu);
                      }}
                    >
                      <Icon
                        className={classes.burgerButtonIcon}
                        name="burgerMinimal"
                        color={headerTheme.isDarkColorTheme ? colorTable.white : colorTable.charcoalBlack}
                      />
                    </Box>
                  ))}
              </div>
            )}
          </Toolbar>
        </ContentWrapperViewport>
      </Container>
    </AppBar>
  );
};

export default ViewportHeader;
