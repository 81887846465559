import { createAsyncReducer } from "~/lib/redux";

import {
  keycloakChangeSecurityQuestionClearAction,
  keycloakChangeSecurityQuestionErrorAction,
  keycloakChangeSecurityQuestionStartAction,
  keycloakChangeSecurityQuestionSuccessAction,
} from "../actions";

export const STATE_NAME = "changeSecurityQuestion";

const [reducer, defaultState] = createAsyncReducer(
  keycloakChangeSecurityQuestionStartAction,
  keycloakChangeSecurityQuestionSuccessAction,
  keycloakChangeSecurityQuestionErrorAction,
  keycloakChangeSecurityQuestionClearAction,
);

export type KeycloakChangeSecurityQuestionState = typeof defaultState;

export default reducer;
