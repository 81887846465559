import { createEvent, createStore } from "effector";

import { clientChannels, productCategory } from "~/constants/enums";
import { IProductsShelfResponse, pdfBase64Info, Remittance } from "~/entities/dma/types";
import { get, post, put } from "~/lib/request";
import { TClientCurrent } from "../current-client/types";
import i18n from "~/i18n";
import { SortKeysEnum, SortValuesEnum, SortValueStore } from "~/modules/showcase/types.ts";

export const $base64PdfStore = createStore<pdfBase64Info[]>([]);
const changed = createEvent<pdfBase64Info>();
$base64PdfStore.on(changed, (state, value) => [...state, value]);

export const dmaApi = {
  searchUsProductId: async () => {
    const res = await get(
      "@auth/product/products",
      {
        params: {
          productCategory: productCategory.STOCK,
        },
      },
      {
        headers: {
          "Accept-Language": "en",
        },
      }
    );
    return res.data;
  },
  searchUsProductApplication: async (): Promise<any> => {
    const res = await get("@auth/service-activation/direct-access-market-view");
    return res.data;
  },
  openingUsProduct: async (serviceId: string) => {
    const res = await post(
      "@auth/executor/v1/dma/opening",
      { data: { serviceId } },
      {
        headers: {
          "X-Channel": clientChannels.UCF_WEB,
        },
      }
    );
    return res.data;
  },
  openingUsProductStatus: async (businessKey: string) => {
    const res = await get(
      "@auth/executor/v1/process/state",
      { params: { processBusinessKey: businessKey } },
      { delay: 3000 }
    );
    return res.data;
  },
  openingUsPdfModal: async (data: { businessKey: string; variableName: string }) => {
    const res = await get(
      "@auth/executor/v1/task/pdf-from-process",
      {
        params: {
          processBusinessKey: data.businessKey,
          variableName: data.variableName,
        },
      },
      {
        isBlob: true,
      }
    );

    const reader = new FileReader();
    reader.readAsDataURL(res.data);
    reader.onloadend = () => {
      //@ts-ignore
      const base64data = reader.result.split(",")[1];
      changed({ content: base64data, type: data.variableName });
    };
  },
  openingUsStockPdfModal: async (data: { businessKey: string; variableNames: string[] }) => {
    data.variableNames.map((variableName) =>
      get(
        "@auth/executor/v1/task/pdf-from-process",
        {
          params: {
            processBusinessKey: data.businessKey,
            variableName: variableName,
          },
        },
        {
          isBlob: true,
        }
      ).then((res) => {
        const name = res.headers?.["content-disposition"].match(/filename=(.*?)\.pdf/)?.[1];
        const reader = new FileReader();
        reader.readAsDataURL(res.data);
        reader.onloadend = () => {
          //@ts-ignore для контента pdf
          const base64data = reader.result.split(",")[1];
          changed({ content: base64data, type: variableName, fileName: name });
        };
      })
    );
  },
  usTaskAgree: async (taskId: string) => {
    const res = await put("@auth/executor/v1/task/complete", {
      data: {
        taskId,
        variables: {
          accept: true,
        },
      },
    });
    return res.data;
  },
  usCheckState: async (businessKey: string) => {
    const res = await get(
      "@auth/executor/v1/process/state",
      {
        params: {
          processBusinessKey: businessKey,
        },
      },
      { delay: 3000 }
    );
    return res.data;
  },
  smsOtpCheck: async (data: { taskId: string; otpCode: string | null }) => {
    const res = await put("@auth/executor/v1/task/complete", {
      data: {
        taskId: data.taskId,
        variables: {
          enteredCode: data.otpCode,
        },
      },
    });
    return res.data;
  },
  clearStatus: async (data: { taskId: string; accepted?: boolean }) => {
    const res = await put("@auth/executor/v1/task/complete", {
      data: {
        taskId: data.taskId,
        variables: {
          accept: data.accepted || false,
        },
      },
    });
    return res.data;
  },
  getCurrentClient: async (): Promise<TClientCurrent> => {
    const res = await get("@auth/client/clients/current");
    return res.data;
  },

  remittance: async (data: Remittance | null) => {
    if (data) {
      const res = await post(
        "@auth/executor/v1/remittance/transfer-order",
        {
          data,
        },
        {
          headers: {
            "X-Channel": clientChannels.UCF_WEB,
          },
        }
      );
      return res.data;
    }
  },
  getProductsShelf: async (data: {
    page?: number;
    filters?: any[];
    sort?: SortValueStore;
  }): Promise<IProductsShelfResponse> => {
    const res = await post(
      "@msdma/api/v1/instruments",
      {
        data: {
          fields: [
            "id",
            "quoteValue",
            "isin",
            "quoteClose",
            "currencySign",
            "quoteChangeRate2",
            "quoteChangeRate",
            "quoteChange2",
            "quoteChange",
            "quoteLast2",
            "quoteLast",
            "name",
            "displayName",
            "ticker",
            "market",
            "tradingStatus",
            "isEquity",
            "isEtf",
            "dateTimeClose",
            "dateTimeOpen",
          ],
          request: {
            filters: data.filters?.length
              ? [
                  {
                    field_type: "STRING",
                    key: "searchString",
                    operator: "LIKE",
                    value: "",
                  },
                  ...data.filters,
                ]
              : undefined,
            page: data.page ?? 0,
            size: 10,
            sorts: [
              {
                direction: data?.sort?.sortValue.toUpperCase() ?? SortValuesEnum.ASC.toUpperCase(),
                key: data?.sort?.sortKey ?? SortKeysEnum.NAME,
              },
            ],
          },
        },
      },
      {
        headers: {
          "Accept-Language": i18n.language,
        },
      }
    );
    return res.data;
  },
};
