import React from "react";
import { useMediaQuery } from "@mui/material";
import { useStore } from "effector-react";

import { modalTypes } from "~/constants/enums";
import { ModalNotification } from "~/features/ModalNotification";
import { notificationModalModel } from "~/modules/notification-modal-model/model";

interface IWithNotificationModalProps {
  children: React.ReactChild;
}

export const WithNotificationModal = ({ children }: IWithNotificationModalProps) => {
  const { $notificationParams, setShowNotificationModal, $showNotificationModal, $pendingNotificationModal } =
    notificationModalModel;
  const notificationParams = useStore($notificationParams);
  const showNotificationModal = useStore($showNotificationModal);
  const pendingNotificationModal = useStore($pendingNotificationModal);

  const isTabletOrMobile = useMediaQuery("(max-width: 768px)");

  return (
    <>
      <ModalNotification
        pending={pendingNotificationModal}
        type={modalTypes.any}
        title={notificationParams.title}
        arrDescription={notificationParams.descriptions}
        buttons={notificationParams.buttons}
        isOpen={showNotificationModal}
        onClose={() => {
          setShowNotificationModal(false);
          notificationParams.onCloseModal && notificationParams.onCloseModal();
        }}
        iconName={notificationParams.icon}
        iconColor={notificationParams.iconColor}
        iconBackground={notificationParams.iconBackground}
        maxWidthContent={isTabletOrMobile ? "480px" : notificationParams.maxWidthContent}
      />
      {children}
    </>
  );
};
