import React, { ReactNode, useRef, useState } from "react";
import { Box, InputAdornment, InputLabel } from "@mui/material";
import cn from "classnames";

import TextField from "~/shared/ui/TextField";
import { useStyles } from "./styles";
import Icon from "~/shared/Icon";

export interface BaseSelectProps {
  name: string;
  style?: object;
  styleWrapper?: object;
  value: string | boolean | number | object | null;
  inputProps?: object;
  onChange?: any | (() => any);
  onBlur?: () => void;
  label: string;
  helperText?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  selectItemsRenderer: ReactNode;
  hasError?: boolean;
  className?: string;
  classNameInput?: string;
  classNameArrow?: string;
  classNameLabel?: string;
  isMobileWrapText?: boolean;
}

export const BaseSelect = React.forwardRef(
  (
    {
      name = "",
      style = {},
      styleWrapper = {},
      value = "",
      inputProps = {},
      onChange = () => {},
      onBlur = () => {},
      label = "",
      helperText = "",
      disabled = false,
      fullWidth = false,
      selectItemsRenderer = () => {},
      hasError = false,
      className = "",
      classNameInput,
      classNameArrow,
      classNameLabel,
      isMobileWrapText,
    }: BaseSelectProps,
    ref: any
  ) => {
    const { classes } = useStyles();
    const [rotateArrow, setRotateArrow] = useState<boolean>(false);
    const newRef = useRef<any>(null);

    return (
      <Box
        className={cn(classes.wrapperSelect, className)}
        style={styleWrapper}
        onClick={() => {
          if (!disabled) {
            setRotateArrow((prev) => !prev);
          }
        }}
      >
        <InputLabel className={classNameLabel || classes.selectLabel}>{label}</InputLabel>
        <TextField
          ref={ref || newRef}
          style={style}
          className={cn(classes.selectField, isMobileWrapText && classes.mobileField, classNameInput)}
          select
          name={name}
          value={value}
          inputProps={inputProps}
          onChange={onChange}
          onBlur={() => {
            onBlur && onBlur();
          }}
          fullWidth={fullWidth}
          helperText={helperText}
          disabled={disabled}
          SelectProps={{
            open: rotateArrow,
            MenuProps: {
              classes: { list: classes.menu },
              PaperProps: {
                style: { width: ref ? ref?.current?.offsetWidth : newRef?.current?.offsetWidth },
              },
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icon
                  name="selectArrow"
                  size={10}
                  className={cn(classNameArrow || classes.arrow, {
                    [classes.arrowRotate]: rotateArrow,
                  })}
                />
              </InputAdornment>
            ),
          }}
          error={hasError}
        >
          {selectItemsRenderer}
        </TextField>
      </Box>
    );
  }
);
