import { combineReducers } from "redux";

import buyReducer, { BuyState, STATE_NAME as BUY_STATE_NAME } from "./reducers/buy";
import commissionReducer, { CommissionState, STATE_NAME as COMMISSION_STATE_NAME } from "./reducers/commission";

export const STATE_NAME = "product";

export interface ProductState {
  [COMMISSION_STATE_NAME]: CommissionState;
  [BUY_STATE_NAME]: BuyState;
}

export default combineReducers<ProductState>({
  [COMMISSION_STATE_NAME]: commissionReducer,
  [BUY_STATE_NAME]: buyReducer,
});
