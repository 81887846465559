import { makeStyles } from "tss-react/mui";

import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
  },
  item: {
    display: "flex",
    gap: 24,
    color: colorTable.charcoalBlack,
    paddingBottom: 24,
    marginRight: 17,
    borderBottom: `1px solid ${colorTable.greyContour}`,

    "& > div": {
      display: "flex",
      flexDirection: "column",
      gap: 8,
    },

    "& span": {
      whiteSpace: "pre-wrap",
      color: colorTable.textSecondary,
    },
  },
});
