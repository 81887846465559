import { createDomain } from "effector";

import history from "~/configs/history";
import store from "~/configs/store";
import { t } from "~/i18n";
import { appDefaultErrorMessageAction, appMessageAction } from "~/modules/app/actions";
import { logoutModel } from "~/modules/keycloak/logout-model";
import { notificationService } from "~/modules/notifications";

const notificationDomain = createDomain("notification domain");

const defaultError = notificationDomain.createEvent();
const error = notificationDomain.createEvent<string | React.ReactElement>();
const success = notificationDomain.createEvent<string>();
const info = notificationDomain.createEvent<string>();
const warning = notificationDomain.createEvent<string>();
const pushTo = notificationDomain.createEvent<string>();
const pushToWithState = notificationDomain.createEvent<{ path: string; state?: any }>();
const errorDefaultServer = notificationDomain.createEvent<string>();
const errorClientLogin = notificationDomain.createEvent();
const errorClientStatus = notificationDomain.createEvent();

defaultError.watch(() => {
  store.dispatch(appDefaultErrorMessageAction());
});

error.watch((errorMessage) => {
  store.dispatch(
    appMessageAction({
      message: errorMessage,
      options: { variant: "error" },
    })
  );
});

errorDefaultServer.watch((errorData: any) => {
  if (
    errorData?.error?.error?.response?.data?.status !== 403 &&
    errorData?.error?.error?.response?.data?.status !== 401 &&
    errorData?.error?.error?.response?.data?.error !== "invalid_grant" &&
    !!errorData?.error?.error?.response?.data
  ) {
    store.dispatch(
      appMessageAction({
        message: t("ERRORS.default_response", { errorData: JSON.stringify(errorData?.error?.error?.response?.data) }),
        options: { variant: "error" },
      })
    );
  }
});

success.watch((successMessage) => {
  store.dispatch(
    appMessageAction({
      message: successMessage,
      options: { variant: "success" },
    })
  );
});

info.watch((infoMessage) => {
  store.dispatch(
    appMessageAction({
      message: infoMessage,
      options: { variant: "info" },
    })
  );
});

warning.watch((warningMessage) => {
  store.dispatch(
    appMessageAction({
      message: warningMessage,
      options: { variant: "warning" },
    })
  );
});

errorClientLogin.watch(() => {
  notificationModel.error(t("ERRORS.missing_login"));
  notificationService.removePushTokenEv();
  logoutModel.logout();
});

errorClientStatus.watch(() => {
  notificationModel.error(t("ERRORS.bad_client_status"));
  notificationService.removePushTokenEv();
  logoutModel.logout();
});

pushTo.watch((route) => history.push(route));
pushToWithState.watch(({ path, state }) => history.push(path, state));

export const notificationModel = {
  defaultError,
  error,
  success,
  info,
  warning,
  pushTo,
  errorDefaultServer,
  errorClientLogin,
  errorClientStatus,
  pushToWithState,
};
