import { createReducer, Reducer } from "~/lib/redux";
import { AsyncData, AsyncDataPhase, initial } from "~/lib/redux/async";

import { anketaSaveInvestSectionStartAction, anketaUpdateInvestSectionStartAction } from "../actions";
import { InvestSectionDto } from "~/entities/anketa/models/InvestSectionDto";

export const STATE_NAME = "saveOrUpdateInvestSection";

export type AnketaSaveOrUpdateInvestSectionState = AsyncData<InvestSectionDto>;

export const defaultState: AnketaSaveOrUpdateInvestSectionState = initial();

const reducer: Reducer<AnketaSaveOrUpdateInvestSectionState> = createReducer<AnketaSaveOrUpdateInvestSectionState>(
  {},
  defaultState
);

const pendingReducer = (state: AnketaSaveOrUpdateInvestSectionState): AnketaSaveOrUpdateInvestSectionState => ({
  ...state,
  phase: AsyncDataPhase.Pending,
});

reducer.on(anketaSaveInvestSectionStartAction, pendingReducer);
reducer.on(anketaUpdateInvestSectionStartAction, pendingReducer);

export default reducer;
