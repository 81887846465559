import React, { useCallback } from "react";
import { Grid, Typography } from "@mui/material";
import cn from "classnames";
import { makeStyles } from "tss-react/mui";

import { Theme } from "~/configs/theme";
import { useTranslation } from "~/i18n";
import colorTable from "~/shared/ui-kit/constants/colors";
import Icon from "~/shared/Icon";

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    position: "relative",
    cursor: "pointer",
    paddingTop: "30px",
    [theme.breakpoints.down(576)]: {
      paddingTop: "8px",
    },
  },
  mobileContainer: {
    width: "220px",
  },
  icon: {
    height: 24,
    width: 24,
    color: colorTable.white,
  },
  text: {
    color: colorTable.white,
    fontSize: "16px",
    lineHeight: "18px",
    [theme.breakpoints.down(576)]: {
      fontSize: "14px",
    },
  },
  mobileIcon: {
    height: 50,
    width: 50,
  },
}));

export interface HeaderSaveDocsProps {
  children?: never;
  onSave: () => void;
  isOne?: boolean;
}

const HeaderSaveDocs = ({ onSave, isOne }: HeaderSaveDocsProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const handleOnClick = useCallback((): void => {
    onSave();
  }, [onSave]);

  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      alignItems="center"
      alignContent="center"
      spacing={1}
      className={cn(classes.container)}
      onClick={handleOnClick}
    >
      <Grid item>
        <Icon name="downloadArrow" color={colorTable.white} size={32} className={cn(classes.icon)} />
      </Grid>
      <Grid item>
        <Typography className={cn(classes.text)}>
          {isOne ? t("GENERAL.download-doc-pdf") : t("GENERAL.download-all-docs")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default HeaderSaveDocs;
