enum colorTable {
  charcoalBlack = "#222222",
  black = "#111111",
  blackDefault = "#000000",
  black_10 = "#212121",
  black_50 = "#00000050",
  black_15 = "#01111A",
  black_20 = "#1F2229",
  anthraciteGray = "#474747",
  moonGrey = "#8e8e8e",
  nobleOchre = "#DAC481",
  goldenHaze = "#FFEBB0",
  gold = "#FFC900",
  ivory = "#FFF2C9",
  textSecondary = "#525252",
  greyContour = "#E7E7E7",
  lightGrey = "#F9F9F9",
  red = "#D72D40",
  red_10 = "#d3312f",
  red_20 = "#F26779",
  red_30 = "#f34a38",
  red_40 = "#d3312e",
  red_90 = "#B10014",
  red_100 = "#9a0006",
  redLight = "#FBEAEC",
  textIconAccentRed = "#D43E50",
  green_60 = "#008368",
  green_50 = "#00A582",
  lightGreen = "#E6F5F2",
  green_40 = "#00BD93",
  green_30 = "#008F71",
  green_20 = "#4caf50",
  green_10 = "#46e3aa",
  orange = "#FFA000",
  lightOrange = "#FFF5E5",
  lightOrange_10 = "#FEB81C",
  white = "#ffffff",
  white_10 = "#F2F5FA",
  white_15 = "#d0d0d0",
  white_20 = "#F7F7F7",
  white_25 = "#DADADA",
  white_30 = "#F4F7FC",
  white_35 = "#FFF0F2",
  white_40 = "#F5F5F5",
  white_50 = "#EAEFF4",
  white_45 = "#E8F0FD",
  white_55 = "#FFF1DE",
  white_60 = "#F0F0F0",
  white_70 = "#F6F6F6",
  white_80 = "#EBEBEB",
  white_85 = "#EEEEEE",
  white_90 = "#D3EDE6",
  white_95 = "#DCDCDB",
  whiteGrey = "#FFFFFFCC",
  lightGrey_90 = "#566C91",
  lightGrey_10 = "#F5F7FA",
  lightGrey_15 = "#93A2BA",
  lightGrey_30 = "#E9EFF5",
  lightGrey_35 = "#9BA6B0",
  lightGrey_50 = "#CFDBEA",
  lightGrey_55 = "#E4E6EA",
  lightGrey_40 = "#8E8E8E",
  lightGrey_45 = "#bdbdbd",
  lightGrey_80 = "#F1F1F1",
  lightGrey_65 = "#8b93a9",
  lightGrey_5 = "#eeeeee",
  lightGrey_70 = "#93969A",
  lightGrey_75 = "#949494",
  lightGrey_60 = "#F0F4F8",
  lightGrey_20 = "#F4F7FA",
  lightGrey_25 = "#dde1e6",
  lightGrey_100 = "#D2D8E2",
  darkGrey_10 = "#6E7A88",
  darkGrey_15 = "#9d9d9d",
  darkGrey_20 = "#757575",
  darkGrey_30 = "#7F8388",
  darkGrey_40 = "#EDF0F1",
  darkGrey_100 = "#2E2F31",
  lightBlue = "#E9F4FF",
  lightBlue_30 = "#9CAFCD",
  shadow = "#00000014",
  blue_10 = "#1F4151",
  blue_20 = "#006AEF",
  blue_30 = "#71B3FD",
  blue_40 = "#006cef",
  blue_55 = "#006DF0",
  blue_45 = "#3D4F7E",
  blue_50 = "#1A49D7",
  blue_60 = "#0063E1",
  blue_65 = "#1E3E6F",
  blue_70 = "#00367B",
  blue_80 = "#153AAC",
  blue_90 = "#002952",
  blue_95 = "#001C40",
  blue_100 = "#002c55",
  lightBlue_10 = "#6D7E98",
  lightBlue_20 = "#798FB3",
  pink = "#F6D8DC",
  misteryBlue = "#5A7494",
  darkBlue = "#0F222B",
  darkBlue_10 = "#1D0553",
  blackBlue = "#111924",
  blackSea = "#102534",
  dialogBackground = "rgba(0, 0, 0, 0.5)",
  transparentBlack_008 = "rgba(0, 0, 0, 0.08)",
  transparentBlack_004 = "rgba(0, 0, 0, 0.04)",
  transparent = "transparent",
  yellowLight = "#FCF2CC",
  yellow_20 = "#FFDA99",
  green = "#23A583",
  chart_sky = "#7EC7FB",
  chart_teal = "#96DDAA",
  chart_salmon = "#FF9897",
  chart_purple = "#B596DD",
  barrier_1 = "#0A7EE9",
  barrier_2 = "#440AE9",
  barrier_3 = "#C8F741",
  barrier_4 = "#0ADBE9",
  barrier_5 = "#FF6B00",
  barrier_6 = "#A20AE9",
  barrier_7 = "#F7DA41",
  barrier_8 = "#0AE98B",
  barrier_9 = "#FF2E00",
  barrier_10 = "#E90A68",
  barrier_11 = "#FA30CE",
  purple = "#180550",
  orange_10 = "#F1BC00",
}

export default colorTable;
