import { makeStyles } from "tss-react/mui";

import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles<{ color: colorTable }>()((_, { color }) => ({
  loaderPage: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  colorPrimary: {
    "& .MuiCircularProgress-circle": {
      stroke: color,
    },
  },
}));
