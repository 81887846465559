import { makeStyles } from "tss-react/mui";

import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    "& *": {
      boxSizing: "border-box",
    },
  },
  head: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    textAlign: "center",
    color: colorTable.moonGrey,

    "& > p:first-child": {
      textAlign: "start",
    },
    "& > p:last-child": {
      textAlign: "end",
    },
  },
  stockItem: {
    position: "relative",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    height: "64px",
    textAlign: "center",
    borderBottom: `1px solid ${borderColors.greyContour}`,
  },
  buying: {
    textAlign: "start",
  },
  buyingGraph: {
    maxWidth: "50%",
    position: "absolute",
    left: 0,
    height: "64px",
    maxHeight: "64px",
    borderRadius: "0 8px 8px 0",
    backgroundColor: colorTable.lightGreen,
  },
  buyingNum: {
    position: "absolute",
    zIndex: 2,
    left: 0,
    maxHeight: "64px",
    height: "64px",
    padding: "20px 0 20px 16px",
    color: colorTable.green_50,
  },
  selling: {
    display: "flex",
    justifyContent: "end",
    textAlign: "end",
  },
  sellingGraph: {
    maxWidth: "50%",
    position: "absolute",
    right: 0,
    maxHeight: "64px",
    height: "64px",
    borderRadius: "8px 0 0 8px",
    backgroundColor: colorTable.redLight,
  },
  sellingNum: {
    zIndex: 2,
    position: "absolute",
    padding: "20px 16px 20px 0",
    right: 0,
    maxHeight: "64px",
    height: "64px",
    color: colorTable.red,
  },
  price: {
    zIndex: 2,
    position: "relative",
    alignSelf: "center",
  },
});
