import { createAsyncReducer } from "~/lib/redux";

import {
  keycloakSecurityQuestionClearAction,
  keycloakSecurityQuestionErrorAction,
  keycloakSecurityQuestionStartAction,
  keycloakSecurityQuestionSuccessAction,
} from "../actions";

export const STATE_NAME = "securityQuestion";

const [reducer, defaultState] = createAsyncReducer(
  keycloakSecurityQuestionStartAction,
  keycloakSecurityQuestionSuccessAction,
  keycloakSecurityQuestionErrorAction,
  keycloakSecurityQuestionClearAction,
);

export type KeycloakSecurityQuestionState = typeof defaultState;

export default reducer;
