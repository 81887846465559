export interface Remittance {
  sourceLocationGroupId: string;
  destLocationGroupId: string;
  amount: number;
  serviceId: string;
  orderDirection: string;
}

export type pdfBase64Info = {
  content: string;
  type: string;
  fileName?: string;
};

export enum pdfBase64DmaType {
  dma = "dma",
  transfer_order = "transfer-order",
  fee_schedule_effective = "fee-schedule-effective",
  md_terms_deactivation = "md_terms_deactivation",
  fee_schedule = "fee-schedule",
  schedule_e = "schedule_e",
}

export interface IProductShelf {
  currencyCode: string;
  currencySign: string;
  dateTimeClose: string;
  dateTimeOpen: string;
  displayName: string;
  id: number;
  isEquity: boolean;
  isEtf: boolean;
  isin: string;
  market: string;
  name: string;
  quoteChange: number;
  quoteChange2: number;
  quoteChangeRate: number;
  quoteChangeRate2: number;
  quoteClose: number;
  quoteLast: number;
  quoteLast2: number;
  quoteLast2Name: string;
  quoteNextTradingStartSeconds: number;
  ticker: string;
  tradingStatus: number;
}

export interface IProductsShelfResponse {
  content: IProductShelf[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    pageNumber: number;
    pageSize: number;
    sort: {
      empty: boolean;
      unsorted: boolean;
      sorted: boolean;
    };
    offset: number;
    unpaged: boolean;
    paged: boolean;
  };
  size: number;
  totalElements: number;
  totalPages: number;
  sort: {
    empty: boolean;
    unsorted: boolean;
    sorted: boolean;
  };
}

export interface IFilterItem {
  field_type: FilterItemFieldTypeEnum;
  key: FilterItemKeyEnum;
  operator: FilterItemOperatorEnum;
  value: any;
}

export type IFiltersStore = {
  [key in FilterItemKeyEnum]?: string | boolean | string[];
};

export enum FilterItemFieldTypeEnum {
  BOOLEAN = "BOOLEAN",
  STRING = "STRING",
}

export enum FilterItemOperatorEnum {
  EQUAL = "EQUAL",
  LIKE = "LIKE",
}

export enum FilterItemKeyEnum {
  IS_ETF = "isEtf",
  IS_EQUITY = "isEquity",
  STOCK = "stock",
  ECONOMIC = "economic",
  MARGIN_LONG = "marginLong",
}
