import React from "react";
import { Box } from "@mui/material";

import { BaseButton, BaseTypography } from "~/shared/ui-kit/BaseComponents";
import colorTable from "~/shared/ui-kit/constants/colors";
import Icon, { IconNameType } from "~/shared/Icon";
import { useStyles } from "./styles";
import { t } from "~/i18n";
import { cn } from "~/lib/cn";

interface IProps {
  errorText: string;
  iconName: IconNameType;
  iconColor?: colorTable;
  btnCallback?: () => void;
  containerClassName?: string;
}

export const ProductTabError = (props: IProps) => {
  const { errorText, btnCallback, iconName, containerClassName } = props;

  const { classes } = useStyles();

  return (
    <Box className={cn(classes.container, containerClassName)}>
      <Icon
        name={iconName}
        size={40}
        color={props.iconColor || colorTable.textSecondary}
        withBackground={{
          size: "68px",
          backgroundType: "rounded_16",
          backgroundColor: colorTable.white_20,
        }}
      />
      <BaseTypography tag="p" fontType="text">
        {errorText}
      </BaseTypography>
      {btnCallback && (
        <BaseButton typeStyle="grey" className={classes.btnRefresh} onClick={btnCallback}>
          <Icon name="reload" size={16} color={colorTable.textSecondary} withBackground={{ height: "16px" }} />
          {t("PUSH.modal_notifications.refresh")}
        </BaseButton>
      )}
    </Box>
  );
};
