export const SHORT_MONEY_VALUES = {
  B: {
    ru: "млрд",
    en: "B",
  },
  M: {
    ru: "млн",
    en: "M",
  },
  K: {
    ru: "тыс",
    en: "K",
  },
  T: {
    ru: "трлн",
    en: "T",
  },
};
