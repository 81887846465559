import { AgreementStatusEnum, AgreementTypeEnum, AvailableStatusEnum } from "~/entities/productBuy/models/ClientCard";
import { CustomerAgreementStatus } from "../models/CustomerAgreementDTO";
import { AccountRequestStatus } from "~/entities/account/models/AccountRequest";

export const isAssetType = (type: AgreementTypeEnum): boolean => {
  return type === AgreementTypeEnum.GENERAL_DU;
};

export const isServiceType = (type: AgreementTypeEnum): boolean => {
  return type === AgreementTypeEnum.GENERAL_BO;
};

export const isWarningStatus = (type: string): boolean => {
  switch (type) {
    case AvailableStatusEnum.REQUEST_IS_OLD:
      return true;

    case AvailableStatusEnum.CLIENT_DATA_EXPIRED:
      return true;

    case AvailableStatusEnum.REQUEST_IS_OFFLINE:
      return true;

    case AvailableStatusEnum.BLOCKED:
      return true;

    default:
      return false;
  }
};

export const isRejectedStatus = (type: string): boolean => {
  return type === AvailableStatusEnum.REJECTED;
};

export const isSentStatus = (type: string): boolean => {
  switch (type) {
    case AvailableStatusEnum.PENDING:
      return true;

    case AvailableStatusEnum.REQUEST_ALREADY_SENT:
      return true;

    default:
      return false;
  }
};

export const isActiveStatusDU = (type: string): boolean => {
  switch (type) {
    case AvailableStatusEnum.NEED_OPEN_BROKER_ACCOUNT:
      return false;

    case AvailableStatusEnum.REQUEST_ALREADY_SENT:
      return false;

    case AccountRequestStatus.EMPTY:
      return false;

    default:
      return true;
  }
};

export const isActiveStatusBO = (type: string): boolean => {
  switch (type) {
    case AvailableStatusEnum.AGREEMENT_ALREADY_EXISTS:
      return true;

    case AvailableStatusEnum.ACTIVATED:
      return true;

    default:
      return false;
  }
};

export const isEmptyStatus = (type: string): boolean => {
  return type === AccountRequestStatus.EMPTY;
};

export const hasActiveAgreementStatus = (status: AgreementStatusEnum | null | undefined): boolean => {
  return status === AgreementStatusEnum.ACTIVE;
};

export const hasActivedStatus = (status: string | null | undefined): boolean => {
  return status?.toLowerCase() === CustomerAgreementStatus.ACTIVATED;
};

export const hasRejectedStatus = (status: string | null | undefined): boolean => {
  return status?.toLowerCase() === CustomerAgreementStatus.REJECTED;
};

export const hasClosedStatus = (status: string | null | undefined): boolean => {
  return status?.toLowerCase() === CustomerAgreementStatus.CLOSED;
};

export const hasSentedStatus = (status: string | null | undefined): boolean => {
  return (
    status?.toLowerCase() === CustomerAgreementStatus.SENT || status?.toLowerCase() === CustomerAgreementStatus.PENDING
  );
};

export const hasSentedWithoutPendingStatus = (status: string | null | undefined): boolean => {
  return status?.toLowerCase() === CustomerAgreementStatus.SENT;
};

export const hasPendingStatus = (status: string | null | undefined): boolean => {
  return status === CustomerAgreementStatus.PENDING;
};

export const hasDraftedStatus = (status: string | null | undefined): boolean => {
  return status?.toLowerCase() === CustomerAgreementStatus.DRAFT;
};

export const hasInitializingStatus = (status: string | null | undefined): boolean => {
  return status?.toLowerCase() === CustomerAgreementStatus.INITIALIZING;
};

export const hasEmptyStatus = (status: string | null | undefined): boolean => {
  return status === AccountRequestStatus.EMPTY;
};
