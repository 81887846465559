import { makeStyles } from "tss-react/mui";

import { Theme } from "~/configs/theme";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    width: "100%",
    position: "relative",
    color: colorTable.white,
    overflow: "hidden",
    borderRadius: "16px",
    padding: "56px",
    cursor: "pointer",
    marginBottom: "64px",
    [theme.breakpoints.down(768)]: {
      padding: "10px",
      background: colorTable.darkGrey_100,
      marginBottom: "40px",
    },
  },
  img: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    [theme.breakpoints.down(768)]: {
      top: "-62px",
      width: "100%",
      height: "100%",
      objectFit: "contain",
      filter: "brightness(80%)",
    },
  },
  textBox: {
    zIndex: 2,
    position: "relative",
    maxWidth: "785px",
    [theme.breakpoints.down(768)]: {
      maxWidth: "100%",
    },
  },
  bannerTitle: {
    color: colorTable.white,
    fontSize: "32px",
    lineHeight: "40px",
    fontWeight: 600,
    marginBottom: "8px",
    [theme.breakpoints.down(768)]: {
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
  bannerText: {
    color: colorTable.white,
    fontSize: "20px",
    lineHeight: "28px",
    [theme.breakpoints.down(768)]: {
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
  secondBannerBottomBox: {
    display: "flex",
    gap: "48px",
    marginTop: "24px",
    [theme.breakpoints.down(768)]: {
      flexDirection: "column",
      gap: "10px",
    },
  },
  secondBannerBottomPart: {
    maxWidth: "271px",
    [theme.breakpoints.down(576)]: {
      maxWidth: "100%",
    },
  },
  bottomPartPercent: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "28px",
    color: colorTable.white,
  },
  bottomPartText: {
    marginTop: "4px",
    color: colorTable.lightGrey_35,
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
  },
}));
