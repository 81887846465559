import { createAsyncReducer } from "~/lib/redux";

import {
  keycloakInitialCredentialsClearAction,
  keycloakInitialCredentialsErrorAction,
  keycloakInitialCredentialsStartAction,
  keycloakInitialCredentialsSuccessAction,
} from "../actions";

export const STATE_NAME = "initialCredential";

const [reducer, defaultState] = createAsyncReducer(
  keycloakInitialCredentialsStartAction,
  keycloakInitialCredentialsSuccessAction,
  keycloakInitialCredentialsErrorAction,
  keycloakInitialCredentialsClearAction,
);

export type KeycloakInitialCredentialsState = typeof defaultState;

export default reducer;
