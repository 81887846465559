import { createAsyncReducer } from "~/lib/redux";

import {
  requestsPostBrokerReportClearAction,
  requestsPostBrokerReportStartAction,
  requestsPostBrokerReportSuccessAction,
} from "../actions";

export const STATE_NAME = "postBrokerReport";

const [reducer, defaultState] = createAsyncReducer(
  requestsPostBrokerReportStartAction,
  requestsPostBrokerReportSuccessAction,
  // @ts-ignore vite
  requestsPostBrokerReportClearAction
);

export type RequestsPostBrokerReportState = typeof defaultState;

export default reducer;
